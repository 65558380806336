import React, {useCallback, useEffect, useState} from 'react';
import {Container, Grid} from '@mui/material';
import {useOs} from '@wppopen/react';
import {connect} from 'react-redux';
import {WppBanner, WppButton, WppIconAdd, WppListItem, WppSelect, WppToast} from '@wppopen/components-library-react';
import serviceURL from '../../../helper/serviceURL';
import CreateInventory from './createInventory/CreateInventory';
import {INVENTORY_SEARCHS, INV_GRID_HEADER_DETAILS, SELECT_TYPES, INVENTORY} from '../../../helper/constants';
import RowDetails from './rowDetails/RowDetails';
import {InventoryListProps} from './interface';
import styles from './InventoryList.module.scss';
import DataGridComp from '../../../components/dataGrid/DataGridComp';
import isObjectEmpty, { convertToTitleCase, prepareRowData } from "../../../helper/Helper"
import DataGridOfflineComp from '../../../components/dataGrid/DataGridOfflineComp';
import SearchComp from '../../../components/search/SearchComp';
import {searchData} from "../../../helper/interface";
import SearchResultComp from '../../../components/search/SearchResultComp';
import {createInventoryDispatcher} from "./createInventory/action";

const mapDispatchToProps = (dispatch: any) => ({
    createInventoryDispatcher: (obj: object) => dispatch(createInventoryDispatcher(obj))
});
const mapStateToProps = (state: any) => {
    return {
        base64Email: state.storeBase64EmailReducer.data,
        selectedSideOrg: state.selectedSideNavOrgRed.data,
        createInventory: state.createInventoryRed.data,
    };
};

const InventoryList = ({
                           base64Email, selectedSideOrg, createInventory, createInventoryDispatcher,
                       }: InventoryListProps) => {
    const initialState = {id: 1, name: INVENTORY.TYPE_DA};
    const pageToStart = 0;
    const pageSize = 25;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const initialRowData = {
        id: '',
        name: '',
        description: '',
        legalEntityInventory: '',
        vendorInventory: '',
        daInventory: '',
        paInventory: ''
    };
    const [clickedRowData, setClickedRowData] = useState<any>(initialRowData);
    const [openClickedRowModal, setOpenClickedRowModal] = useState(false);
    const [selectedType, setSelectedType] = useState(initialState);
    const [isLoading, setIsLoading] = useState(false);
    const [isToShowBanner, setIsToShowBanner] = useState(false);
    const [totalElements, setTotalElements] = useState(0);
    const [initialGridList, setInitialGridList] = useState([]);
    const [searchResults, setSearchResults] = useState<any>([]);
    const isFetchBySelectType = true;
    const [showToast, setShowToast] = useState(false);
    const baseApiUrl = `${serviceURL.pgpBaseAPI}/api/inventory`;
    const {osApi: { getAccessToken }} = useOs();
    const headers = {
        accept: '*/*',
        Authorization: 'Bearer ' + getAccessToken()
    };
    const [queryVal, setQueryVal] = useState<string>('');
    const [triggerFetch, setTriggerFetch] = useState(false);

    useEffect(() => {
        if (createInventory && Object.keys(createInventory).length > 0) {
            let invObj: any = {};
            switch (createInventory.selectedType) {
                case INVENTORY.TYPE_DA:
                    invObj = SELECT_TYPES[0]
                    break;
                case INVENTORY.TYPE_PA:
                    invObj = SELECT_TYPES[1]
                    break;
                default:
                    invObj = SELECT_TYPES[3]
                    break;
            }
            setSelectedType(invObj);
            createInventoryDispatcher && createInventoryDispatcher({});
            setTriggerFetch(true);
        }
    }, [createInventory]);

    useEffect(() => {
        if (base64Email && selectedSideOrg && selectedSideOrg.id && selectedType) {
            setInitialGridList([]);
            setSearchResults([]);
            fetchInitialGridList(pageToStart, pageSize);
        }
        setTriggerFetch(false);
    }, [base64Email, selectedSideOrg, selectedType, triggerFetch]);

    const fetchInitialGridList = (startRow: number = 0, endRow: number = pageSize,
                                  queryString: string = `&page=${startRow}&size=${endRow}`) => {
        if (selectedSideOrg) {
            const apiUrl = baseApiUrl + '/inventories?type=' + selectedType.name + queryString +
                '&orgId=' + selectedSideOrg.id;

            const handleErrors = (response: any) => {
                if (!response.ok) {
                    setIsLoading(false);
                    setIsToShowBanner(true);
                    throw Error(response.statusText);
                }
                return response;
            }

            setIsLoading(true);
            fetch(apiUrl, {headers: headers})
                .then(handleErrors)
                .then(httpResponse => httpResponse.json())
                .then(response => {
                    if (response) {
                        let sanitizedData = prepareRow(response.content);
                        if (sanitizedData) {
                            setInitialGridList(sanitizedData);
                            setTotalElements(response.totalElements);
                            setIsToShowBanner(false);
                        }
                    }
                    setIsLoading(false);
                })
                .catch(error => {
                    console.error(error);
                    setIsLoading(false);
                    setIsToShowBanner(true);
                });
        }
    };

    const prepareRow = useCallback((rowData: []) => {
        return prepareRowData(rowData);
    }, []);

    const getClickedRow = useCallback((data: object): void => {
        if (!isObjectEmpty(data)) {
            setClickedRowData(data);
            setOpenClickedRowModal(true);
        }
    }, [clickedRowData]);
    const handleCreateInventory = useCallback((): void => {
        setIsModalOpen(true);
        setShowToast(false);
    }, []);
    const getFilterQuery = async (val: string, list: object | searchData[]) => {
        setQueryVal(val);
        setSearchResults(list);
    };

    const getShowToast = (val: any) => {
        setShowToast(val);
    }

    const handleSelectType = (e: any) => {
        setSelectedType(e.target.value);
    }

    return (
        <div className={styles.container}>
            <Container maxWidth="xl">
                <div className={styles.loadingArea}>
                    <WppBanner id="banner" type="information" show={isToShowBanner}>
                        Unable to fetch information. Please refresh or try after some time.
                    </WppBanner>
                    {showToast && <WppToast
                        className={styles.toast}
                        message={'Inventory created successfully'}
                        type={'success'}/>}
                </div>
                <Grid
                    container
                    spacing={{xs: 2, md: 3, sm: 3}}
                    columns={{xs: 4, sm: 8, md: 12}}
                    rowSpacing={10}>
                    <Grid item xs={12} sm={3} md={3}>
                        <WppSelect
                            onWppChange={handleSelectType}
                            placeholder="Select Type"
                            size="s"
                            value={selectedType}
                        >
                            {SELECT_TYPES.slice(0, 2).concat(SELECT_TYPES.slice(3)).map((item, idx) => {
                                return (
                                    <WppListItem key={idx} value={item} defaultValue={selectedType.name}>
                                        <p slot="label">{convertToTitleCase(item.name)}</p>
                                    </WppListItem>
                                );
                            })}
                        </WppSelect>
                    </Grid>
                    <div className={styles.inventoryContainer}>
                    {INVENTORY_SEARCHS.length > 0 && selectedSideOrg &&
                        <SearchComp searchList={INVENTORY_SEARCHS} filterQuery={getFilterQuery}
                                    initialGridList={initialGridList}/>
                    }
                    {
                        initialGridList.length ?
                            <Grid item xs={12} sm={3} md={3} display="flex" justifyContent="flex-end"
                                  alignSelf="flex-end" className={styles.createInventoryButton}>
                                <WppButton
                                    className={styles.customBtnSecondary}
                                    variant="secondary" onClick={handleCreateInventory} size='s'>
                                    <WppIconAdd slot="icon-start" className={styles.customIcon}/>
                                    Create Inventory
                                </WppButton>
                            </Grid>
                            : null
                    }
                    </div>

                    {selectedSideOrg &&
                        <SearchResultComp searchResultList={searchResults} selectedSideOrg={selectedSideOrg.id}/>}
                    <Grid item xs={12} sm={12} md={12} style={{paddingTop: "3rem", marginBottom: "5rem", height: "100vh" }}>
                        {
                            isLoading ?
                                <DataGridOfflineComp/>
                                :
                                <>
                                    {selectedSideOrg &&
                                        <DataGridComp
                                            columnHeaderDefinition={INV_GRID_HEADER_DETAILS}
                                            initialGridResult={initialGridList} totalElements={totalElements}
                                            clickedRow={getClickedRow}
                                            baseApiUrl={baseApiUrl}
                                            prepareRow={prepareRow} headers={headers}
                                            searchList={INVENTORY_SEARCHS}
                                            queryVal={queryVal}
                                            isFetchBySelectType={isFetchBySelectType}
                                            selectedType={selectedType.name}
                                            createParentModule={handleCreateInventory}
                                            noCustomRowBtn={true}
                                            fetchListUrl={baseApiUrl + '/inventories?type=' + selectedType.name + '&orgId=' + selectedSideOrg.id} //add list of attributes
                                        />
                                    }
                                </>
                        }
                    </Grid>
                </Grid>
                {isModalOpen &&
                    <CreateInventory open={isModalOpen} onClose={() => setIsModalOpen(false)}
                                     showToast={getShowToast}/>}
                {openClickedRowModal &&
                    <RowDetails
                        open={openClickedRowModal}
                        clickedRowData={clickedRowData}
                        onClose={() => setOpenClickedRowModal(false)}
                        typeOfParentInventory={selectedType}
                    />}
            </Container>
        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(InventoryList);
