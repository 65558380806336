import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {WppSideModal, WppButton, WppStep, WppStepper} from '@wppopen/components-library-react';
import Step1 from './step1/Step1';
import Step2 from './step2/Step2';
import Step3 from './/step3/Step3';
import styles from './CreateAssessment.module.scss';
import {CreateAssessmentProps, ValidationError} from './interface';
import {validate} from '../../../../helper/validate';
import {mapStateToProps, mapDispatchToProps} from './connect';

const CreateAssessment = ({
                              open,
                              closeSideModal,
                              selectedTypeDispatcher,
                              selectedOrgDispatcher,
                              selectedInvDispatcher,
                              selectedRegionDispatcher,
                              selectedMarketDispatcher,
                              selectedAgencyDispatcher,
                              getInvList,
                              getRegList,
                              getMarketList,
                              getAgencyList,
                              getTemplates,
                              getRespondent,
                              getAssesser,
                              selectedTemplateDispatcher,
                              assessNameDispatcher,
                              selectedRespondentDispatcher,
                              selectedDaysDispatcher,
                              respondentNoteDispatcher,
                              selectedAssesserDispatcher,
                              showToast,
                              selectedType,
                              selectedInventory,
                              selectedTemplate,
                              isLaunchAssess
                          }: CreateAssessmentProps) => {
    const StepNumber1: number = 1;
    const StepNumber2: number = 2;
    const StepNumber3: number = 3;
    const [currentStep, setNextStep] = useState(StepNumber1);
    const [validationErrors, setValidationErrors] = useState<ValidationError[]>();
    const initialState = {id: 0, name: ''};
    const initialStateEmail = {id: 0, email: ''};
    const steps: { id: number, name: string }[] = [
        {id: StepNumber1, name: 'Fill Details'},
        {id: StepNumber2, name: 'Select Template'},
        {id: StepNumber3, name: 'Launch Assessment'}
    ]

    useEffect(() => {
        if (isLaunchAssess && !isLaunchAssess.openAssess) {
            selectedTypeDispatcher && selectedTypeDispatcher(initialState);
            selectedInvDispatcher && selectedInvDispatcher(initialState);
            selectedOrgDispatcher && selectedOrgDispatcher(initialState);
            selectedRegionDispatcher && selectedRegionDispatcher(initialState);
            selectedMarketDispatcher && selectedMarketDispatcher(initialState);
            selectedAgencyDispatcher && selectedAgencyDispatcher(initialState);
        }
        selectedTemplateDispatcher && selectedTemplateDispatcher(initialState);
        assessNameDispatcher && assessNameDispatcher('');
        selectedRespondentDispatcher && selectedRespondentDispatcher([]);
        selectedDaysDispatcher && selectedDaysDispatcher(0);
        respondentNoteDispatcher && respondentNoteDispatcher('');
        selectedAssesserDispatcher && selectedAssesserDispatcher(initialStateEmail);
        getInvList && getInvList('');
        getRegList && getRegList('');
        getMarketList && getMarketList('');
        getAgencyList && getAgencyList('');
        getTemplates && getTemplates('');
        getAssesser && getAssesser('');
        getRespondent && getRespondent('');
    }, []);

    const handleNextStep = () => {
        let validationList: any[];
        let validateFields: object;
        if (currentStep === StepNumber1) {
            validateFields = {
                selectedType: selectedType,
                linkedSelectedInventory: selectedInventory,
            };
        } else {
            validateFields = {
                selectedTemplate: selectedTemplate
            };
        }
        validationList = validate(validateFields);
        setValidationErrors(validationList);
        const found = validationList.map((item: any) => !!item.error).includes(true);
        if (!found) {
            if (currentStep <= steps.length - 1) {
                setNextStep(currentStep + 1);
            }
        }
    }
    const handlePreviousStep = () => {
        if (currentStep > 1) {
            setNextStep(currentStep - 1);
        }
    }

    return (
        <WppSideModal open={open} size={'2xl'} onWppSideModalClose={closeSideModal}>
            <h3 slot="header">Create Assessment</h3>
            <div slot="body">
                <WppStepper className={styles.riskAssessContainer} activeStep={currentStep} orientation={'horizontal'}>
                    {steps.map((item: { id: number, name: string }) => (
                        <WppStep key={item.id} className={styles.steps}>
                            <p slot="label" className={styles.stepText}>
                                {item.name}
                            </p>
                        </WppStep>
                    ))}
                </WppStepper>
                <div className={styles.innerPage}>
                    {currentStep === 1 && <Step1 validationErrors={validationErrors}/>}
                    {currentStep === 2 && <Step2 validationErrors={validationErrors}/>}
                    {currentStep === 3 && <Step3 closeSideModal={closeSideModal} showToast={showToast}/>}
                </div>
                <div className={styles.button}>
                    {currentStep !== 1 &&
                        <WppButton
                            className={styles.customBtnSecondary}
                            variant={'secondary'}
                            onClick={handlePreviousStep}>Previous Step</WppButton>}
                    {currentStep !== 3 &&
                        <WppButton
                            variant={'secondary'}
                            className={styles.customBtnSecondary}
                            onClick={handleNextStep}>Next Step</WppButton>}
                </div>
            </div>
        </WppSideModal>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateAssessment);
