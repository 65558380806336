import React, {useEffect, useRef, useState} from 'react';
import { useSelector } from "react-redux";
import {Grid, Container} from '@mui/material';
import styles from './DMDashboard.module.scss';
import serviceURL from "../../../helper/serviceURL";
import {StatusObject} from "../../../helper/interface";
import {Chart, registerables} from 'chart.js';
import {buildStatusChart} from "./charts";
import {DoughnutChart} from "../../../components/doughnutChart/DoughnutChart";
import {convertToTitleCase, generateRandomColor} from "../../../helper/Helper";
import DashboardCards from "../../../components/dashboardCards/DashboardCards";
import {WppBanner, WppProgressIndicator} from '@wppopen/components-library-react';
import useAxiosInterceptors from '../../../hooks/useAxiosInterceptors'

export const DMDashboard = () => {
    Chart.register(...registerables);
    const canvasRefStatusDA = useRef(null);
    const canvasRefStatusPA = useRef(null);
    const canvasRefStatusLE = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isToShowBanner, setIsToShowBanner] = useState(false);
    const [totalDA, setTotalDA] = useState(0);
    const [totalPA, setTotalPA] = useState(0);
    const [totalLE, setTotalLE] = useState(0);
    const base64Email = useSelector((state: any) => state.storeBase64EmailReducer.data);
    const selectedSideOrg = useSelector((state: any) => state.selectedSideNavOrgRed.data);
    const [active, setActive] = useState(0);
    const [archived, setArchived] = useState(0);
    const [pending, setPending] = useState(0);
    const [rejected, setRejected] = useState(0);
    const { axiosInstance } = useAxiosInterceptors()

    useEffect(() => {
        fetchData();
    }, [selectedSideOrg]);

    const fetchData = () => {
        const apiUrl = serviceURL.pgpBaseAPI + '/api/report/data-mapping' + '?orgId=' + selectedSideOrg.id;
        setIsLoading(true);
        axiosInstance
            .get(apiUrl)
            .then((res: any) => {
                console.log(res);
                createStatusChart(res?.data);
                setIsLoading(false);
                setIsToShowBanner(false);
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false);
                setIsToShowBanner(true);
            });
    };


    const createStatusChart = (response: StatusObject) => {
        const statusLabelsDA: string[] = [];
        const statusCountDA: number[] = [];
        const bgColorsDA: string[] = [];
        const statusLabelsPA: string[] = [];
        const statusCountPA: number[] = [];
        const bgColorsPA: string[] = [];
        const statusLabelsLE: string[] = [];
        const statusCountLE: number[] = [];
        const bgColorsLE: string[] = [];
        let totalActive = 0;
        let totalArchived = 0;
        let totalPending = 0;
        let totalRejected = 0;
        if (response?.invByStatus) {
            for (const [key, values] of Object.entries<number>(response?.invByStatus)) {
                let total = 0;
                if (key === 'data-asset') {
                    for (const [key, value] of Object.entries(values)) {
                        statusLabelsDA.push(convertToTitleCase(key));
                        statusCountDA.push(value);
                        bgColorsDA.push(generateRandomColor());
                        total += value;
                        switch (key) {
                            case 'active':
                                totalActive += value;
                                break;
                            case 'archived':
                                totalArchived += value;
                                break;
                            case 'pending':
                                totalPending += value;
                                break;
                            case 'rejected':
                                totalRejected += value;
                                break;
                        }
                    }
                    setTotalDA(total);
                }
                if (key === 'processing-activities') {
                    let total = 0;
                    for (const [key, value] of Object.entries(values)) {
                        statusLabelsPA.push(convertToTitleCase(key));
                        statusCountPA.push(value);
                        bgColorsPA.push(generateRandomColor());
                        total += value;
                        switch (key) {
                            case 'active':
                                totalActive += value;
                                break;
                            case 'archived':
                                totalArchived += value;
                                break;
                            case 'pending':
                                totalPending += value;
                                break;
                            case 'rejected':
                                totalRejected += value;
                                break;
                        }
                    }
                    setTotalPA(total);
                }
                if (key === 'legal-entity') {
                    let total = 0;
                    for (const [key, value] of Object.entries(values)) {
                        statusLabelsLE.push(convertToTitleCase(key));
                        statusCountLE.push(value);
                        bgColorsLE.push(generateRandomColor());
                        total += value;
                        switch (key) {
                            case 'active':
                                totalActive += value;
                                break;
                            case 'archived':
                                totalArchived += value;
                                break;
                            case 'pending':
                                totalPending += value;
                                break;
                            case 'rejected':
                                totalRejected += value;
                                break;
                        }
                    }
                    setTotalLE(total);
                }
            }
            setActive(totalActive);
            setArchived(totalArchived);
            setPending(totalPending);
            setRejected(totalRejected);
        }
        buildStatusChart(canvasRefStatusDA, statusLabelsDA, statusCountDA, bgColorsDA);
        buildStatusChart(canvasRefStatusPA, statusLabelsPA, statusCountPA, bgColorsPA);
        buildStatusChart(canvasRefStatusLE, statusLabelsLE, statusCountLE, bgColorsLE);
    };

    return (
        <Container maxWidth="xl">
            {isLoading && <WppProgressIndicator variant="bar" className={styles.customLoader}/>}
            <WppBanner id="banner" type="information" show={isToShowBanner}>
                Unable to fetch information. Please refresh or try after some time.
            </WppBanner>
            <Grid container spacing={3} marginTop={'5rem'} justifyContent={'center'}>
                <Grid item xs={12} md={3} lg={3}>
                    <DashboardCards
                        title="Total Inventories"
                        count={totalDA + totalPA + totalLE}
                        color="info"
                        icon={'ant-design:apple-filled'}
                        bgcolor={'white'}/>
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                    <DashboardCards
                        title="Data Assets"
                        count={totalDA}
                        color="info"
                        icon={'ant-design:apple-filled'}
                        bgcolor={'white'}/>
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                    <DashboardCards
                        title="Processing Activities"
                        count={totalPA}
                        color="info"
                        icon={'ant-design:apple-filled'}
                        bgcolor={'white'}/>
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                    <DashboardCards
                        title="Legal Entity"
                        count={totalLE}
                        color="info"
                        icon={'ant-design:apple-filled'}
                        bgcolor={'white'}/>
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                    <DashboardCards
                      title="Total Active"
                      count={active}
                      color="info"
                      icon={'ant-design:apple-filled'}
                      bgcolor={'white'}/>
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                    <DashboardCards
                      title="Total Archived"
                      count={archived}
                      color="info"
                      icon={'ant-design:apple-filled'}
                      bgcolor={'white'}/>
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                    <DashboardCards
                      title="Total Pending"
                      count={pending}
                      color="info"
                      icon={'ant-design:apple-filled'}
                      bgcolor={'white'}/>
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                    <DashboardCards
                      title="Total Rejected"
                      count={rejected}
                      color="info"
                      icon={'ant-design:apple-filled'}
                      bgcolor={'white'}/>
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                    <div className={styles.sectionDoughnut}>
                        <DoughnutChart title={'Data Asset Status'} chartRef={canvasRefStatusDA}/>
                    </div>
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                    <div className={styles.sectionDoughnut}>
                        <DoughnutChart title={'Processing Activities Status'} chartRef={canvasRefStatusPA}/>
                    </div>
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                    <div className={styles.sectionDoughnut}>
                        <DoughnutChart title={'Legal Entity Status'} chartRef={canvasRefStatusLE}/>
                    </div>
                </Grid>
            </Grid>
        </Container>
    );
}

export default DMDashboard;