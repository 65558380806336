import React, { forwardRef, memo, useEffect, useImperativeHandle, useState } from "react"
import { parseQuestionBankIds, getTime } from "../../helper/Helper"
import _ from "lodash"
import styles from "./QueAnsAssessment.module.scss"
import { WppActionButton, WppIconPending, WppSpinner, WppTypography } from "@wppopen/components-library-react"
import { styled } from "@mui/material/styles"
import Badge, { BadgeProps } from "@mui/material/Badge"
import useAxiosInterceptors from '../../hooks/useAxiosInterceptors'

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -12,
    top: 0,
    border: `0 solid ${theme.palette.background.paper}`,
    padding: "0 4px",
    fontSize: "1.1rem",
    borderRadius: "50%",
    width: "2.5rem",
    height: "2.5rem"
  }
}))
const CommentHistory = memo(
  forwardRef((props: any, ref) => {
    const [showHistory, setShowHistory] = useState(false)
    const parseKey = parseQuestionBankIds(props.cmtHistoryKey)
    const [comments, setComments] = useState([])
    const [commentsLoaded, setCommentsLoaded] = useState(false)
    const [commentsLoadedError, setCommentsLoadedError] = useState(false)
    const { axiosInstance } = useAxiosInterceptors()
    useImperativeHandle(
      ref,
      () => {
        return {}
      },
      []
    )

    useEffect(() => {
      if (props.getComments?.length) {
        setShowHistory(true)
        setComments(props.getComments)
      }
    }, [props.getComments])
    useEffect(() => {
      const getCommentsApiUrl = props.serviceURL.pgpBaseAPI + "/api/pm/comments/" + parseKey.responseId
      setTimeout(() => {
        //API is not reflecting changes immediately
        axiosInstance
          .get(getCommentsApiUrl)
          .then(res => {
            if (res.data) {
              setCommentsLoaded(true)
              if (res.data.length) {
                setShowHistory(true)
                setComments(res.data?.length && res.data)
              }
            }
          })
          .catch(error => {
            setCommentsLoaded(false)
            setCommentsLoadedError(true)
            console.log(error)
          })
      }, 1000)
    }, [])
    const historyContent = () => {
      return (
        <div className={styles.historyContainer}>
          {showHistory && comments.length > 0 && (
            <ul>
              {comments.map((cmt: any) => {
                return (
                  <li key={`li-${cmt.id}`}>
                    {cmt?.reviewer ? (
                      <>
                        <span key={`span-${cmt.id}`} className={styles.cmtPoints}>
                          &nbsp;
                        </span>
                        <p key={`p-${cmt.id}`}>
                        <WppTypography class={styles.you} type="s-strong">You</WppTypography> : <span key={`date-${cmt.id}`}>{getTime(cmt.received)}</span> &nbsp;{cmt.comment}
                        </p>
                      </>
                    ) : (
                      <>
                        <span key={`span-${cmt.id}`} className={styles.cmtPoints}>
                          &nbsp;
                        </span>
                        <p key={`p-${cmt.id}`}>
                        <WppTypography className={styles.vendor} type="s-strong">Vendor</WppTypography> : <span key={`date-${cmt.id}`}>{getTime(cmt.received)}</span> &nbsp;{cmt.comment}
                        </p>
                      </>
                    )}
                  </li>
                )
              })}
            </ul>
          )}
        </div>
      )
    }
    return (
      <div className={styles.feedbackContainer}>
        {!commentsLoaded ? (
          <span className={styles.commentText}>
            Loading comments if exist...
            <WppSpinner size="s" color={"black"} />
          </span>
        ) : (
          <>
            <WppActionButton
              variant="secondary"
              slot="actions"
              key={`review-history-btn-Id-${props.cmtHistoryKey}`}
              onClick={e => setShowHistory(!showHistory)}
            >
              {comments?.length > 0 && (
                <div className={styles.historyBtn}>
                  {showHistory ? "Hide Feedback History" : "Show Feedback History "} &nbsp;
                  <StyledBadge badgeContent={comments?.length} color="info">
                    <WppIconPending size={"s"} color={"black"} />
                  </StyledBadge>
                </div>
              )}
            </WppActionButton>
            {historyContent()}
          </>
        )}
        {commentsLoadedError && <p>Comment history fetch failed, please try again!</p>}
      </div>
    )
  })
)
export default CommentHistory
