import {
  WppBanner,
  WppButton,
  WppListItem,
  WppSelect,
  WppTextareaInput,
  WppTag,
  ChangeEvent
} from "@wppopen/components-library-react"
import React, { useState } from "react"
import styles from "./QueAnsAssessment.module.scss"
import { SELECT_ASSESSMENT_REVIEW_OUTCOME } from "helper/constants"
import { validate } from "helper/validate"
import serviceURL from "helper/serviceURL"
import useAxiosInterceptors from "../../hooks/useAxiosInterceptors"
import TextArea from "components/textArea/TextArea"

const isAttachmentMandatory = (configuration = null) => {
  try {
    const config = JSON.parse(configuration)
    return (
      config?.conditional?.requiredAttachment === true || !config?.conditional?.hasOwnProperty("requiredAttachment")
    )
  } catch (error) {
    console.log("error", error)
  }
}

const isAttachmentMandatoryConditional = (configuration = null, responseValue = "") => {
  try {
    const config = JSON.parse(configuration)
    return config?.conditional?.showAttachment.length && config?.conditional?.showAttachment.includes(responseValue)
  } catch (error) {
    console.log("error", error)
  }
}

const getValidSequences = (details: any) => {
  return details.flatMap((detail: { questionAnswers: any[] }) =>
    detail.questionAnswers
      .filter(({ question, attachments, response }: any) => {
        const isTextOrConditional =
          question.questionType === "TEXT" || question.questionType === "SINGLE_SELECT_TEXT_CONDITIONAL"
        const isAttachmentAvaialble = question.attachment
        if (isTextOrConditional && isAttachmentAvaialble) {
          const isAttachmentFound = !attachments || !attachments.length
          if (question.questionType === "TEXT") {
            return isAttachmentAvaialble && isAttachmentMandatory(question?.configuration || null) && isAttachmentFound
          } else if (question.questionType === "SINGLE_SELECT_TEXT_CONDITIONAL") {
            const responseValue = response.responseValue
            return (
              isAttachmentAvaialble &&
              isAttachmentMandatoryConditional(question?.configuration || null, responseValue) &&
              isAttachmentFound
            )
          }
        }
      })
      .map(({ question }) => question.sequence)
  )
}

const Review = ({
  dpmReviewComment,
  assessment,
  setDpmReviewComment,
  selectReviewOutcome,
  validationErrors,
  setSelectReviewOutcome,
  showReviewOutcomeSaveSpinnner,
  setShowReviewOutcomeSaveSpinnner,
  showOutcomeBanner,
  setShowOutcomeBanner,
  base64Email,
  showApproveToast,
  closeSideModal,
  riskStatusOpen
}) => {
  const [hasMandatoryAttachment, setMandatoryAttachment] = useState([])
  const { axiosInstance } = useAxiosInterceptors()
  const saveReviewOutcomeHandler = (id: string) => {
    let validationList: any[]
    let validateFields: object
    let hasMandatoryAttachment = []
    validateFields = {
      selectReviewOutcome: selectReviewOutcome.name
    }
    validationList = validate(validateFields)
    if (selectReviewOutcome.name === SELECT_ASSESSMENT_REVIEW_OUTCOME[0].name) {
      const getQuestionsHasMandatoryAttachments = getValidSequences(assessment.details)
      hasMandatoryAttachment = getQuestionsHasMandatoryAttachments
      setMandatoryAttachment(getQuestionsHasMandatoryAttachments)
    }
    validationErrors && validationErrors(validationList)
    const found = validationList.map((item: any) => !!item.error).includes(true)
    if (!found && !hasMandatoryAttachment?.length) {
       const apiUrl = serviceURL.pgpBaseAPI + "/api/pm/assessment/" + id + "/review"
       setShowReviewOutcomeSaveSpinnner(true)
       const payload = {
          assessmentReviewOutcome: selectReviewOutcome.name,
          comments: dpmReviewComment
       }
       axiosInstance
          .put(apiUrl, payload, {
             headers: {
                "accept": " */*",
                "Content-Type": "application/json",
             }
          })
          .then(() => {
             setShowReviewOutcomeSaveSpinnner(false)
             setShowOutcomeBanner(false)
             if (showApproveToast) {
                showApproveToast(true)
             }
             setMandatoryAttachment([])
             closeSideModal && closeSideModal()
          })
          .catch((error) => {
             setShowReviewOutcomeSaveSpinnner(false)
             setShowOutcomeBanner(true)
          })
    }
  }

  const handleDPMReviewComment = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setDpmReviewComment && setDpmReviewComment(e.target.value)
  }

  return (
    <React.Fragment>
      <div key={`dpm-container-id-${assessment.assessmentId}`} className={styles.dmActions}>
        <p>Reviewer comments</p>
        <TextArea
          className={styles.dpmReviewContainerCommentItem}
          value={dpmReviewComment}
          placeholder="Enter Comment"
          onChange={e => handleDPMReviewComment(e)}
        />
        {riskStatusOpen && riskStatusOpen.length > 0 && (
          <WppTag
            label="Please close all risks before approving the assessment!!"
            variant="warning"
            maxLabelLength={200}
            className={styles.tagWarning}
          />
        )}
        {hasMandatoryAttachment && hasMandatoryAttachment.length > 0 && (
          <p className={styles.attachmentError}>
            Please provide attachments to&nbsp;
            {hasMandatoryAttachment.map((i, index) => (
              <span key={i}>
                {i}
                {index < hasMandatoryAttachment.length - 1 && ", "}
              </span>
            ))}{" "}
            as these questions are mandatory attachment questions.
          </p>
        )}
        <div className={styles.dpmHeader} key={`dpm-text-header`}>
          Please choose assessment review outcome from below dropdown menu.
        </div>

        <div className={styles.thirdRow} key={`dpm-thirdRow`}>
          <div className={styles.firstColDpm} key={`firstColDpm`}>
            <WppSelect
              key={`dpm-select`}
              placeholder="Select Review Outcome"
              className={styles.selectReview}
              value={selectReviewOutcome.name}
              size="s"
              message={
                (validationErrors &&
                  validationErrors.find((item: any) => item.name === "selectReviewOutcome")?.error) ||
                ""
              }
              messageType={
                validationErrors && validationErrors.find((item: any) => item.name === "selectReviewOutcome")?.error
                  ? "error"
                  : undefined
              }
              onWppChange={e => setSelectReviewOutcome(e.target.value)}
            >
              {SELECT_ASSESSMENT_REVIEW_OUTCOME.slice(0, 1)
                .concat(SELECT_ASSESSMENT_REVIEW_OUTCOME.slice(2))
                .map((item, idx) => {
                  return (
                    <WppListItem
                      key={idx}
                      value={item}
                      disabled={riskStatusOpen && riskStatusOpen.length > 0 ? item.name === "APPROVED" && true : false}
                    >
                      <p slot="label" key={`label-dpm`}>
                        {item.name}
                      </p>
                    </WppListItem>
                  )
                })}
            </WppSelect>
            <WppButton
              key={`review-save`}
              variant={"secondary"}
              size={"s"}
              className={styles.saveReviewBtn}
              loading={showReviewOutcomeSaveSpinnner}
              onClick={e => saveReviewOutcomeHandler(assessment.assessmentId)}
            >
              Submit{" "}
            </WppButton>
            {showOutcomeBanner && (
              <WppBanner id="banner" type="information" show={showOutcomeBanner} className={styles.outcomeBanner}>
                Unable to save review outcome information. Please refresh or try after some time.
              </WppBanner>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Review
