import {Card, Divider, Typography} from '@mui/material';

interface AppWidgetSummaryProps {
    color?: string,
    icon?: string,
    title: string,
    total?: number,
    sx?: object,
    accReq?: number,
    delReq?: number,
    dnsReq?: number,
    bgcolor?: string
}

export default function AppWidgetSummary({ accReq, delReq, dnsReq, bgcolor, title, total, icon, color = 'primary', sx, ...other }: AppWidgetSummaryProps) {
    return (
        <Card
            sx={{
                py: 5,
                boxShadow: "0 4px 12px 0 rgba(94, 118, 181, 0.1)",
                borderRadius: 2,
                textAlign: 'center',
                // color: (theme) => theme.palette[color].darker,
                bgcolor,
                ...sx,
            }}
            {...other}
        >
            <Typography variant="subtitle2" sx={{ opacity: 0.72, fontSize: 18, lineHeight: 0.5, fontWeight: 500, color: 'black' }}>
                {title} : {total}
            </Typography>
            <br />
            <Divider/>
            <br />
            <Typography variant="subtitle1" sx={{ opacity: 0.72, fontSize: 16, lineHeight: 0.3, fontWeight: 300 }}>
                Access Request : {accReq}
            </Typography>
            <br />
            <Typography variant="subtitle1" sx={{ opacity: 0.72, fontSize: 16, lineHeight: 0.3, fontWeight: 300 }}>
                Delete Request : {delReq}
            </Typography>
            <br />
            <Typography variant="subtitle1" sx={{ opacity: 0.72, fontSize: 16, lineHeight: 0.3, fontWeight: 300 }}>
                Do Not Sell Request : {dnsReq}
            </Typography>
        </Card>
    );
}
