import * as React from 'react';
import ReactFlow, { Controls, Background } from 'reactflow';
import initialNodes from './node';
import initialEdges from './edge';
import { useEffect } from 'react';
import 'reactflow/dist/style.css';
const FlowChart = () => {
    useEffect(() => {
      const panels = document.querySelectorAll('.react-flow__panel');
      panels.forEach(panel => {
        panel?.parentNode?.removeChild(panel);
      });

    }, []);

    return (
        <div style={{ height: 700 }}>
            <ReactFlow nodes={initialNodes} edges={initialEdges}>
                <Controls />
                <Background gap={16}/>
            </ReactFlow>
        </div>
    );
};

export default FlowChart;
