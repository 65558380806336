import React, {useState, useEffect, useRef} from 'react';
import {connect} from 'react-redux';
import {Grid, Container} from '@mui/material';
import {
    WppListItem,
    WppSelect,
    WppProgressIndicator,
    WppBanner,
    WppLabel,
    WppInput
} from '@wppopen/components-library-react';
import {useOs} from '@wppopen/react';
import {SELECT_TYPES} from '../../../../../helper/constants';
import styles from './Step1.module.scss';
import {Step1Props} from './interface';
import {
    selectedTypeDispatcher, selectedRegionDispatcher, selectedMarketDispatcher,
    selectedAgencyDispatcher, getRegListDispatcher, getMarketListDispatcher,
    getAgencyListDispatcher, getInvListDispatcher, selectedInvDispatcher
} from './action';
import {selectedAgency} from "../../../../sideNav/action";
import { convertToTitleCase } from "../../../../../helper/Helper"

const mapStateToProps = (state: any) => {
    return {
        base64Email: state.storeBase64EmailReducer.data,
        selectedType: state.caSelectedTypeReducer.data,
        selectedInventory: state.caSelectedInvReducer.data,
        selectedRegion: state.caSelectedRegReducer.data,
        selectedMarket: state.caSelectedMarketReducer.data,
        selectedAgency: state.caSelectedAgencyReducer.data,
        selectedTemplate: state.caSelectedTemplateReducer.data,
        invList: state.caInvListReducer.data,
        regList: state.caRegListReducer.data,
        marketList: state.caMarketListReducer.data,
        agencyList: state.caAgencyListReducer.data,
        isLaunchAssess: state.launchAssessRed.data,
        rowDetails: state.inventoryRowDetailsRed.data,
        selectedSideOrg: state.selectedSideNavOrgRed.data
    };
};

const mapDispatchToProps = (dispatch: any) => ({
    selectedTypeDispatcher: (type: { id: number, name: string }) => dispatch(selectedTypeDispatcher(type)),
    selectedInvDispatcher: (inv: { id: number, name: string }) => dispatch(selectedInvDispatcher(inv)),
    selectedRegionDispatcher: (reg: { id: number, name: string }) => dispatch(selectedRegionDispatcher(reg)),
    selectedMarketDispatcher: (market: { id: number, name: string }) => dispatch(selectedMarketDispatcher(market)),
    selectedAgencyDispatcher: (agency: { id: number, name: string }) => dispatch(selectedAgencyDispatcher(agency)),
    getInvListDispatcher: (type: string, header: object, orgId: number) => dispatch(getInvListDispatcher(type, header, orgId)),
    getRegListDispatcher: (regList: any, header: object) => dispatch(getRegListDispatcher(regList, header)),
    getMarketListDispatcher: (marketList: any, header: object) => dispatch(getMarketListDispatcher(marketList, header)),
    getAgencyListDispatcher: (agencyList: any, header: object) => dispatch(getAgencyListDispatcher(agencyList, header)),
    selectedOrgDispatcher: (orgList: any) => dispatch(selectedAgency(orgList)),
});

const Step1 = ({
                   base64Email, selectedTypeDispatcher, selectedInvDispatcher,
                   selectedRegionDispatcher, selectedMarketDispatcher, selectedAgencyDispatcher,
                   selectedType, selectedInventory, getInvListDispatcher, invList, validationErrors,
                   isLaunchAssess, rowDetails, selectedSideOrg, selectedOrgDispatcher
               }: Step1Props) => {
    const initialState = {id: 0, name: ''};
    const [isLoading, setIsLoading] = useState(false);
    const [isToShowBanner, setIsToShowBanner] = useState(false);
    const {osApi: { getAccessToken }} = useOs();
    const headers = {
        accept: '*/*',
        Authorization: 'Bearer ' + getAccessToken()
    };
    const previousSelectedTypeRef = useRef(selectedType?.name || '');

    useEffect(() => {
        if (isLaunchAssess && isLaunchAssess.openAssess) {
            selectedInvDispatcher && selectedInvDispatcher({
                id: rowDetails.id,
                name: rowDetails.name,
                market: rowDetails.market,
                region: rowDetails.region,
                agency: rowDetails.agency
            });
            selectedRegionDispatcher && selectedRegionDispatcher(rowDetails.region);
            selectedMarketDispatcher && selectedMarketDispatcher(rowDetails.market);
            selectedAgencyDispatcher && selectedAgencyDispatcher(rowDetails.agency);
            selectedOrgDispatcher && selectedOrgDispatcher(rowDetails.organization)
        }
    }, []);


    useEffect(() => {
        if ((selectedType?.name !== '') || (selectedType?.name !== previousSelectedTypeRef.current)) {
            fetchInvList();
            previousSelectedTypeRef.current = selectedType?.name || '';
        }
        return () => {
            previousSelectedTypeRef.current = '';
        };
    }, [selectedType]);


    const fetchInvList = () => {
        if (selectedSideOrg && selectedType) {
            setIsLoading(true);
            getInvListDispatcher && getInvListDispatcher(selectedType.name || '',
                headers, selectedSideOrg.id).then(() => {
                setIsLoading(false);
                setIsToShowBanner(false);
            }).catch((error: any) => {
                console.log(error);
                setIsLoading(false);
                setIsToShowBanner(true);
            });
        }
    }

    const handleType = (e: any) => {
        selectedTypeDispatcher && selectedTypeDispatcher(e.target.value);
        selectedInvDispatcher && selectedInvDispatcher(null);
        selectedRegionDispatcher && selectedRegionDispatcher(initialState);
        selectedMarketDispatcher && selectedMarketDispatcher(initialState);
        selectedAgencyDispatcher && selectedAgencyDispatcher(initialState);
    }

    const handleInventory = (e: any) => {
        selectedInvDispatcher && selectedInvDispatcher(e.target.value);
        selectedRegionDispatcher && selectedRegionDispatcher(initialState);
        selectedMarketDispatcher && selectedMarketDispatcher(initialState);
        selectedAgencyDispatcher && selectedAgencyDispatcher(initialState);
    }

    const renderDisableInput = (name: string) => {
        return <WppInput value={name} disabled={true} size="s"/>;
    }

    return (
        <>
            <div className={styles.loadingArea}>
                {isLoading && <WppProgressIndicator className={styles.customLoader} variant="bar"/>}
                <WppBanner id="banner" type="information" show={isToShowBanner} className={styles.banner}>
                    Unable to fetch information. Please refresh or try after some time.
                </WppBanner>
            </div>
            <Container maxWidth="xl">
                <Grid container
                      spacing={{xs: 2, md: 3}}
                      columns={{xs: 4, sm: 8, md: 12}}
                      rowSpacing={10}>
                    <Grid item xs={12} sm={4} md={4}>
                        <WppLabel
                            className={styles.label}
                            config={{text: 'Type'}}
                            htmlFor="type"
                            typography="s-strong"
                        />
                        <WppSelect
                            id="type"
                            onWppChange={handleType}
                            placeholder="Select Type"
                            disabled={isLaunchAssess && isLaunchAssess.openAssess}
                            value={selectedType}
                            size="s"
                            message={validationErrors && validationErrors.find((item: any) => item.name === 'selectedType')?.error || ''}
                            messageType={validationErrors && validationErrors.find((item: any) => item.name === 'selectedType')?.error ? 'error' : undefined}
                        >
                            {SELECT_TYPES.slice(0, 3).map((item: {
                                name: string
                            }, idx: number) => {
                                return (
                                    <WppListItem key={idx} value={item}>
                                        <p slot="label">{convertToTitleCase(item.name)}</p>
                                    </WppListItem>
                                );
                            })}
                        </WppSelect>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                        <WppLabel
                            className={styles.label}
                            config={{text: 'Inventory Name'}}
                            htmlFor="inventory-name"
                            typography="s-strong"
                        />
                        {isLaunchAssess && !isLaunchAssess.openAssess ? (
                            <WppSelect
                                id="inventory-name"
                                onWppChange={handleInventory}
                                placeholder="Select Inventory"
                                disabled={selectedType && selectedType.name === ''}
                                value={selectedInventory === null ? '' : selectedInventory}
                                withSearch={true}
                                size="s"
                                message={validationErrors && validationErrors.find((item: any) => item.name === 'linkedSelectedInventory')?.error || ''}
                                messageType={validationErrors && validationErrors.find((item: any) => item.name === 'linkedSelectedInventory')?.error ? 'error' : undefined}
                            >
                                {invList && invList.length ? invList.map((item: {
                                    name: string
                                }, idx: number) => (
                                    <WppListItem key={idx} value={item}>
                                        <p slot="label">{item.name}</p>
                                    </WppListItem>
                                )) : <WppListItem>
                                    <p slot="label">No Data</p>
                                </WppListItem>}
                            </WppSelect>
                        ) : (renderDisableInput(rowDetails.name))}
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                        <WppLabel
                            className={styles.label}
                            config={{text: 'Region'}}
                            htmlFor="region"
                            typography="s-strong"
                        />
                        {isLaunchAssess && !isLaunchAssess.openAssess ? (
                            renderDisableInput(selectedInventory && selectedInventory.region && selectedInventory.region.name)
                        ) : (renderDisableInput(rowDetails.region.name))}
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                        <WppLabel
                            className={styles.label}
                            config={{text: 'Market'}}
                            htmlFor="market"
                            typography="s-strong"
                        />
                        {isLaunchAssess && !isLaunchAssess.openAssess ? (
                            renderDisableInput(selectedInventory && selectedInventory.market && selectedInventory.market.name)
                        ) : (renderDisableInput(rowDetails.market.name))}
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                        <WppLabel
                            className={styles.label}
                            config={{text: 'Agency'}}
                            htmlFor="agency"
                            typography="s-strong"
                        />
                        {isLaunchAssess && !isLaunchAssess.openAssess ? (
                            renderDisableInput(selectedInventory && selectedInventory.organization && selectedInventory.agency?.name)
                        ) : (renderDisableInput(rowDetails.agency?.name))}
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Step1);