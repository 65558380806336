import React, { useEffect, useState, ChangeEvent } from "react"
import { Grid } from "@mui/material"
import { WppIconAdd, WppIconRemove, WppButton } from "@wppopen/components-library-react"
import styles from "./EmailRespondent.module.scss"
import Input from "components/input/Input"
interface Respondent {
  id: number
  fullname: string
  email: string
}
interface EmailRespondentProps {
  value: Array<{ id: number; fullname: string; email: string }>
  setValue: (value: Respondent[]) => void
  validationErrors: { error: string; name: string }
}

const EmailRespondent = ({ value = [], setValue, validationErrors }: EmailRespondentProps) => {
  const [respondents, setRespondents] = useState<Respondent[]>([{ id: Date.now(), fullname: "", email: "" }])
  useEffect(() => {
      setRespondents(value)
  }, [value])

  const handleEmailChange = (id: number, event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    setRespondents(prevRespondents => {
      const updatedRespondents = prevRespondents.map(respondent =>
        respondent.id === id ? { ...respondent, [name as keyof Respondent]: value } : respondent
      )
      setValue(updatedRespondents)
      return updatedRespondents
    })
  }

  const addRespondent = () => {
    setRespondents(prevRespondents => {
      const newRespondent = { id: Date.now(), fullname: "", email: "" }
      const updatedRespondents = [...prevRespondents, newRespondent]
      setValue(updatedRespondents)
      return updatedRespondents
    })
  }

  const removeRespondent = (id: number) => {
    setRespondents(prevRespondents => {
      const updatedRespondents = prevRespondents.filter(respondent => respondent.id !== id)
      setValue(updatedRespondents)
      return updatedRespondents
    })
  }

  const getValidationError = (id: number, fieldName: string): string => {
    const error = validationErrors?.find(item => item.name === `${fieldName}-${id}`)
    return error ? error.error : ""
  }

  return (
    <>
      {respondents.map((respondent, index) => (
        <Grid container item xs={12} sm={12} md={12} className={styles.container} key={index} spacing={2}>
          <Grid item xs={12} sm={5} md={5}>
            <Input 
             name="fullname"
             label="Name of Respondent"
             placeholder="Name Of Respondent"
             maxLength={50}
             value={respondent.fullname}
             onChange={event => handleEmailChange(respondent.id, event)}
             message={getValidationError(respondent.id, "fullname")}
            />
          </Grid>
          <Grid item xs={12} sm={5} md={5}>
            <Input 
             name="email"
             label="Respondent Email"
             placeholder="Respondent Email"
             maxLength={50}
             value={respondent.email}
             onChange={event => handleEmailChange(respondent.id, event)}
             message={getValidationError(respondent.id, "email")}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={2}
            md={2}
            className={styles.btnBox}
          >
            <WppButton variant="secondary" className={styles.customBtnSecondary} size="s" onClick={addRespondent}>
              <WppIconAdd size="s" slot="icon-end" aria-label="Add icon" className={styles.iconColor} />
            </WppButton>
            <WppButton
              variant="secondary"
              size="s"
              className={styles.customBtnSecondary}
              onClick={() => removeRespondent(respondent.id)}
              disabled={respondents?.length === 1}
            >
              <WppIconRemove size="s" slot="icon-end" aria-label="Remove icon" className={respondents?.length === 1 ? styles.iconDisable : styles.iconColor} />
            </WppButton>
          </Grid>
        </Grid>
      ))}
    </>
  )
}

export default EmailRespondent
