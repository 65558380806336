import * as React from 'react';

interface LegendColorProps {
  srno?: number;
  color?: string;
}

const LegendColor = ({ color, srno }: LegendColorProps) => {
  if (srno) {
    return <div style={{ width: '1.5rem', height: '1rem' }}>{srno}</div>;
  } else {
    return <div style={{ width: '1.5rem', height: '1rem', backgroundColor: `${color}` }} />;
  }
};

export default LegendColor;
