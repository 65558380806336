import React, { forwardRef, memo, useEffect, useState } from "react"
import { parseQuestionBankIds } from "../../helper/Helper"
import { QUE_TYPE } from "../../helper/constants"
import _ from "lodash"
import styles from "./QueAnsAssessment.module.scss"
import { WppButton } from "@wppopen/components-library-react"

const Save = memo(forwardRef((props: any, ref) => {
  const parseKey = parseQuestionBankIds(props.dpmSaveKey)
  const [showDpmQnSaveSpinnner, setShowDpmQnSaveSpinnner] = useState(false)
  const [changeDpmQnDetected, setChangeDpmQnDetected] = useState(true)
  const saveDpmSelectData = (saveDpmIdx: string, e: any) => {
    if (!changeDpmQnDetected) {
      setShowDpmQnSaveSpinnner(true)
      const parseObjRes = parseQuestionBankIds(saveDpmIdx)
      const found = props.tempQnBank.find((item: any) => {
        if (item && item.question.id === parseKey.questionId) {
          return item
        }
      })
      setTimeout(() => {
        if (Object.keys(found).length) {
          switch (found.question.questionType) {
            case QUE_TYPE.SINGLE_SELECT_TEXT:
            case QUE_TYPE.SINGLE_SELECT:
              found.response && props.executeSingleSelectSave && props.executeSingleSelectSave(parseObjRes, found)
              break
            case QUE_TYPE.MULTI_SELECT:
              props.executeMultiSelectSave && props.executeMultiSelectSave(parseObjRes, found)
              break
            case "TEXT":
            case "INVENTORY":
              props.executeTextInventorySave && props.executeTextInventorySave(parseObjRes, found)
              break
            default:
              break
          }
        }
        setShowDpmQnSaveSpinnner(false)
      }, 1500)
    }
  }
  const apiSavedQnBank = (savedResArray: any) => {
    if (savedResArray.length) {
      const qnBank = savedResArray.find((catItem: any) => catItem.categoryId === parseKey.categoryId)?.questionAnswers?.length ?
        savedResArray.find((item: any) => item.categoryId === parseKey.categoryId)?.questionAnswers
        : []
      const selectedQn = qnBank.length && qnBank.find((qnItem: any) => qnItem.question.id === parseKey.questionId)
      return { qnBank, selectedQn }
    }
    return {}
  }
  const compareMultiSelectVals = (selected: any, saveUnsavedSelected: any) => {

    if (selected) {
      if (selected !== saveUnsavedSelected) {
        setChangeDpmQnDetected(false)
      } else {
        setChangeDpmQnDetected(true)
      }
    }else if(selected === undefined && saveUnsavedSelected !== undefined){
      setChangeDpmQnDetected(false)
    }else if(selected === "" && saveUnsavedSelected !== undefined){
      setChangeDpmQnDetected(false)
    }else{
      setChangeDpmQnDetected(true)
    }
  }
  const compareSingleSelectVals = (selected: any, saveUnsavedSelected: any) => {

    if (selected) {
      if (selected !== saveUnsavedSelected) {
        setChangeDpmQnDetected(false)
      } else {
        setChangeDpmQnDetected(true)
      }
    }
  }
  useEffect(() => {
    if(props.tempQnBank.length){
      for (let i = 0; i < props.tempQnBank.length; i++) {
        if (props.tempQnBank[i]["question"]["id"] === parseKey.questionId && props.inlineEdit) {
          if (props.dpmApiResponse && props.dpmApiResponse.length) {
            if (props.dpmQn.questionType === QUE_TYPE.SINGLE_SELECT) {
              if (props.savedResponse.length) {
                compareSingleSelectVals(props.tempQnBank[i]["response"]["responseValue"], apiSavedQnBank(props.savedResponse).selectedQn.response?.responseValue)
              } else {
                compareSingleSelectVals(props.tempQnBank[i]["response"]["responseValue"], props.dpmApiResponse)
              }
              break;

            }else if (props.dpmQn.questionType === QUE_TYPE.SINGLE_SELECT_TEXT) {
              if (props.savedResponse.length) {
                compareSingleSelectVals(props.tempQnBank[i]["response"]["responseValue"], apiSavedQnBank(props.savedResponse).selectedQn.response?.responseValue)
              } else {
                compareSingleSelectVals(props.tempQnBank[i]["response"]["responseValue"], props.dpmApiResponse)
              }

            } else if (props.dpmQn.questionType === QUE_TYPE.MULTI_SELECT) {
              if (props.savedResponse.length) {
                compareMultiSelectVals(props.tempQnBank[i]?.response?.responseValue, apiSavedQnBank(props.savedResponse).selectedQn.response?.responseValue)
              } else {
                compareMultiSelectVals(props.tempQnBank[i]?.response?.responseValue, props.dpmApiResponse)
              }
              break;
            } else if (props.dpmQn.questionType === "TEXT" || props.dpmQn.questionType === "INVENTORY") {
              if (props.savedResponse.length) {
                if (props.tempQnBank[i]?.response?.responseValue !== apiSavedQnBank(props.savedResponse).selectedQn.response?.responseValue) {
                  if (props.tempQnBank[i]["response"]["responseValue"].trim().length === 0) {
                    setChangeDpmQnDetected(true)
                  } else {
                    setChangeDpmQnDetected(false)
                  }
                } else {
                  setChangeDpmQnDetected(true)
                }
                break;
              } else {
                if (props.tempQnBank[i]?.response?.responseValue !== props.dpmApiResponse) {
                  if (props.tempQnBank[i]["response"]["responseValue"].trim().length === 0) {
                    setChangeDpmQnDetected(true)
                  } else {
                    setChangeDpmQnDetected(false)
                  }
                } else {
                  setChangeDpmQnDetected(true)
                }
                break;
              }
            }
          } else if (!props.dpmApiResponse) {
            if (props.dpmQn.questionType === "TEXT") {
              if (props.savedResponse.length) {
                if (props.tempQnBank[i]["response"]["responseValue"] !== apiSavedQnBank(props.savedResponse).selectedQn.response?.responseValue) {
                  setChangeDpmQnDetected(false)
                } else {
                  setChangeDpmQnDetected(true)
                }
              } else {
                if (props.tempQnBank[i]?.response?.responseValue !== props.dpmApiResponse) {
                  if (props.tempQnBank[i]?.response?.responseValue.trim().length === 0) {
                    setChangeDpmQnDetected(true)
                  } else {
                    setChangeDpmQnDetected(false)
                  }
                } else {
                  setChangeDpmQnDetected(true)
                }
                break;
              }
            } else if (props.dpmQn.questionType === QUE_TYPE.SINGLE_SELECT) {
              if (props.savedResponse.length) {
                compareSingleSelectVals(props.tempQnBank[i]?.response?.responseValue, apiSavedQnBank(props.savedResponse).selectedQn.response?.responseValue)
              }else{
                compareSingleSelectVals(props.tempQnBank[i]?.response?.responseValue, props.dpmApiResponse)
              }
              break;
            } else if (props.dpmQn.questionType === QUE_TYPE.MULTI_SELECT) {
              if (props.savedResponse.length) {
                compareMultiSelectVals(props.tempQnBank[i]?.response?.responseValue, apiSavedQnBank(props.savedResponse).selectedQn.response?.responseValue);
              }else{
                compareMultiSelectVals(props.tempQnBank[i]?.response?.responseValue, props.dpmApiResponse)
              }
              break;
            }
          } else {
            setChangeDpmQnDetected(false)
          }
        }
      }
    }else{
      setChangeDpmQnDetected(true)
    }

  }, [props.tempQnBank, props.savedResponse, props.inlineEdit])

  return (
    <div className={styles.dpmQnBtnContainer}>
      <WppButton
        key={`-save-${parseKey.questionId}`}
        className={styles.customBtnSecondary}
        variant={"secondary"}
        size={"s"}
        disabled={changeDpmQnDetected}
        loading={showDpmQnSaveSpinnner || props.dpmQnRetrievingSpinner || props.dpmQnSaveSpinner}
        onClick={(e) => saveDpmSelectData(props.dpmSaveKey, e)}
      >
        Save{" "}
      </WppButton>
    </div>)
}))

export default Save;