import * as React from "react"
import { useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
import axios from "axios"
import __ from "lodash"
import jsPDF from "jspdf"
import autoTable from "jspdf-autotable"

import { Chart, registerables } from "chart.js"
import { Grid, Container, Typography } from "@mui/material"
import WorldMap from "react-svg-worldmap"
import {
  buildChartPortalType,
  buildChartByStates,
  buildChartByDSARType,
  buildChartFinishedByType,
  buildChartProgressDSAndDMP,
  buildChartErrorByType,
  buildChartCancelledByType,
  buildChartFinishedByDMP,
  buildChartErrorByDMP,
  buildChartProgressByDMP
} from "./charts"
import {
  WppListItem,
  WppSelect,
  WppProgressIndicator,
  WppBanner,
  WppDatepicker,
  WppButton
} from "@wppopen/components-library-react"
import { useOs } from "@wppopen/react"
import serviceURL from "../../../helper/serviceURL"
import LegendTable from "../../../components/legendTable/LegendTable"
import LegendColor from "../../../components/legendColor/LegendColor"
import {
  CountryChartProps,
  DashboardProps,
  DMPProps,
  ModifiedObjProps,
  PortalTypeProps,
  StateChartProps
} from "./interface"
import { mapStateToProps, mapDispatchToProps } from "./connect"
import { DURATION, getRegion, REMOVE_FILTER } from "../../../helper/constants"
import { DoughnutChart } from "../../../components/doughnutChart/DoughnutChart"
import AppWidgetSummary from "../../../components/appWidgetSummary/AppWidgetSummary"
import styles from "./CPPDashboard.module.scss"
import { generateRandomColor } from "helper/Helper"
import appConfig from "../../../app.config"
import { serviceUrlProps } from "../../../helper/interface"

const CPPDashboard = ({
  dsarByTypeDispatcher,
  dsarByType,
  dsarStats,
  dsarPortalType,
  dsarCountryType,
  dsarStateType,
  dsarFinishedByType,
  dsarProgressDSByType,
  dsarErrorByType,
  dsarDeniedByType,
  dsarProgressByDMP,
  responseStatsDispatcher,
  portalTypeDispatcher,
  countryTypeDispatcher,
  stateTypeDispatcher,
  completedByTypeDispatcher,
  errorByTypeDispatcher,
  cancelledByTypeDispatcher,
  finishedByDMPDispatcher,
  errorByDMPDispatcher
}: DashboardProps) => {
  const REGION = getRegion()
  Chart.register(...registerables)
  const canvasRefPortalType = useRef(null)
  const canvasRefStateType = useRef(null)
  const canvasRefDsarType = useRef(null)
  const canvasRefCompletedByType = useRef(null)
  const canvasRefProgressDSByType = useRef(null)
  const canvasRefErrorByType = useRef(null)
  const canvasRefCancelledByType = useRef(null)
  const canvasRefFinishedByDMP = useRef(null)
  const canvasRefProgressByDMP = useRef(null)
  const canvasRefErrorByDMP = useRef(null)

  const [isLoading, setIsLoading] = useState(false)
  const [region, setRegion] = useState(REGION[0]?.value)
  const [selectedState, setSelectedState] = useState({})
  const [duration, setDuration] = useState("1month")
  const [states, setStates] = useState([] as any[])
  const [selectedDateRange, setSelectedDateRange] = useState("")
  const [isToShowBanner, setIsToShowBanner] = useState(false)
  const { osContext } = useOs()
  const { firstname } = osContext.userDetails

  useEffect(() => {
    fetchDsarList()
  }, [region, duration])

  const handleRegion = (e: any) => {
    setRegion(e.target.value)
  }

  const handleSelectedState = (e: any) => {
    setSelectedState(e.target.value)
  }

  const handleSelectedDateRangle = (e: any) => {
    setSelectedState(e.target.value)
  }

  const handleDuration = (e: any) => {
    setDuration(e.target.value)
  }

  const fetchDsarList = () => {
    const dpsRegionService = `DPS_SERVICE_URL_${region}` as keyof serviceUrlProps
    const apiUrl = serviceURL[dpsRegionService] + "/dps/report/pgp/dashboard"
    setIsLoading(true)
    axios
      .get(apiUrl, {
        headers: {
          Authorization: "Basic " + appConfig.DPS_TOKEN
        }
      })
      .then(res => {
        setIsLoading(false)
        setIsToShowBanner(false)
        if (res && res.data) {
          responseStatsDispatcher && responseStatsDispatcher(res.data)
          createPortalTypeData(res.data)
          createCountryData(res.data)
          createStateData(res.data)
          createDsarByTypeData(res.data)
          createFinishedByTypeData(res.data)
          createProgressDSAndDMPByType(res.data)
          createErrorByTypeData(res.data)
          createCancelledByTypeData(res.data)
          createFinishedByDMPData(res.data)
          createProgressByDMPData(res.data)
          createErrorByDMPDATA(res.data)
          extractstates(res.data)
        }
      })
      .catch(error => {
        console.log(error)
        canvasRefPortalType && buildChartPortalType(canvasRefPortalType, [], [], [])
        canvasRefStateType && buildChartByStates(canvasRefStateType, [])
        canvasRefDsarType && buildChartByDSARType(canvasRefDsarType, [], [], [])
        canvasRefCompletedByType && buildChartFinishedByType(canvasRefCompletedByType, [], [], [])
        canvasRefErrorByType && buildChartErrorByType(canvasRefErrorByType, [], [], [])
        canvasRefCancelledByType && buildChartCancelledByType(canvasRefCancelledByType, [], [], [])
        canvasRefFinishedByDMP && buildChartFinishedByDMP(canvasRefFinishedByDMP, [], [], [])
        canvasRefProgressByDMP && buildChartProgressByDMP(canvasRefProgressByDMP, [], [], [], [])
        canvasRefErrorByDMP && buildChartErrorByDMP(canvasRefErrorByDMP, [], [], [])
        setIsToShowBanner(true)
        setIsLoading(false)
      })
  }

  const extractstates = (data: any) => {
    if (data?.stateProvince) {
      setStates(data?.stateProvince)
    }
  }

  const downloadPdf = () => {
    let dates = selectedDateRange.split("|")
    if (dates.length == 0) {
      return
    }
    let fromSplit = dates[0].trim().split("/")
    let toSplit = dates[1].trim().split("/")
    let fromDate = fromSplit[1] + "/" + fromSplit[0] + "/" + fromSplit[2]
    let toDate = toSplit[1] + "/" + toSplit[0] + "/" + toSplit[2]
    const from = fromDate + "T00:00:00"
    const to = toDate + "T23:59:59"
    const dpsRegionService = `DPS_SERVICE_URL_${region}` as keyof serviceUrlProps
    const apiUrl =
      serviceURL[dpsRegionService] +
      "/dps/report/pgp/dashboard?from=" +
      from +
      "&to=" +
      to +
      "&stateCode=" +
      selectedState?.code
    setIsLoading(true)
    axios
      .get(apiUrl, {
        headers: {
          Authorization: "Basic " + appConfig.DPS_TOKEN
        }
      })
      .then(res => {
        setIsLoading(false)
        setIsToShowBanner(false)
        if (res && res.data) {
          generateReportPDF(res.data, dates[0], dates[1])
        }
      })
      .catch(error => {
        setIsLoading(false)
      })
  }

  const generateReportPDF = (data: any, from: string, to: string) => {
    let totalDataList: any[] = []
    if (data?.dsarAndStateCountByDateRange) {
      for (let key in data?.dsarAndStateCountByDateRange) {
        const row = data?.dsarAndStateCountByDateRange[key]
        let curerntData = []
        curerntData.push(key)
        curerntData.push((row["CANCELED"] ? row["CANCELED"] : 0) + (row["FINISHED"] ? row["FINISHED"] : 0))
        curerntData.push(row["FINISHED"] ? row["FINISHED"] : 0)
        curerntData.push(row["CANCELED"] ? row["CANCELED"] : 0)
        curerntData.push(row["ERRORR"] ? row["ERRORR"] : 0)
        curerntData.push(row["Average"])
        totalDataList.push(curerntData)
      }
    }
    const doc = new jsPDF("p", "px")
    doc.text(" From : " + from, 40, 40)
    doc.text(" To : " + to, 300, 40)
    autoTable(doc, {
      styles: { halign: "right" },
      startY: 80,
      head: [["", "Total Recieved", "Fullfilled", "Denied", "Error", "Average No Of Days to Complete"]],
      body: totalDataList
    })
    doc.save(selectedState?.name + ".pdf")
  }
  const generateSortedData = (unSortedData: any) => {
    return Object.entries(unSortedData as ModifiedObjProps)
      .sort((val1, val2) => val2[1].value - val1[1].value)
      .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})
  }

  // create and build portal type chart
  const createPortalTypeData = (dsarResponse: any) => {
    const portalType = dsarResponse.dsarByPortalType
    if (!portalType) {
      return
    }
    let modifiedPortalType: { [key: string]: any } = {}
    portalType.forEach((item: PortalTypeProps) => {
      modifiedPortalType[item.name] = {
        value: item.numRequests,
        color: generateRandomColor()
      }
    })
    modifiedPortalType = generateSortedData(modifiedPortalType)
    const portalTypeNames: string[] = []
    const portalTypeCounts: string[] = []
    const bgColors: string[] = []
    for (const portal in modifiedPortalType) {
      portalTypeNames.push(portal)
      portalTypeCounts.push(modifiedPortalType[portal].value)
      bgColors.push(modifiedPortalType[portal].color)
    }
    portalTypeDispatcher && portalTypeDispatcher(portalTypeNames, portalTypeCounts, bgColors)
    canvasRefPortalType && buildChartPortalType(canvasRefPortalType, portalTypeNames, portalTypeCounts, bgColors)
  }

  // create and build chart for  DSAR by state
  const createStateData = (dsarResponse: any) => {
    const stateList = dsarResponse.dsarByStateProvinceAndType
    if (!stateList) {
      return
    }
    let modifiedStateList = []
    for (const value of Object.values<StateChartProps>(stateList)) {
      modifiedStateList.push({
        value: value.numRequests,
        name: value.stateProvince
      })
    }
    stateTypeDispatcher && stateTypeDispatcher(modifiedStateList)
    canvasRefStateType && buildChartByStates(canvasRefStateType, modifiedStateList)
  }

  // create and build DSAR by type
  const createDsarByTypeData = (dsarResponse: any) => {
    const dsarList = dsarResponse.dsarByType
    if (!dsarList) {
      return
    }
    let modifiedDsarList: { [key: string]: any } = {}
    for (const [key, value] of Object.entries<any>(dsarList)) {
      modifiedDsarList[key] = {
        value: Object.values(value).reduce((acc: number, currentValue: unknown) => acc + (currentValue as number), 0),
        color: generateRandomColor()
      }
    }
    modifiedDsarList = generateSortedData(modifiedDsarList)
    const dsarNames: string[] = []
    const dsarCount: string[] = []
    const bgColors: string[] = []
    for (const dsar in modifiedDsarList) {
      dsarNames.push(dsar)
      dsarCount.push(modifiedDsarList[dsar].value)
      bgColors.push(modifiedDsarList[dsar].color)
    }
    dsarByTypeDispatcher && dsarByTypeDispatcher(dsarNames, dsarCount, bgColors)
    canvasRefDsarType && buildChartByDSARType(canvasRefDsarType, dsarNames, dsarCount, bgColors)
  }

  // create and build chart for dsar completed by type
  const createFinishedByTypeData = (dsarResponse: any) => {
    const finishedDsarList = dsarResponse.dsarByType
    if (!finishedDsarList) {
      return
    }
    let modifiedFinishedDsarList: { [key: string]: any } = {}
    for (const key in finishedDsarList) {
      modifiedFinishedDsarList[key] = {
        value: finishedDsarList[key].FINISHED ? finishedDsarList[key].FINISHED : 0,
        color: generateRandomColor()
      }
    }
    modifiedFinishedDsarList = generateSortedData(modifiedFinishedDsarList)
    const finishedDsarNames: string[] = []
    const finishedDsarCount: string[] = []
    const bgColors: string[] = []
    for (const dsar in modifiedFinishedDsarList) {
      finishedDsarNames.push(dsar)
      finishedDsarCount.push(modifiedFinishedDsarList[dsar].value)
      bgColors.push(modifiedFinishedDsarList[dsar].color)
    }
    completedByTypeDispatcher && completedByTypeDispatcher(finishedDsarNames, finishedDsarCount, bgColors)
    canvasRefCompletedByType &&
      buildChartFinishedByType(canvasRefCompletedByType, finishedDsarNames, finishedDsarCount, bgColors)
  }

  // create and build chart for DSAR progress (DS) by type
  const createProgressDSAndDMPByType = (dsarResponse: any) => {
    const progressDsarDSList = dsarResponse.dsarByType
    if (!progressDsarDSList) {
      return
    }
    let modifiedProgressDsarDSList: { [key: string]: any } = {}
    for (const key in progressDsarDSList) {
      modifiedProgressDsarDSList[key] = {
        value:
          (progressDsarDSList[key].DATA_READY ? progressDsarDSList[key].DATA_READY : 0) +
          (progressDsarDSList[key].DATA_DOWNLOADED ? progressDsarDSList[key].DATA_DOWNLOADED : 0) +
          (progressDsarDSList[key].DATA_NOT_AVAILABLE ? progressDsarDSList[key].DATA_NOT_AVAILABLE : 0),
        color: generateRandomColor()
      }
    }
    modifiedProgressDsarDSList = generateSortedData(modifiedProgressDsarDSList)
    const progressDsarNames: string[] = []
    const progressDsarCount: string[] = []
    let bgColors: string[] = []
    for (const dsar in modifiedProgressDsarDSList) {
      progressDsarNames.push(dsar)
      progressDsarCount.push(modifiedProgressDsarDSList[dsar].value)
      bgColors.push(modifiedProgressDsarDSList[dsar].color)
    }
    // progressDSByTypeDispatcher(progressDsarNames, progressDsarCount, bgColors);
    const progressDsarDMPList = dsarResponse.dsarByType
    if (!progressDsarDMPList) {
      return
    }
    let modifiedProgressDsarDMPList: { [key: string]: any } = {}
    for (const key in progressDsarDMPList) {
      modifiedProgressDsarDMPList[key] = {
        value:
          (progressDsarDMPList[key].WAIT_FOR_DMP_REVIEW ? progressDsarDMPList[key].WAIT_FOR_DMP_REVIEW : 0) +
          (progressDsarDMPList[key].WAIT_FOR_DMP_RESPONSE ? progressDsarDMPList[key].WAIT_FOR_DMP_RESPONSE : 0),
        color: generateRandomColor()
      }
    }
    modifiedProgressDsarDMPList = generateSortedData(modifiedProgressDsarDMPList)
    const progressDMPNames: string[] = []
    const progressDMPCount: string[] = []
    // const bgColors = [];
    for (const dmp in modifiedProgressDsarDMPList) {
      progressDMPNames.push(dmp)
      progressDMPCount.push(modifiedProgressDsarDMPList[dmp].value)
      bgColors.push(modifiedProgressDsarDMPList[dmp].color)
    }
    canvasRefProgressDSByType &&
      buildChartProgressDSAndDMP(canvasRefProgressDSByType, progressDsarNames, progressDsarCount, progressDMPCount)
  }

  // create and build chart for DSAR in error state by type
  const createErrorByTypeData = (dsarResponse: any) => {
    const errorDsarList = dsarResponse.dsarByType
    if (!errorDsarList) {
      return
    }
    let modifiedErrorDsarList: { [key: string]: any } = {}
    for (const key in errorDsarList) {
      modifiedErrorDsarList[key] = {
        value: errorDsarList[key].ERROR ? errorDsarList[key].ERROR : 0,
        color: generateRandomColor()
      }
    }
    modifiedErrorDsarList = generateSortedData(modifiedErrorDsarList)
    const errorDsarNames: string[] = []
    const errorDsarCount: string[] = []
    const bgColors: string[] = []
    for (const dsar in modifiedErrorDsarList) {
      errorDsarNames.push(dsar)
      errorDsarCount.push(modifiedErrorDsarList[dsar].value)
      bgColors.push(modifiedErrorDsarList[dsar].color)
    }
    errorByTypeDispatcher && errorByTypeDispatcher(errorDsarNames, errorDsarCount, bgColors)
    canvasRefErrorByType && buildChartErrorByType(canvasRefErrorByType, errorDsarNames, errorDsarCount, bgColors)
  }

  // create and build chart for DSAR in cancelled state by type
  const createCancelledByTypeData = (dsarResponse: any) => {
    const cancelledDsarList = dsarResponse.dsarByType
    if (!cancelledDsarList) {
      return
    }
    let modifiedCancelledDsarList: { [key: string]: any } = {}
    for (const key in cancelledDsarList) {
      modifiedCancelledDsarList[key] = {
        value: cancelledDsarList[key].CANCELED ? cancelledDsarList[key].CANCELED : 0,
        color: generateRandomColor()
      }
    }
    modifiedCancelledDsarList = generateSortedData(modifiedCancelledDsarList)
    const cancelledDsarNames: string[] = []
    const cancelledDsarCount: string[] = []
    const bgColors: string[] = []
    for (const dsar in modifiedCancelledDsarList) {
      cancelledDsarNames.push(dsar)
      cancelledDsarCount.push(modifiedCancelledDsarList[dsar].value)
      bgColors.push(modifiedCancelledDsarList[dsar].color)
    }
    cancelledByTypeDispatcher && cancelledByTypeDispatcher(cancelledDsarNames, cancelledDsarCount, bgColors)
    canvasRefCancelledByType &&
      buildChartCancelledByType(canvasRefCancelledByType, cancelledDsarNames, cancelledDsarCount, bgColors)
  }

  // create and build chart DSAR finished by DMP
  const createFinishedByDMPData = (dsarResponse: any) => {
    const completedDMPDataList = dsarResponse.dsarByDmpAndType
    if (!completedDMPDataList) {
      return
    }
    let modifiedCompletedDMPDataList: { [key: string]: any } = {}
    Object.entries<DMPProps>(completedDMPDataList).forEach(([_, DMPData]: [string, DMPProps]) => {
      if (typeof DMPData === "object" && "requestsByTypeAndResult" in DMPData) {
        const requestsByTypeAndResult = DMPData.requestsByTypeAndResult
        let finishedCount = 0
        for (const [_, value] of Object.entries(requestsByTypeAndResult)) {
          if (value.hasOwnProperty("FINISHED")) {
            finishedCount += value.FINISHED
          }
        }
        modifiedCompletedDMPDataList[DMPData.dmpName] = {
          value: finishedCount,
          color: generateRandomColor()
        }
      }
    })
    modifiedCompletedDMPDataList = generateSortedData(modifiedCompletedDMPDataList)
    const finishedDMPNames: string[] = []
    const finishedDMPCount: string[] = []
    const bgColors: string[] = []
    for (const dmp in modifiedCompletedDMPDataList) {
      finishedDMPNames.push(dmp)
      finishedDMPCount.push(modifiedCompletedDMPDataList[dmp].value)
      bgColors.push(modifiedCompletedDMPDataList[dmp].color)
    }
    finishedByDMPDispatcher && finishedByDMPDispatcher(finishedDMPNames, finishedDMPCount, bgColors)
    canvasRefFinishedByDMP &&
      buildChartFinishedByDMP(canvasRefFinishedByDMP, finishedDMPNames, finishedDMPCount, bgColors)
  }

  // create and build chart DSAR progress by DMP
  const createProgressByDMPData = (dsarResponse: any) => {
    const progressDMPDataList = dsarResponse.dsarByDmpAndType
    if (!progressDMPDataList) {
      return
    }
    let modifiedProgressDMPDataList: { [key: string]: any } = {}
    Object.entries<DMPProps>(progressDMPDataList).forEach(([_, DMPData]: [string, DMPProps]) => {
      if (typeof DMPData === "object" && "requestsByTypeAndResult" in DMPData) {
        const requestsByTypeAndResult = DMPData.requestsByTypeAndResult
        let finishedCount = 0
        for (const [_, value] of Object.entries(requestsByTypeAndResult)) {
          if (value.hasOwnProperty("WAIT_FOR_DMP_RESPONSE") || value.hasOwnProperty("WAIT_FOR_DMP_REVIEW")) {
            finishedCount += value.FINISHED
          }
        }
        modifiedProgressDMPDataList[DMPData.dmpName] = {
          value: finishedCount,
          color: generateRandomColor()
        }
      }
    })
    modifiedProgressDMPDataList = generateSortedData(modifiedProgressDMPDataList)
    const progressDMPNames: string[] = []
    const progressDMPCount: string[] = []
    const bgColors: string[] = []
    for (const dmp in modifiedProgressDMPDataList) {
      progressDMPNames.push(dmp)
      progressDMPCount.push(modifiedProgressDMPDataList[dmp].value)
      bgColors.push(modifiedProgressDMPDataList[dmp].color)
    }
    // progressByDMPDispatcher(progressDMPNames, progressDMPCount, bgColors);

    //error
    const errorDMPDataList = dsarResponse.dsarByDmpAndType
    if (!errorDMPDataList) {
      return
    }
    let modifiedErrorDMPDataList: { [key: string]: any } = {}
    Object.entries<DMPProps>(errorDMPDataList).forEach(([_, DMPData]: [string, DMPProps]) => {
      if (typeof DMPData === "object" && "requestsByTypeAndResult" in DMPData) {
        const requestsByTypeAndResult = DMPData.requestsByTypeAndResult
        let finishedCount = 0
        for (const [_, value] of Object.entries(requestsByTypeAndResult)) {
          if (value.hasOwnProperty("ERROR")) {
            finishedCount += value.ERROR
          }
        }
        modifiedErrorDMPDataList[DMPData.dmpName] = {
          value: finishedCount,
          color: generateRandomColor()
        }
      }
    })
    modifiedErrorDMPDataList = generateSortedData(modifiedErrorDMPDataList)
    const errorDMPNames: string[] = []
    const errorDMPCount: string[] = []
    // const bgColors = [];
    for (const dmp in modifiedErrorDMPDataList) {
      errorDMPNames.push(dmp)
      errorDMPCount.push(modifiedErrorDMPDataList[dmp].value)
      bgColors.push(modifiedErrorDMPDataList[dmp].color)
    }
    // errorByDMPDispatcher(errorDMPNames, errorDMPCount, bgColors);

    //finished
    const completedDMPDataList = dsarResponse.dsarByDmpAndType
    if (!completedDMPDataList) {
      return
    }
    let modifiedCompletedDMPDataList: { [key: string]: any } = {}
    Object.entries<DMPProps>(completedDMPDataList).forEach(([_, DMPData]: [string, DMPProps]) => {
      if (typeof DMPData === "object" && "requestsByTypeAndResult" in DMPData) {
        const requestsByTypeAndResult = DMPData.requestsByTypeAndResult
        let finishedCount = 0
        for (const [_, value] of Object.entries(requestsByTypeAndResult)) {
          if (value.hasOwnProperty("FINISHED")) {
            finishedCount += value.FINISHED
          }
        }
        modifiedCompletedDMPDataList[DMPData.dmpName] = {
          value: finishedCount,
          color: generateRandomColor()
        }
      }
    })
    modifiedCompletedDMPDataList = generateSortedData(modifiedCompletedDMPDataList)
    const finishedDMPCount: string[] = []
    for (const dmp in modifiedCompletedDMPDataList) {
      finishedDMPCount.push(modifiedCompletedDMPDataList[dmp].value)
      bgColors.push(modifiedCompletedDMPDataList[dmp].color)
    }
    canvasRefProgressByDMP &&
      buildChartProgressByDMP(
        canvasRefProgressByDMP,
        progressDMPNames,
        progressDMPCount,
        errorDMPCount,
        finishedDMPCount
      )
  }

  // create and build chart DSAR Error by DMP
  const createErrorByDMPDATA = (dsarResponse: any) => {
    const errorDMPDataList = dsarResponse.dsarByDmpAndType
    if (!errorDMPDataList) {
      return
    }
    let modifiedErrorDMPDataList: { [key: string]: any } = {}
    Object.entries<DMPProps>(errorDMPDataList).forEach(([_, DMPData]: [string, DMPProps]) => {
      if (typeof DMPData === "object" && "requestsByTypeAndResult" in DMPData) {
        const requestsByTypeAndResult = DMPData.requestsByTypeAndResult
        let finishedCount = 0
        for (const [_, value] of Object.entries(requestsByTypeAndResult)) {
          if (value.hasOwnProperty("ERROR")) {
            finishedCount += value.ERROR
          }
        }
        modifiedErrorDMPDataList[DMPData.dmpName] = {
          value: finishedCount,
          color: generateRandomColor()
        }
      }
    })
    modifiedErrorDMPDataList = generateSortedData(modifiedErrorDMPDataList)
    const errorDMPNames: string[] = []
    const errorDMPCount: string[] = []
    const bgColors: string[] = []
    for (const dmp in modifiedErrorDMPDataList) {
      errorDMPNames.push(dmp)
      errorDMPCount.push(modifiedErrorDMPDataList[dmp].value)
      bgColors.push(modifiedErrorDMPDataList[dmp].color)
    }
    errorByDMPDispatcher && errorByDMPDispatcher(errorDMPNames, errorDMPCount, bgColors)
    canvasRefErrorByDMP && buildChartErrorByDMP(canvasRefErrorByDMP, errorDMPNames, errorDMPCount, bgColors)
  }

  // create and build chart for  DSAR by country
  const createCountryData = (dsarResponse: any) => {
    const countryList = dsarResponse.dsarByCountryAndType
    if (!countryList) {
      return
    }
    let modifiedCountryList = []
    for (const value of Object.values<CountryChartProps>(countryList)) {
      modifiedCountryList.push({
        value: value.numRequests,
        country: value.code
      })
    }
    countryTypeDispatcher && countryTypeDispatcher(modifiedCountryList)
  }

  const generateRowData = (name: any, count: any, color: any, total: any) => {
    return name
      .map((portalName: any, index: number) => [
        color ? <LegendColor key={index} color={color[index]} /> : <LegendColor key={index} srno={index + 1} />,
        portalName,
        count[index],
        total !== 0 ? `${Math.round((100 * count[index]) / total)} %` : "0 %"
      ])
      .concat([["", "TOTAL", total, "100 %"]])
      .flat()
  }

  // canvas portal type
  const renderPortalType = () => {
    if (dsarPortalType) {
      const total = __.sum(dsarPortalType["portalTypeCounts"])
      return (
        <div className={styles.sectionDoughnut}>
          <DoughnutChart title={"DSAR By Portal Type"} chartRef={canvasRefPortalType} />
          {dsarPortalType && dsarPortalType["portalTypeNames"] && (
            <LegendTable
              rows={dsarPortalType["portalTypeNames"].length + 1}
              columns={4}
              headers={["Color", "Portal Type", "Count", "Share"]}
              values={generateRowData(
                dsarPortalType["portalTypeNames"],
                dsarPortalType["portalTypeCounts"],
                dsarPortalType["bgColors"],
                total
              )}
            />
          )}
        </div>
      )
    }
  }

  // canvas DSAR by type
  const renderByType = () => {
    if (dsarByType) {
      const total = __.sum(dsarByType["dsarCount"])
      return (
        <div className={styles.sectionDoughnut}>
          <DoughnutChart title={"DSAR by Type"} chartRef={canvasRefDsarType} />
          {dsarByType && dsarByType["dsarNames"] && (
            <LegendTable
              rows={dsarByType["dsarNames"].length + 1}
              columns={4}
              headers={["Color", "DSAR Type", "Count", "Share"]}
              values={generateRowData(dsarByType["dsarNames"], dsarByType["dsarCount"], dsarByType["bgColors"], total)}
            />
          )}
        </div>
      )
    }
  }

  const renderWorldMap = () => {
    if (dsarCountryType) {
      return (
        dsarCountryType["modifiedCountryList"] && (
          <div>
            <div className={styles.worldMap}>
              <WorldMap
                color="#47E17F"
                title="Total DSAR by Country"
                value-suffix="people"
                size={600}
                data={dsarCountryType["modifiedCountryList"]}
                backgroundColor={"transparent"}
              />
            </div>
          </div>
        )
      )
    }
  }

  // canvas dsar by state
  const renderStateType = () => {
    if (dsarStateType) {
      return (
        <div className={styles.sectionDoughnut}>
          <p>Total DSAR by States</p>
          <div ref={canvasRefStateType} style={{ width: "100%", height: "400px" }} />
        </div>
      )
    }
  }

  // canvas DSAR completed by type
  const renderCompletedType = () => {
    if (dsarFinishedByType) {
      const total = __.sum(dsarFinishedByType["completedDsarCount"])
      return (
        <div className={styles.sectionDoughnut}>
          <DoughnutChart title={"DSAR Completed by Type"} chartRef={canvasRefCompletedByType} />
          {dsarFinishedByType && dsarFinishedByType["completedDsarNames"] && (
            <LegendTable
              rows={dsarFinishedByType["completedDsarNames"].length + 1}
              columns={4}
              headers={["Color", "DSAR Type", "Count", "Share"]}
              values={generateRowData(
                dsarFinishedByType["completedDsarNames"],
                dsarFinishedByType["completedDsarCount"],
                dsarFinishedByType["bgColors"],
                total
              )}
            />
          )}
        </div>
      )
    }
  }

  // canvas DSAR progress (DS) by type
  const renderProgressDSAndDmp = () => {
    if (dsarProgressDSByType) {
      return (
        <div className={styles.sectionDoughnut}>
          <div ref={canvasRefProgressDSByType} style={{ width: "100%", height: "400px" }} />
        </div>
      )
    }
  }

  // canvas DSAR error type
  const renderErrorType = () => {
    if (dsarErrorByType) {
      const total = __.sum(dsarErrorByType["errorDsarCount"])
      return (
        <div className={styles.sectionDoughnut}>
          <DoughnutChart title={"DSAR in Error State by Type"} chartRef={canvasRefErrorByType} />
          {dsarErrorByType && dsarErrorByType["errorDsarNames"] && (
            <LegendTable
              rows={dsarErrorByType["errorDsarNames"].length + 1}
              columns={4}
              headers={["Color", "DSAR Type", "Count", "Share"]}
              values={generateRowData(
                dsarErrorByType["errorDsarNames"],
                dsarErrorByType["errorDsarCount"],
                dsarErrorByType["bgColors"],
                total
              )}
            />
          )}
        </div>
      )
    }
  }

  // canvas DSAR cancelled type
  const renderCancelledType = () => {
    if (dsarDeniedByType) {
      const total = __.sum(dsarDeniedByType["cancelledDsarCount"])
      return (
        <div className={styles.sectionDoughnut}>
          <DoughnutChart title={"DSAR in Cancelled State by Type"} chartRef={canvasRefCancelledByType} />
          {dsarDeniedByType && dsarDeniedByType["cancelledDsarNames"] && (
            <LegendTable
              id={"table-cancel"}
              rows={dsarDeniedByType["cancelledDsarNames"].length + 1}
              columns={4}
              headers={["Sr. No.", "DSAR Type", "Count", "Share"]}
              values={generateRowData(
                dsarDeniedByType["cancelledDsarNames"],
                dsarDeniedByType["cancelledDsarCount"],
                null,
                total
              )}
            />
          )}
        </div>
      )
    }
  }
  // canvas DSAR progress by DMP
  const renderAllByDMP = () => {
    if (dsarProgressByDMP) {
      // const total = __.sum(dsarProgressByDMP['progressDMPCount']);
      return (
        <div className={styles.sectionDoughnut}>
          <div ref={canvasRefProgressByDMP} style={{ width: "100%", height: "400%" }} />
        </div>
      )
    }
  }

  const renderRegionFilter = () => {
    return (
      <WppSelect
        onWppChange={handleRegion}
        placeholder="Select Region"
        value={region}
        size="s"
        defaultValue={"DEV"}
        withSearch={true}
      >
        {REGION.map((item, idx) => {
          return (
            <WppListItem key={idx} value={item.value}>
              <p slot="label">{item.value}</p>
            </WppListItem>
          )
        })}
      </WppSelect>
    )
  }

  const renderDateFilter = () => {
    return (
      <WppDatepicker
        size="s"
        range={true}
        onWppChange={selectedDateRangeFn}
        placeholder="dd/mm/yyyy | dd/mm/yyyy"
        locale={{ dateFormat: "dd/MM/yyyy" }}
      />
    )
  }

  const selectedDateRangeFn = (e: any) => {
    setSelectedDateRange(e.target.value)
  }

  const renderStateProvinceFilter = () => {
    return (
      <WppSelect
        onWppChange={handleSelectedState}
        placeholder="Select State"
        value={selectedState}
        size="s"
        withSearch={true}
      >
        {states.map((item, idx) => {
          return (
            <WppListItem key={idx} value={item}>
              <p slot="label">{item?.name}</p>
            </WppListItem>
          )
        })}
      </WppSelect>
    )
  }

  const renderDownloadButton = () => {
    return (
      <WppButton onClick={downloadPdf} variant="secondary" size="s">
        {" "}
        Download Report{" "}
      </WppButton>
    )
  }

  return (
    <>
      {isLoading && <WppProgressIndicator className={styles.customLoader} variant="bar" isShowPercentage={true} />}
      <WppBanner id="banner" type="information" show={isToShowBanner}>
        Unable to fetch information. Please refresh or try after some time.
      </WppBanner>
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            display={"flex"}
            justifyContent={"flex-end"}
            marginTop={"3rem"}
            gap={"3rem"}
          >
            {renderRegionFilter()}
            {!REMOVE_FILTER.includes(region) && (
              <>
                {renderStateProvinceFilter()}
                {renderDateFilter()}
                {renderDownloadButton()}
              </>
            )}
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Typography variant="h4" sx={{ mb: 5 }} style={{ textAlign: "initial", color: "#123751" }}>
              Hello {firstname}, Welcome back!
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            {dsarStats && dsarStats["response"] && (
              <AppWidgetSummary
                title="Total Req Received"
                total={
                  (dsarStats["response"]?.dsarByType?.INFO?.RECEIVED || 0) +
                  (dsarStats["response"]?.dsarByType?.DELETE?.RECEIVED || 0) +
                  (dsarStats["response"]?.dsarByType?.OPT_OUT_FS?.RECEIVED || 0)
                }
                accReq={dsarStats["response"]?.dsarByType?.INFO?.RECEIVED || 0}
                delReq={dsarStats["response"]?.dsarByType?.DELETE?.RECEIVED || 0}
                dnsReq={dsarStats["response"]?.dsarByType?.OPT_OUT_FS?.RECEIVED || 0}
                color="info"
                icon={"ant-design:apple-filled"}
                bgcolor={"white"}
              />
            )}
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            {dsarStats && dsarStats["response"] && (
              <AppWidgetSummary
                title="Total Req Error"
                total={
                  dsarStats &&
                  dsarStats["response"] &&
                  (dsarStats["response"]?.dsarByType?.INFO?.ERROR || 0) +
                    (dsarStats["response"]?.dsarByType?.DELETE?.ERROR || 0) +
                    (dsarStats["response"]?.dsarByType?.OPT_OUT_FS?.ERROR || 0)
                }
                accReq={dsarStats["response"]?.dsarByType?.INFO?.ERROR || 0}
                delReq={dsarStats["response"]?.dsarByType?.DELETE?.ERROR || 0}
                dnsReq={dsarStats["response"]?.dsarByType?.OPT_OUT_FS?.ERROR || 0}
                color="info"
                icon={"ant-design:apple-filled"}
                bgcolor={"white"}
              />
            )}
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            {dsarStats && dsarStats["response"] && (
              <AppWidgetSummary
                title="Total Req Cancelled"
                total={
                  dsarStats &&
                  dsarStats["response"] &&
                  (dsarStats["response"]?.dsarByType?.INFO?.CANCELED || 0) +
                    (dsarStats["response"]?.dsarByType?.DELETE?.CANCELED || 0) +
                    (dsarStats["response"]?.dsarByType?.OPT_OUT_FS?.CANCELED || 0)
                }
                accReq={dsarStats["response"]?.dsarByType?.INFO?.CANCELED || 0}
                delReq={dsarStats["response"]?.dsarByType?.DELETE?.CANCELED || 0}
                dnsReq={dsarStats["response"]?.dsarByType?.OPT_OUT_FS?.CANCELED || 0}
                color="info"
                icon={"ant-design:apple-filled"}
                bgcolor={"white"}
              />
            )}
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            {dsarStats && dsarStats["response"] && (
              <AppWidgetSummary
                title="Total Req Finished"
                total={
                  dsarStats &&
                  dsarStats["response"] &&
                  (dsarStats["response"]?.dsarByType?.INFO?.FINISHED || 0) +
                    (dsarStats["response"]?.dsarByType?.DELETE?.FINISHED || 0) +
                    (dsarStats["response"]?.dsarByType?.OPT_OUT_FS?.FINISHED || 0)
                }
                accReq={dsarStats["response"]?.dsarByType?.INFO?.FINISHED || 0}
                delReq={dsarStats["response"]?.dsarByType?.DELETE?.FINISHED || 0}
                dnsReq={dsarStats["response"]?.dsarByType?.OPT_OUT_FS?.FINISHED || 0}
                color="info"
                icon={"ant-design:apple-filled"}
                bgcolor={"white"}
              />
            )}
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            {renderWorldMap()}
          </Grid>

          <Grid item xs={12} sm={3} md={3}>
            {renderCompletedType()}
          </Grid>

          <Grid item xs={12} sm={3} md={3}>
            {renderErrorType()}
          </Grid>

          <Grid item xs={12} md={8} lg={8}>
            {renderProgressDSAndDmp()}
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            {renderCancelledType()}
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            {renderAllByDMP()}
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            {renderStateType()}
          </Grid>

          <Grid item xs={12} md={3} lg={3}>
            {renderPortalType()}
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            {renderByType()}
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <div />
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(CPPDashboard)
