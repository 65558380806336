import {combineReducers} from 'redux';
import {storeBase64EmailReducer, getOrgByUserRed, storeAccessTokenReducer} from '../app/reducer';
import {selectedSideNavOrgRed, selectedAgencyRed} from '../containers/sideNav/reducer';
import {
    countryTypeReducer, dsarByTypeReducer, finishedByTypeReducer,
    portalTypeReducer, responseStatsReducer, progressDSByTypeReducer,
    progressDMPByTypeReducer, errorByTypeReducer, stateTypeReducer,
    cancelledByTypeReducer, errorByDMPReducer, finishedByDMPReducer, progressByDMPReducer
} from '../containers/cppModule/cppDashboard/reducer';
import {
    caSelectedTypeReducer, caSelectedRegReducer, caSelectedMarketReducer,
    caSelectedAgencyReducer, caRegListReducer, caMarketListReducer,
    caAgencyListReducer, caSelectedInvReducer, caInvListReducer
} from '../containers/piaModule/assessmentList/createAssessment/step1/reducer';
import {
    caGetTemplateReducer,
    caSelectedTemplateReducer,
} from '../containers/piaModule/assessmentList/createAssessment/step2/reducer';
import {
    caSelectedRespondentReducer, caAssessmentName, caGetAssesserRed,
    caSelectedDaysReducer, caGetRespondentReducer,
    caRespondentNoteReducer, caSelectedAssesserReducer
} from '../containers/piaModule/assessmentList/createAssessment/step3/reducer';
import {inventoryRowDetailsRed, launchAssessRed} from '../containers/dmModule/inventoryList/rowDetails/reducer';
import {assessmentRowDetailsRed, inventoryDetailsViaAssessRed} from '../containers/piaModule/assessmentList/rowDetails/reducer';
import {createInventoryRed} from "../containers/dmModule/inventoryList/createInventory/reducer";


const rootReducer = combineReducers({
    storeBase64EmailReducer, getOrgByUserRed, dsarByTypeReducer, responseStatsReducer,launchAssessRed, selectedSideNavOrgRed,
    portalTypeReducer, countryTypeReducer, stateTypeReducer, finishedByTypeReducer,
    progressDSByTypeReducer, progressDMPByTypeReducer, errorByTypeReducer, cancelledByTypeReducer,
    finishedByDMPReducer, progressByDMPReducer, errorByDMPReducer, caSelectedTypeReducer,
    caSelectedInvReducer, caSelectedRegReducer, caSelectedMarketReducer, caSelectedAgencyReducer,
    caInvListReducer, caRegListReducer, caMarketListReducer, caAgencyListReducer, caSelectedTemplateReducer,
    caGetTemplateReducer, caSelectedRespondentReducer, caGetRespondentReducer, caGetAssesserRed, caAssessmentName,
    caSelectedDaysReducer, caRespondentNoteReducer, caSelectedAssesserReducer, inventoryRowDetailsRed, assessmentRowDetailsRed,
    inventoryDetailsViaAssessRed, createInventoryRed, storeAccessTokenReducer, selectedAgencyRed
});
export default rootReducer;

