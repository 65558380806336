import React, {useCallback, useEffect, useState} from 'react';
import styles from './LegacyInventories.module.scss';
import {useOs} from '@wppopen/react';
import {WppBanner, WppListItem, WppSelect,} from "@wppopen/components-library-react";
import {connect} from 'react-redux';
import {Container, Grid} from "@mui/material";
import {INVENTORY_SEARCHS, SELECT_TYPES, INVENTORY} from "../../../helper/constants";
import SearchComp from "../../../components/search/SearchComp";
import SearchResultComp from "../../../components/search/SearchResultComp";
import DataGridOfflineComp from "../../../components/dataGrid/DataGridOfflineComp";
import DataGridComp from "../../../components/dataGrid/DataGridComp";
import serviceURL from "../../../helper/serviceURL";
import { searchData} from "../../../helper/interface";
import { convertToTitleCase } from "../../../helper/Helper"

const mapStateToProps = (state: any) => {
    return {
        base64Email: state.storeBase64EmailReducer.data,
        selectedSideOrg: state.selectedSideNavOrgRed.data,
    };
};

export const LegacyInventories = ({base64Email, selectedSideOrg}: any) => {

    const initialState = {id: 1, name: INVENTORY.TYPE_DA};
    const pageToStart = 0;
    const pageSize = 25;
    const [selectedType, setSelectedType] = useState(initialState);
    const [isLoading, setIsLoading] = useState(false);
    const [isToShowBanner, setIsToShowBanner] = useState(false);
    const [totalElements, setTotalElements] = useState(0);
    const [initialGridList, setInitialGridList] = useState([]);
    const [searchResults, setSearchResults] = useState<any>([]);
    const {osApi: { getAccessToken }} = useOs();
    const isFetchBySelectType = true;
    const baseApiUrl = `${serviceURL.pgpBaseAPI}/api/legacy`;
    const headers = {
        accept: '*/*',
        Authorization: 'Bearer ' + getAccessToken()
    };
    const [queryVal, setQueryVal] = useState<string>('');
    const [triggerFetch, setTriggerFetch] = useState(false);
    const [tableHeader, setTableHeader] = useState<any>([]);

    useEffect(() => {
        if (base64Email && selectedSideOrg && selectedSideOrg.id && selectedType) {
            setInitialGridList([]);
            setSearchResults([]);
            fetchInitialGridList(pageToStart, pageSize);
        }
        setTriggerFetch(false);
    }, [base64Email, selectedSideOrg, selectedType, triggerFetch]);

    const fetchInitialGridList = (startRow: number = 0, endRow: number = pageSize,
                                  queryString: string = `&page=${startRow}&size=${endRow}`) => {
        if (selectedSideOrg) {
            const apiUrl = baseApiUrl + '/inventories?type=' + selectedType.name + queryString +
                '&orgId=' + selectedSideOrg.id;

            const handleErrors = (response: any) => {
                if (!response.ok) {
                    setIsLoading(false);
                    setIsToShowBanner(true);
                    throw Error(response.statusText);
                }
                return response;
            }

            setIsLoading(true);
            fetch(apiUrl, {headers: headers})
                .then(handleErrors)
                .then(httpResponse => httpResponse.json())
                .then(response => {
                    if (response) {
                        let sanitizedData = prepareRow(response.content);
                        setTableHeader(prepareHeader(response.content));
                        if (sanitizedData) {
                            setInitialGridList(sanitizedData);
                            setTotalElements(response.totalElements);
                            setIsToShowBanner(false);
                        }
                    }
                    setIsLoading(false);
                })
                .catch(error => {
                    console.error(error);
                    setIsLoading(false);
                    setIsToShowBanner(true);
                });
        }
    };

    const prepareHeader = (data: any) => {
        let rows = [];
        for (const key of Object.keys(data[0])) {
            rows.push({field: key, headerName: key, width: 200, colId: key});
        }
        return rows;
    }

    const prepareRowData = (rowData: []) => {
        return rowData;
    }

    const prepareRow = useCallback((rowData: []) => {
        return prepareRowData(rowData);
    }, []);

    const handleSelectType = (e: any) => {
        setSelectedType(e.target.value);
    }

    const getFilterQuery = async (val: string, list: object | searchData[]) => {
        setQueryVal(val);
        setSearchResults(list);
    };

    return (
        <div className={styles.container}>
            <Container maxWidth="xl">
                <div className={styles.loadingArea}>
                    <WppBanner id="banner" type="information" show={isToShowBanner}>
                        Unable to fetch information. Please refresh or try after some time.
                    </WppBanner>
                </div>
                <Grid
                    container
                    spacing={{xs: 2, md: 3, sm: 3}}
                    columns={{xs: 4, sm: 8, md: 12}}
                    rowSpacing={10}>
                    <Grid item xs={12} sm={3} md={3}>
                        <WppSelect
                            onWppChange={handleSelectType}
                            placeholder="Select Type"
                            size="s"
                            value={selectedType}
                        >
                            {SELECT_TYPES.map((item, idx) => {
                                return (
                                    <WppListItem key={idx} value={item} defaultValue={selectedType.name}>
                                        <p slot="label">{convertToTitleCase(item.name)}</p>
                                    </WppListItem>
                                );
                            })}
                        </WppSelect>
                    </Grid>
                    {INVENTORY_SEARCHS.length > 0 && selectedSideOrg &&
                        <SearchComp searchList={INVENTORY_SEARCHS}
                                    filterQuery={getFilterQuery}
                                    initialGridList={initialGridList}/>
                    }

                    {selectedSideOrg &&
                        <SearchResultComp searchResultList={searchResults}
                                          selectedSideOrg={selectedSideOrg.id}/>}
                    <Grid item xs={12} sm={12} md={12} style={{paddingTop: "5rem"}}>
                        {
                            isLoading ?
                                <DataGridOfflineComp/>
                                :
                                <>
                                    {selectedSideOrg &&
                                        <DataGridComp
                                            columnHeaderDefinition={tableHeader}
                                            initialGridResult={initialGridList}
                                            totalElements={totalElements}
                                            baseApiUrl={baseApiUrl}
                                            prepareRow={prepareRow}
                                            headers={headers}
                                            searchList={INVENTORY_SEARCHS}
                                            queryVal={queryVal}
                                            isFetchBySelectType={isFetchBySelectType}
                                            selectedType={selectedType.name}
                                            noCustomRowBtn={false}
                                            fetchListUrl={baseApiUrl + '/inventories?type=' + selectedType.name + '&orgId=' + selectedSideOrg.id}
                                        />
                                    }
                                </>
                        }
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}

export default connect(mapStateToProps, null)(LegacyInventories);