// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Qir5P{width:100%;margin-top:6rem;height:85%}.syNoq{--wpp-button-secondary-text-color: black;--wpp-button-secondary-border-color: black;--wpp-button-secondary-bg-color-hover: lightgray;--wpp-button-secondary-bg-color-active: lightgray;--wpp-button-secondary-border-color-active: black;--wpp-button-secondary-text-color-active: black;--wpp-button-secondary-border-color-disabled: darkgray;--wpp-button-secondary-text-color-disabled: darkgrey}.J2nkb{margin-bottom:1rem;--wpp-progress-bar-color: black;--wpp-progress-linear-bg-color: black}`, "",{"version":3,"sources":["webpack://./src/containers/ldModule/legacyInventories/LegacyInventories.module.scss"],"names":[],"mappings":"AAAA,OACE,UAAA,CACA,eAAA,CACA,UAAA,CAGF,OACE,wCAAA,CACA,0CAAA,CACA,gDAAA,CACA,iDAAA,CACA,iDAAA,CACA,+CAAA,CACA,sDAAA,CACA,oDAAA,CAGF,OACE,kBAAA,CACA,+BAAA,CACA,qCAAA","sourcesContent":[".container {\n  width: 100%;\n  margin-top: 6rem;\n  height: 85%;\n}\n\n.customBtnSecondary {\n  --wpp-button-secondary-text-color: black;\n  --wpp-button-secondary-border-color: black;\n  --wpp-button-secondary-bg-color-hover: lightgray;\n  --wpp-button-secondary-bg-color-active: lightgray;\n  --wpp-button-secondary-border-color-active: black;\n  --wpp-button-secondary-text-color-active: black;\n  --wpp-button-secondary-border-color-disabled: darkgray;\n  --wpp-button-secondary-text-color-disabled: darkgrey;\n}\n\n.customLoader {\n  margin-bottom: 1rem;\n  --wpp-progress-bar-color: black;\n  --wpp-progress-linear-bg-color: black;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Qir5P`,
	"customBtnSecondary": `syNoq`,
	"customLoader": `J2nkb`
};
export default ___CSS_LOADER_EXPORT___;
