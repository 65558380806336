import { useEffect, useState, useRef } from "react"
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, AxiosError, InternalAxiosRequestConfig } from "axios"
import { useOs } from "@wppopen/react"

interface UseAxiosInterceptorsReturn {
  axiosInstance: AxiosInstance
}

const useAxiosInterceptors = (): UseAxiosInterceptorsReturn => {
  const {
    osApi: { getAccessToken }
  } = useOs()
  const axiosInstance = useRef<AxiosInstance>(axios.create()).current
  const [token, setToken] = useState<string | null>(() => getAccessToken())
  const retryLimit = 3 // Set retry limit

  useEffect(() => {
    // Add a request interceptor
    const requestInterceptor = axiosInstance.interceptors.request.use(
      (config: InternalAxiosRequestConfig) => {
        // Add Authorization header if token is provided
        if (token) {
          config.headers = {
            ...config.headers,
            'Authorization': `Bearer ${token}`,
          }
        }
        return config
      },
      (error: AxiosError) => {
        // Handle request error
        console.error("Request Interceptor Error", error)
        return Promise.reject(error)
      }
    )

    // Add a response interceptor
    const responseInterceptor = axiosInstance.interceptors.response.use(
      (response: AxiosResponse) => {
        // Handle response
        return response
      },
      (error: AxiosError) => {
        const originalRequest = error.config as InternalAxiosRequestConfig & { _retryCount?: number }
        // Handle response error
        console.error("Response Interceptor Error", error)
        if (error?.response?.status === 401) {
          originalRequest._retryCount = originalRequest._retryCount || 0

          if (originalRequest._retryCount >= retryLimit) {
            return Promise.reject(error)
          }
          setToken(getAccessToken())
          return new Promise(resolve => {
            originalRequest._retryCount! += 1
            if (originalRequest.headers) {
              originalRequest.headers["Authorization"] = `Bearer ${token}`
            }
            resolve(axiosInstance(originalRequest))
          })
        }
        return Promise.reject(error)
      }
    )

    // Cleanup function to eject the interceptors when the component unmounts
    return () => {
      axiosInstance.interceptors.request.eject(requestInterceptor)
      axiosInstance.interceptors.response.eject(responseInterceptor)
    }
  }, [token, axiosInstance])

  return { axiosInstance }
}

export default useAxiosInterceptors
