import React, { useState, useEffect, ChangeEvent } from "react"
import { connect } from "react-redux"
import { useOs } from "@wppopen/react"
import { Grid, Container } from "@mui/material"
import {
  WppListItem,
  WppSelect,
  WppInput,
  WppTextareaInput,
  WppButton,
  WppBanner,
  WppIconAdd,
  WppModal,
  WppActionButton,
  WppLabel,
  WppToast
} from "@wppopen/components-library-react"
import { validate } from "../../../../../helper/validate"
import { ValidationError, Step3Props, RespondentValidationError } from "./interface"
import {
  selectedRespondentDispatcher,
  selectedDaysDispatcher,
  assessNameDispatcher,
  respondentNoteDispatcher,
  selectedAssesserDispatcher,
  getRespondentDispatcher
} from "./action"
import { serviceURL } from "../../../../../helper/serviceURL"
import styles from "./Step3.module.scss"
import { INVENTORY } from "../../../../../helper/constants"
import { caSelectedRegReducer } from "../step1/reducer"
import useAxiosInterceptors from "../../../../../hooks/useAxiosInterceptors"
import EmailRespondent from "components/emailRespondent/EmailRespondent"
import TextArea from "components/textArea/TextArea"
import Input from "components/input/Input"

const mapStateToProps = (state: any) => {
  return {
    base64Email: state.storeBase64EmailReducer.data,
    assessmentName: state.caAssessmentName.data,
    selectedType: state.caSelectedTypeReducer.data,
    selectedInv: state.caSelectedInvReducer.data,
    selectedMarket: state.caSelectedMarketReducer.data,
    selectedRegion: state.caSelectedRegReducer.data,
    selectedAgency: state.caSelectedAgencyReducer.data,
    selectedTemplate: state.caSelectedTemplateReducer.data,
    templateList: state.caGetTemplateReducer.data,
    invList: state.caInvListReducer.data,
    respondentList: state.caGetRespondentReducer.data,
    assesserList: state.caGetAssesserRed.data,
    selectedDays: state.caSelectedDaysReducer.data,
    respondentNote: state.caRespondentNoteReducer.data,
    selectedSideOrg: state.selectedSideNavOrgRed.data,
    selectedAgencyRed: state.selectedAgencyRed.data,
    userData: state.getOrgByUserRed.data
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  assessNameDispatcher: (name: string) => dispatch(assessNameDispatcher(name)),
  selectedRespondentDispatcher: (respondent: object) => dispatch(selectedRespondentDispatcher(respondent)),
  getRespondentDispatcher: (respondentList: any) => dispatch(getRespondentDispatcher(respondentList)),
  selectedDaysDispatcher: (days: number) => dispatch(selectedDaysDispatcher(days)),
  respondentNoteDispatcher: (note: string) => dispatch(respondentNoteDispatcher(note)),
  selectedAssesserDispatcher: (assesser: object) => dispatch(selectedAssesserDispatcher(assesser))
})

const Step3 = ({
  closeSideModal,
  selectedRespondentDispatcher,
  selectedSideOrg,
  selectedDaysDispatcher,
  respondentNoteDispatcher,
  selectedAssesserDispatcher,
  selectedDays,
  respondentNote,
  base64Email,
  selectedType,
  selectedInv,
  selectedTemplate,
  getRespondentDispatcher,
  showToast,
  respondentList,
  assessNameDispatcher,
  assessmentName,
  assesserList,
  selectedMarket,
  selectedAgency,
  selectedRegion,
  selectedAgencyRed,
  userData
}: Step3Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const [newRespEmail, setNewRespEmail] = useState("")
  const [newRespName, setNewRespName] = useState("")
  const daysOptions = Array.from({ length: 60 }, (_, index) => index + 1)
  const [validationErrors, setValidationErrors] = useState<ValidationError[]>()
  const [showSpinner, setShowSpinner] = useState(false)
  const [showCreateSpinner, setCreateSpinner] = useState(false)
  const [isToShowBanner, setIsToShowBanner] = useState(false)
  const [errCreateResMsg, setErrCreateResMsg] = useState("")
  const [assessmentToast, setAssessmentToast] = useState(false)
  const [bannerMsg, setBannerMsg] = useState("")
  const [respondentValidationErrors, setRespondentValidationErrors] = useState<RespondentValidationError[]>()
  const [respondentEmail, setRespondentEmail] = useState<{ id: number; fullname: string; email: string }[]>([
    { id: 1, fullname: "", email: "" }
  ])
  const { axiosInstance } = useAxiosInterceptors()
  const {
    osApi: { getAccessToken }
  } = useOs()
  const headers = {
    accept: "*/*",
    Authorization: "Bearer " + getAccessToken()
  }

  useEffect(() => {
    switch (selectedType?.name) {
      case INVENTORY.TYPE_VE:
        assessNameDispatcher &&
          assessNameDispatcher(selectedInv?.name + " | " + (selectedMarket?.name || selectedInv?.market?.name))
        break
      case INVENTORY.TYPE_PA:
        assessNameDispatcher &&
          assessNameDispatcher(
            selectedInv?.name +
              " | " +
              selectedSideOrg?.name +
              " | " +
              (selectedMarket?.name || selectedInv?.market?.name) +
              " | " +
              (selectedAgency?.name || selectedInv?.agency?.name)
          )
        break
      case INVENTORY.TYPE_DA:
        assessNameDispatcher &&
          assessNameDispatcher(
            selectedInv?.name +
              " | " +
              (selectedRegion?.name || selectedInv?.region?.name) +
              " | " +
              (selectedMarket?.name || selectedInv?.market?.name) +
              " | " +
              selectedSideOrg?.name
          )
        break
    }
  }, [])

  const validateRespondents = (): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    const errors: ValidationError[] = []
    respondentEmail.forEach(respondent => {
      if (!respondent.fullname) {
        errors.push({ name: `fullname-${respondent.id}`, error: "Name is required" })
      }
      if (respondent?.fullname?.length > 50) {
        errors.push({ name: `fullname-${respondent.id}`, error: "Name limit is 50 character." })
      }
      if (!respondent.email) {
        errors.push({ name: `email-${respondent.id}`, error: "Email is required" })
      }
      if (respondent?.email?.toLowerCase().trim() === userData?.userEmail?.toLowerCase().trim()) {
        errors.push({ name: `email-${respondent.id}`, error: "Please use email other than your login email." })
      }
      if (!emailRegex.test(respondent?.email)) {
        errors.push({ name: `email-${respondent.id}`, error: "Enter a valid email." })
      }
      if (respondent?.email?.length > 50) {
        errors.push({ name: `email-${respondent.id}`, error: "Email limit is 50 character.." })
      }
    })
    setRespondentValidationErrors(errors)
    return errors.length === 0
  }

  const launchAssessment = () => {
    let validationList: any[]
    let validateFields: object
    validateFields = {
      nameOfAssessment: assessmentName,
      selectedDays: selectedDays
    }
    validationList = validate(validateFields)
    setValidationErrors(validationList)
    const hasError = validateRespondents()
    const found = validationList.map((item: any) => !!item.error).includes(true)
    if (!found && hasError) {
      const apiUrl = serviceURL.pgpBaseAPI + "/api/assessments/assessment/create/"
      setShowSpinner(true)
      let postData = {}
      if (selectedType && selectedTemplate && selectedInv && selectedSideOrg) {
        postData = {
          name: assessmentName?.trim(),
          description: respondentNote,
          inventoryId: selectedInv.id,
          inventoryTypeId: selectedType.id,
          orgId: selectedAgencyRed.id,
          assessmentTemplateId: selectedTemplate.id,
          daysToRespond: selectedDays,
          respondents: respondentEmail
        }
      }
      axiosInstance
        .post(apiUrl, postData, {
          headers: headers
        })
        .then(() => {
          setShowSpinner(false)
          setIsToShowBanner(false)
          setBannerMsg("")
          setAssessmentToast(true)
          setTimeout(() => {
            closeSideModal && closeSideModal()
          }, 1200)
        })
        .catch(error => {
          setShowSpinner(false)
          setIsToShowBanner(true)
          setBannerMsg(error.response.data.message)
          console.log(error)
        })
    }
  }

  const handleNoteForRespondent = (e: ChangeEvent<HTMLTextAreaElement>) => {
    respondentNoteDispatcher && respondentNoteDispatcher(e.target.value)
  }

  return (
    <>
      <div className={styles.loadingArea}>
        <WppBanner type="information" closable={true} show={isToShowBanner} className={styles.banner}>
          {bannerMsg}
        </WppBanner>
      </div>
      <Container maxWidth="xl">
        {assessmentToast && (
          <WppToast className={styles.assessmentToast} message={"Assessment created successfully"} type={"success"} />
        )}
        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} rowSpacing={5}>
          <Grid item xs={12} sm={5} md={5}>
            <Input
              id="assessment-name"
              className={styles.wppInputClass}
              label="Assessment Name"
              maxLength={100}
              placeholder="Name Of Assessment"
              message={
                (validationErrors && validationErrors.find((item: any) => item.name === "nameOfAssessment")?.error) ||
                ""
              }
              value={assessmentName}
              onChange={e => assessNameDispatcher && assessNameDispatcher(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={5} md={5}>
            <WppLabel
              className={styles.label}
              config={{ text: "Number of Response Days" }}
              htmlFor="response-days"
              typography="s-strong"
            />
            <WppSelect
              id="response-days"
              onWppChange={e => selectedDaysDispatcher && selectedDaysDispatcher(e.target.value)}
              placeholder="Number Of Days To Respond"
              value={selectedDays}
              withSearch={true}
              size="s"
              message={
                (validationErrors && validationErrors.find((item: any) => item.name === "selectedDays")?.error) || ""
              }
              messageType={
                validationErrors && validationErrors.find((item: any) => item.name === "selectedDays")?.error
                  ? "error"
                  : undefined
              }
            >
              {daysOptions.map(days => (
                <WppListItem key={days} value={days}>
                  <p slot="label">{days}</p>
                </WppListItem>
              ))}
            </WppSelect>
          </Grid>

          <EmailRespondent
            value={respondentEmail}
            setValue={setRespondentEmail}
            validationErrors={respondentValidationErrors}
          />

          <Grid item xs={12} sm={12} md={12}>
            <WppLabel
              className={styles.label}
              config={{ text: "Note For Respondent" }}
              htmlFor="description"
              typography="s-strong"
            />
            <TextArea
              id="description"
              placeholder="Enter Note For Respondant"
              value={respondentNote}
              onChange={e => handleNoteForRespondent(e)}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} display={"flex"} justifyContent={"right"}>
            <WppButton
              className={styles.customBtnSecondary}
              variant={"secondary"}
              loading={showSpinner}
              onClick={launchAssessment}
            >
              Launch Assessment
            </WppButton>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Step3)
