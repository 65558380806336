import React, { useEffect, useState, ChangeEvent } from "react"
import { Grid, Container } from "@mui/material"
import { connect } from "react-redux"
import {
  WppSideModal, WppButton, WppListItem, WppBanner, WppInput, WppTextareaInput,
  WppSelect, WppRadioGroup, WppRadio, WppProgressIndicator, WppSpinner, WppLabel, WppIconError
} from "@wppopen/components-library-react"
import { useOs } from "@wppopen/react"
import { ERROR_MSG, INVENTORY, SELECT_TYPES, TEXT } from "../../../../helper/constants"
import serviceURL from "../../../../helper/serviceURL"
import { validate } from "../../../../helper/validate"
import { USER_ROLE } from "../../../../helper/constants";
import styles from "./CreateVendors.module.scss"
import { CreateVendorsProps, ValidationError } from "../interface"
import {createInventoryDispatcher} from "../../../dmModule/inventoryList/createInventory/action";
import {selectedTypeDispatcher} from "../../../piaModule/assessmentList/createAssessment/step1/action";
import {getInventoryByIdDispatcher, launchAssessDispatcher} from "../../../dmModule/inventoryList/rowDetails/action";
import {selectedAgency} from "../../../sideNav/action";
import { useNavigate } from "react-router-dom"
import { convertToTitleCase } from "../../../../helper/Helper"
import useAxiosInterceptors from '../../../../hooks/useAxiosInterceptors'
import TextArea from "components/textArea/TextArea"
import Input from "components/input/Input"
import CreateAssessment from "containers/piaModule/assessmentList/createAssessment/CreateAssessment"

const mapDispatchToProps = (dispatch: any) => ({
  createInventoryDispatcher: (obj: object) => dispatch(createInventoryDispatcher(obj)),
  selectedTypeDispatcher: (type: object) => dispatch(selectedTypeDispatcher(type)),
  launchAssessDispatcher: (obj: object) => dispatch(launchAssessDispatcher(obj)),
  getInventoryByIdDispatcher: (type: string, id: string, head: object, orgId: number) =>
    dispatch(getInventoryByIdDispatcher(type, id, head, orgId)),
  selectedAgencyDispatcher: (obj: object) => dispatch(selectedAgency(obj)),
});

const mapStateToProps = (state: any) => {
  return {
    base64Email: state.storeBase64EmailReducer.data,
    selectedSideOrg: state.selectedSideNavOrgRed.data,
    userContext: state.getOrgByUserRed.context,
    userData: state.getOrgByUserRed.data
  }
}

const CreateVendors = ({
                         open, onClose, showToast, base64Email, selectedSideOrg,
                         selectedTypeDispatcher, launchAssessDispatcher, getInventoryByIdDispatcher, userContext, userData, selectedAgencyDispatcher
                       }: CreateVendorsProps) => {
  const selectedType = SELECT_TYPES[2];
  const initialState = { id: 0, name: "", code: "" }
  const [isMasterVendor, setIsMasterVendor] = useState("No")
  const [isVendorLinked, setIsInventoryLinked] = useState("No")
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingVendorName, setIsLoadingVendorName] = useState(false)
  const [isToShowBanner, setIsToShowBanner] = useState(false)
  const [vendorName, setVendorName] = useState("")
  const [typeOfService, setTypeOfService] = useState(initialState)
  const [vendorDesc, setVendorDesc] = useState("")
  const [selectedReg, setSelectedReg] = useState(initialState)
  const [selectedMarket, setSelectedMarket] = useState(initialState)
  const [selectedAgency, setSelectedAgency] = useState(initialState)
  const [linkedSelectedType, setLinkedSelectedType] = useState(initialState)
  const [linkedSelectedRelation, setLinkedSelectedRelation] = useState(initialState)
  const [linkedSelectedInventory, setLinkedSelectedInventory] = useState(initialState)
  const [regList, setRegList] = useState([])
  const [marketList, setMarketList] = useState([])
  const [agencyList, setAgencyList] = useState([])
  const [serviceList, setServiceList] = useState([])
  const [inventoryList, setInventoryList] = useState([])
  const [validationErrors, setValidationErrors] = useState<ValidationError[]>()
  const [showSpinner, setShowSpinner] = useState(false)
  const [warningVendorNameExist, setWarningInvNameExist] = useState(false)
  const [relationList, setRelationList] = useState([])
  const [launchAssessment, setLaunchAssessment] = useState(false);
  const { axiosInstance } = useAxiosInterceptors()
  const [isModalOpen, setModalStatus] = useState(false)
  const [assessmentToast, setAssessmentToast] = useState(false)
  const navigate = useNavigate();
  const { osContext, osApi: { getAccessToken }} = useOs()
  const userEmail: string = osContext.userDetails.email
  const headers = {
    accept: "*/*",
    Authorization: 'Bearer ' + getAccessToken()
  }
  const delayTime = 1000
  const isSiteAdmin = userData?.groups.some((group:any) => group?.name === USER_ROLE.SAD);
  const handleRadioLink = (event: any) => {
    setIsInventoryLinked(event.target.value)
  }

  const handleRadioMaster = (event: any) => {
    setIsMasterVendor(event.target.value)
  }

  useEffect(() => {
    let delayTimer: any = null
    if (vendorName) {
      clearTimeout(delayTimer)
      delayTimer = setTimeout(() => {
        fetchInvName()
      }, delayTime)
    } else {
      setWarningInvNameExist(false)
    }
    return () => {
      clearTimeout(delayTimer)
    }
  }, [vendorName])

  useEffect(() => {
    fetchRegionList();
  }, [])

  useEffect(() => {
    if (selectedReg.name !== "") {
      fetchMarketList()
    }
  }, [selectedReg])

  useEffect(() => {
    if (selectedMarket.name !== "") {
      fetchAgencyList()
    }
  }, [selectedMarket])
  
  useEffect(() => {
    if (selectedAgency.name !== "") {
      fetchTypeOfServiceList()
    }
  }, [selectedAgency])

  useEffect(() => {
    if (linkedSelectedType.name !== "") {
      fetchInventoryList()
      fetchRelationList()
    }
  }, [linkedSelectedType])

  const fetchInvName = () => {
    const apiUrl = serviceURL.pgpBaseAPI + "/api/inventory/" + INVENTORY.TYPE_VE +
      "?name=" + encodeURIComponent(vendorName?.trim())
    setIsLoadingVendorName(true)
    axiosInstance
      .get(apiUrl, {
        headers: headers
      })
      .then(() => {
        setIsLoadingVendorName(false)
        setWarningInvNameExist(true)
      })
      .catch((error) => {
        if (error.response.status === 404) {
          setIsLoadingVendorName(false)
          setWarningInvNameExist(false)
        }
        console.log(error)
      })
  }
  
  const fetchRegionList = () => {
    if (selectedSideOrg) {
      const apiUrl = serviceURL.pgpBaseAPI + "/api/organizations/regions"
      setIsLoading(true)
      axiosInstance
        .get(apiUrl, {
          headers: headers
        })
        .then((res) => {
          setIsLoading(false)
          setIsToShowBanner(false)
          setRegList(res.data)
        })
        .catch((error) => {
          setIsLoading(false)
          setIsToShowBanner(true)
          console.log(error)
        })
    }
  }

  const fetchMarketList = () => {
    const apiUrl = serviceURL.pgpBaseAPI + "/api/organizations/regions/" + selectedReg.id + "/markets"
    setIsLoading(true)
    axiosInstance
      .get(apiUrl, {
        headers: headers
      })
      .then((res) => {
        setIsLoading(false)
        setIsToShowBanner(false)
        setMarketList(res.data)
      })
      .catch((error) => {
        setIsLoading(false)
        setIsToShowBanner(true)
        console.log(error)
      })
  }

  const fetchAgencyList = () => {
    const apiUrl = serviceURL.pgpBaseAPI + "/api/organizations/markets/" + userContext.id +"/" + selectedMarket.id + "/agencies?isAdmin="+isSiteAdmin
    setIsLoading(true)
    axiosInstance
      .get(apiUrl, {
        headers: headers
      })
      .then((res) => {
        setIsLoading(false)
        setIsToShowBanner(false)
        setAgencyList(res.data)
      })
      .catch((error) => {
        setIsLoading(false)
        setIsToShowBanner(true)
        console.log(error)
      })
  }

  const fetchTypeOfServiceList = () => {
    const apiUrl = serviceURL.pgpBaseAPI + '/api/organizations/serviceTypes';
    setIsLoading(true)
    axiosInstance
      .get(apiUrl, {
        headers: headers
      })
      .then((res) => {
        setIsLoading(false)
        setIsToShowBanner(false)
        setServiceList(res.data)
      })
      .catch((error) => {
        setIsLoading(false)
        setIsToShowBanner(true)
        console.log(error)
      })
  }

  const fetchInventoryList = () => {
    const apiUrl = serviceURL.pgpBaseAPI + "/api/inventory/inventories?type=" +
      linkedSelectedType.name + "&page=0&size=100&orgId=" + selectedSideOrg.id
    setIsLoading(true)
    axiosInstance
      .get(apiUrl, {
        headers: headers
      })
      .then((res) => {
        setInventoryList(res.data.content)
        setIsLoading(false)
        setIsToShowBanner(false)
      })
      .catch((error) => {
        console.log(error)
        setIsLoading(false)
        setIsToShowBanner(true)
      })
  }

  const fetchRelationList = () => {
    const apiUrl = serviceURL.pgpBaseAPI + "/api/inventory/relation/type"
    setIsLoading(true)
    axiosInstance
      .get(apiUrl, {
        headers: headers
      })
      .then((res) => {
        setRelationList(res.data)
        setIsLoading(false)
        setIsToShowBanner(false)
      })
      .catch((error) => {
        console.log(error)
        setIsLoading(false)
        setIsToShowBanner(true)
      })
  }

  const createVendor = () => {
    let apiUrl: string
    let validationList: any[]
    const postData = {
      name: vendorName?.trim(),
      description: vendorDesc,
      agencyId: selectedAgency.id,
      organizationId:userContext.id,
      marketId: selectedMarket.id,
      regionId: selectedReg.id,
      createdBy: userEmail,
      modifiedBy: userEmail,
      isMasterInventory: isMasterVendor === "Yes",
      serviceTypeId : typeOfService.id
    }
    let validateFields: object
    if (isVendorLinked === "Yes") {
      apiUrl = serviceURL.pgpBaseAPI + "/api/inventory/" + linkedSelectedType.name + "/" +
        linkedSelectedInventory.id + "/" + INVENTORY.TYPE_VE + "?relationTypeId=" + linkedSelectedRelation.id
      validateFields = {
        ...postData,
        selectedType: INVENTORY.TYPE_VE,
        linkedSelectedInventory: linkedSelectedInventory.id,
        linkedSelectedRelation: linkedSelectedRelation.id,
        linkedSelectedType: linkedSelectedType.name
      }
    } else {
      apiUrl = serviceURL.pgpBaseAPI + "/api/inventory/" + INVENTORY.TYPE_VE
      validateFields = {
        ...postData,
        selectedType: INVENTORY.TYPE_VE
      }
    }
    validationList = validate(validateFields)
    setValidationErrors(validationList)
    const found = validationList.map((item: any) => !!item.error).includes(true)
    if (!found) {
      setShowSpinner(true)
      axiosInstance
        .post(apiUrl, postData, {
          headers: headers
        })
        .then((res) => {
          setIsToShowBanner(false)
          setAgencyList(res.data)
          if (showToast) {
            showToast(true)
          }
          // onClose()
          createInventoryDispatcher && createInventoryDispatcher({
            ...postData,
            selectedType: selectedType.name
          });
          getInventoryByIdDispatcher &&
          getInventoryByIdDispatcher(selectedType.name, res?.data?.id, headers, res?.data?.organization?.id)
            .then((res: any) => {
              if (res) {
                setShowSpinner(false);
                setLaunchAssessment(true);
              }
            }).catch((err) => {
            console.log(err);
            setIsToShowBanner(true);
            setIsLoading(false);
          })
        })
        .catch((error) => {
          setShowSpinner(false)
          setIsToShowBanner(true)
          console.log(error)
        })
    }
  }

  const updateValidationList = (fieldName: string) => {
    return validationErrors?.filter((item: any) => item.name !== fieldName)

  }

  const handleVendorName = (e: any) => {
    setVendorName(e.target.value)
    setValidationErrors(updateValidationList("name"))
  }

  const handleRegion = (e: any) => {
    setSelectedReg(e.target.value)
    setSelectedMarket(initialState)
    setSelectedAgency(initialState)
    setTypeOfService(initialState)
    setValidationErrors(updateValidationList("regionId"))
  }

  const handleMarket = (e: any) => {
    setSelectedMarket(e.target.value)
    setSelectedAgency(initialState)
    setTypeOfService(initialState)
    setValidationErrors(updateValidationList("marketId"))
  }

  const handleAgency = (e: any) => {
    setSelectedAgency(e.target.value)
    selectedAgencyDispatcher(e.target.value)
    setTypeOfService(initialState)
    setValidationErrors(updateValidationList("agencyId"))
  }

  const handleTypeOfService = (e: any) => {
    setTypeOfService(e.target.value)
    setValidationErrors(updateValidationList("serviceTypeId"))
  }

  const handleLinkedType = (e: any) => {
    setLinkedSelectedType(e.target.value)
    setLinkedSelectedRelation(initialState)
    setLinkedSelectedInventory(initialState)
    setValidationErrors(updateValidationList("linkedSelectedType"))
  }

  const handleLinkedRelation = (e: any) => {
    setLinkedSelectedRelation(e.target.value)
    setLinkedSelectedInventory(initialState)
    setValidationErrors(updateValidationList("linkedSelectedRelation"))
  }

  const handleLinkedInv = (e: any) => {
    setLinkedSelectedInventory(e.target.value)
    setValidationErrors(updateValidationList("linkedSelectedInventory"))
  }

  const handleLaunchAssessment = () => {
    selectedTypeDispatcher && selectedTypeDispatcher(selectedType);
    launchAssessDispatcher && launchAssessDispatcher({openAssess: true, type: selectedType});
    setModalStatus(true)
  };

  const handleVendorDescription = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setVendorDesc(e.target.value)
  }

  const closeSideModal = () => {
    launchAssessDispatcher && launchAssessDispatcher({})
    setModalStatus(false)
    onClose()
  }
  
  const getShowToast = (val: any) => {
    setAssessmentToast(val)
  }

  return (
    <>
    <WppSideModal open={open} size={"2xl"} onWppSideModalClose={onClose}>
      <h3 className={styles.vendorLabel} slot="header">Create Vendor</h3>
      <div className={styles.vInventoryBody} slot="body">
        <div className={styles.vLoadingArea}>
          {isLoading && <WppProgressIndicator
            className={styles.customLoader}
            variant="bar" />}
          <WppBanner id="banner" type="information" show={isToShowBanner}>
            {ERROR_MSG.UNABLE_FETCH}
          </WppBanner>
        </div>
        <Container maxWidth="xl">
          <Grid container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
                rowSpacing={10}>
            {/* <Grid item xs={12} sm={12} md={12}>
              <p>Do you want to create this inventory as master inventory?</p>
              <div className={styles.masterSubDesc}>{TEXT.MASTER_INV_SUB_DESC}</div>
              <WppRadioGroup className={styles.vRadioGroup} onWppChange={handleRadioMaster}>
                <WppRadio
                  required
                  disabled={launchAssessment}
                  className={styles.radio}
                  name="vendor-master"
                  value="Yes"
                  labelConfig={{ text: "Yes" }} />
                <WppRadio
                  disabled={launchAssessment}
                  className={styles.radio}
                  checked={isMasterVendor === "No"}
                  required
                  name="vendor-master"
                  value="No"
                  labelConfig={{ text: "No" }} />
              </WppRadioGroup>
            </Grid> */}
            <Grid item xs={12} sm={4} md={4} className={styles.vendorName} paddingTop={"5rem !important"}>
              {/* <WppLabel
                className={styles.vLabel}
                config={{ text: "Vendor Name" }}
                htmlFor="v-name"
                typography="s-strong"
              /> */}
              {/* <WppInput
                id="v-name"
                placeholder="Name Of Vendor"
                size={"s"}
                disabled={launchAssessment}
                message={validationErrors && validationErrors.find((item: any) => item.name === "name")?.error || ""}
                messageType={validationErrors && validationErrors.find((item: any) => item.name === "name")?.error ? "error" : undefined}
                value={vendorName}
                onWppChange={handleVendorName}
              /> */}
              <Input 
              id="v-name"
              label="Vendor Name"
              placeholder="Name Of Vendor"
              disabled={launchAssessment}
              maxLength={70}
              message={validationErrors && validationErrors.find((item: any) => item.name === "name")?.error || ""}
              value={vendorName}
              onChange={handleVendorName}
              />
              <div className={styles.vWarningSec}>
                {isLoadingVendorName && <WppSpinner size="s" color={"black"} />}
                {warningVendorNameExist && <p className={styles.warningNameExist}>
                <WppIconError /> Vendor name already exists.
                </p>}
              </div>
            </Grid>
            <Grid item xs={12} sm={4} md={4} paddingTop={"5rem !important"}>
              <WppLabel
                className={styles.vLabel}
                config={{ text: "Region" }}
                htmlFor="v-region"
                typography="s-strong"
              />
              <WppSelect
                id="v-region"
                onWppChange={handleRegion}
                placeholder="Select Region"
                disabled={selectedSideOrg.name === "" || launchAssessment}
                value={selectedReg.name}
                withSearch={true}
                size="s"
                message={validationErrors && validationErrors.find((item: any) => item.name === "regionId")?.error || ""}
                messageType={validationErrors && validationErrors.find((item: any) => item.name === "regionId")?.error ? "error" : undefined}
              >
                {regList?.length ? regList?.map((item: { name: string }, idx: number) => (
                  <WppListItem key={idx} value={item}>
                    <p slot="label">{item.name}</p>
                  </WppListItem>
                )) : <WppListItem>
                  <p slot="label">No Data</p>
                </WppListItem>}
              </WppSelect>
            </Grid>
            <Grid item xs={12} sm={4} md={4} paddingTop={"5rem !important"}>
              <WppLabel
                className={styles.vLabel}
                config={{ text: "Market" }}
                htmlFor="v-market"
                typography="s-strong"
              />
              <WppSelect
                id="v-market"
                onWppChange={handleMarket}
                placeholder="Select Market"
                disabled={selectedReg.name === "" || launchAssessment}
                value={selectedMarket.name}
                withSearch={true}
                size="s"
                message={validationErrors && validationErrors.find((item: any) => item.name === "marketId")?.error || ""}
                messageType={validationErrors && validationErrors.find((item: any) => item.name === "marketId")?.error ? "error" : undefined}
              >
                {marketList?.length ? marketList?.map((item: { name: string }, idx: number) => (
                  <WppListItem key={idx} value={item}>
                    <p slot="label">{item.name}</p>
                  </WppListItem>
                )) : <WppListItem>
                  <p slot="label">No Data</p>
                </WppListItem>}
              </WppSelect>
            </Grid>
            <Grid item xs={12} sm={4} md={4} paddingTop={"5rem !important"}>
              <WppLabel
                className={styles.vLabel}
                config={{ text: "Agency" }}
                htmlFor="v-agency"
                typography="s-strong"
              />
              <WppSelect
                id="v-agency"
                onWppChange={handleAgency}
                placeholder="Select Agency"
                disabled={selectedMarket.name === "" || launchAssessment}
                value={selectedAgency.name}
                withSearch={true}
                size="s"
                message={validationErrors && validationErrors.find((item: any) => item.name === "agencyId")?.error || ""}
                messageType={validationErrors && validationErrors.find((item: any) => item.name === "agencyId")?.error ? "error" : undefined}
              >
                {agencyList?.length ? agencyList?.map((item: { name: string }, idx: number) => (
                  <WppListItem key={idx} value={item}>
                    <p slot="label">{item.name}</p>
                  </WppListItem>
                )) : <WppListItem>
                  <p slot="label">No Data</p>
                </WppListItem>}
              </WppSelect>
            </Grid>
            {/* <Grid item xs={12} sm={4} md={4} paddingTop={"5rem !important"}>
              <WppLabel
                className={styles.vLabel}
                config={{ text: "Type of Service" }}
                htmlFor="v-type-service"
                typography="s-strong"
              />
              <WppInput
                id="v-type-service"
                placeholder="Enter Service"
                size={"s"}
                disabled={launchAssessment}
                message={validationErrors && validationErrors.find((item: any) => item.name === "typeOfService")?.error || ""}
                messageType={validationErrors && validationErrors.find((item: any) => item.name === "typeOfService")?.error ? "error" : undefined}
                value={typeOfService}
                onWppChange={handleTypeOfService}
              />
            </Grid> */}
            <Grid item xs={12} sm={4} md={4} paddingTop={"5rem !important"}>
              <WppLabel
                className={styles.vLabel}
                config={{ text: "Type of Service" }}
                htmlFor="v-type-service"
                typography="s-strong"
              />
               <WppSelect
                id="v-type-service"
                onWppChange={handleTypeOfService}
                placeholder="Select Service"
                disabled={selectedAgency.name === "" || launchAssessment}
                value={typeOfService.name}
                withSearch={true}
                size="s"
                message={validationErrors && validationErrors.find((item: any) => item.name === "serviceTypeId")?.error || ""}
                messageType={validationErrors && validationErrors.find((item: any) => item.name === "serviceTypeId")?.error ? "error" : undefined}
              >
                {serviceList?.length ? serviceList?.map((item: { name: string }, idx: number) => (
                  <WppListItem key={idx} value={item}>
                    <p slot="label">{item.name}</p>
                  </WppListItem>
                )) : <WppListItem>
                  <p slot="label">No Data</p>
                </WppListItem>}
              </WppSelect>
            </Grid>
            <Grid item xs={12} sm={12} md={12} paddingTop={"5rem !important"}>
              <WppLabel
                className={styles.vLabel}
                config={{ text: "Description" }}
                htmlFor="v-description"
                typography="s-strong"
                optional={true}
              />
              <TextArea
                placeholder="Enter Description"
                value={vendorDesc}
                disabled={launchAssessment}
                onChange={(e) => handleVendorDescription(e)}
                />
            </Grid>
            {/* <Grid item xs={12} sm={12} md={12} className={styles.vLinkedInventory}>
              <p>Would you like to link the new inventory to any existing inventory?</p>
              <WppRadioGroup className={styles.vRadioGroup} onWppChange={handleRadioLink}>
                <WppRadio
                  required
                  disabled={launchAssessment}
                  className={styles.radio}
                  name="inventory-link"
                  value="Yes"
                  labelConfig={{ text: "Yes" }} />
                <WppRadio
                  disabled={launchAssessment}
                  className={styles.radio}
                  checked={isVendorLinked === "No"}
                  required
                  name="inventory-link"
                  value="No"
                  labelConfig={{ text: "No" }} />
              </WppRadioGroup>
            </Grid> */}
            {isVendorLinked === "Yes" &&
              <>
                <Grid item xs={12} sm={4} md={4} className={styles.vLinkedInventorySelect}
                      marginBottom={"5rem"}>
                  <WppLabel
                    className={styles.vLabel}
                    config={{ text: "Type" }}
                    htmlFor="v-link_type"
                    typography="s-strong"
                  />
                  <WppSelect
                    id="v-link_type"
                    placeholder="Select Type"
                    value={linkedSelectedType.name}
                    size="s"
                    onWppChange={handleLinkedType}
                    message={validationErrors && validationErrors.find((item: any) => item.name === "linkedSelectedType")?.error || ""}
                    messageType={validationErrors && validationErrors.find((item: any) => item.name === "linkedSelectedType")?.error ? "error" : undefined}
                  >
                    {SELECT_TYPES.map((item, idx) => {
                      return (
                        <WppListItem key={idx} value={item}>
                          <p slot="label">{convertToTitleCase(item.name)}</p>
                        </WppListItem>
                      )
                    })}
                  </WppSelect>
                </Grid>
                <Grid item xs={12} sm={4} md={4} className={styles.vLinkedInventorySelect}
                      marginBottom={"5rem"}>
                  <WppLabel
                    className={styles.vLabel}
                    config={{ text: "Relation" }}
                    htmlFor="v-link_relation"
                    typography="s-strong"
                  />
                  <WppSelect
                    id="v-link_relation"
                    onWppChange={handleLinkedRelation}
                    placeholder={"Select Relation"}
                    disabled={linkedSelectedType.name === ""}
                    value={linkedSelectedRelation.name}
                    withSearch={true}
                    size="s"
                    message={validationErrors && validationErrors.find((item: any) => item.name === "linkedSelectedRelation")?.error || ""}
                    messageType={validationErrors && validationErrors.find((item: any) => item.name === "linkedSelectedRelation")?.error ? "error" : undefined}
                  >
                    {relationList.map((item: { idx: number, name: string }) => {
                      return (
                        <WppListItem key={item.idx} value={item}>
                          <p slot="label">{item.name}</p>
                        </WppListItem>
                      )
                    })}
                  </WppSelect>
                </Grid>
                <Grid item xs={12} sm={4} md={4} className={styles.vLinkedInventorySelect}
                      marginBottom={"5rem"}>
                  <WppLabel
                    className={styles.vLabel}
                    config={{ text: "Inventory" }}
                    htmlFor="v-link_inventory"
                    typography="s-strong"
                  />
                  <WppSelect
                    id="v-link_inventory"
                    onWppChange={handleLinkedInv}
                    placeholder={"Select Inventory"}
                    disabled={linkedSelectedRelation.name === ""}
                    value={linkedSelectedInventory.name}
                    withSearch={true}
                    size="s"
                    message={validationErrors && validationErrors.find((item: any) => item.name === "linkedSelectedInventory")?.error || ""}
                    messageType={validationErrors && validationErrors.find((item: any) => item.name === "linkedSelectedInventory")?.error ? "error" : undefined}
                  >
                    {inventoryList && inventoryList.map((item: { name: string }, idx: number) => {
                      return (
                        <WppListItem key={idx} value={item}>
                          <p slot="label">{item.name}</p>
                        </WppListItem>
                      )
                    })}
                  </WppSelect>
                </Grid>
              </>}
          </Grid>
        </Container>
      </div>
      <div className={styles.btnSection} slot="actions">
        <WppButton
          className={styles.customBtnSecondary}
          id={"create_vendor"}
          disabled={warningVendorNameExist || launchAssessment}
          variant="secondary"
          loading={showSpinner}
          onClick={createVendor}>
          Create Vendor
        </WppButton>
        <WppButton
          className={styles.customBtnSecondary}
          id={"create_inventory"}
          disabled={!launchAssessment}
          variant="secondary"
          onClick={handleLaunchAssessment}>
          Launch Assessment
        </WppButton>
      </div>
    </WppSideModal>
    {isModalOpen && (
      <CreateAssessment open={isModalOpen} closeSideModal={closeSideModal} showToast={getShowToast} />
    )}
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateVendors)
