import React, {useCallback, useEffect, useState} from 'react';
import styles from './RiskRegister.module.scss';
import {WppBanner, WppListItem, WppSelect,} from "@wppopen/components-library-react";
import {useOs} from '@wppopen/react';
import {connect} from 'react-redux';
import {Container, Grid} from "@mui/material";
import {RISK_REGISTER_GRID_HEADER_DETAILS, INVENTORY_SEARCHS, SELECT_TYPES, INVENTORY} from "../../../helper/constants";
// import SearchComp from "../../../components/search/SearchComp";
// import SearchResultComp from "../../../components/search/SearchResultComp";
import DataGridOfflineComp from "../../../components/dataGrid/DataGridOfflineComp";
import DataGridComp from "../../../components/dataGrid/DataGridComp";
import RowDetails from "./RowDetails";
import serviceURL from "../../../helper/serviceURL";
import isObjectEmpty, {convertToTitleCase} from "../../../helper/Helper";
import {searchData} from "../../../helper/interface";

const mapStateToProps = (state: any) => {
    return {
        base64Email: state.storeBase64EmailReducer.data,
        selectedSideOrg: state.selectedSideNavOrgRed.data,
    };
};

export const RiskRegister = ({base64Email, selectedSideOrg}: any) => {

    const initialState = {id: 3, name: INVENTORY.TYPE_VE};
    const pageToStart = 0;
    const pageSize = 25;
    const initialRowData = {
        id: '',
        name: '',
        description: '',
        legalEntityInventory: '',
        vendorInventory: '',
        daInventory: '',
        paInventory: ''
    };
    const [clickedRowData, setClickedRowData] = useState<any>(initialRowData);
    const [openClickedRowModal, setOpenClickedRowModal] = useState(false);
    const [selectedType, setSelectedType] = useState(initialState);
    const [isLoading, setIsLoading] = useState(false);
    const [isToShowBanner, setIsToShowBanner] = useState(false);
    const [totalElements, setTotalElements] = useState(0);
    const [initialGridList, setInitialGridList] = useState([]);
    const [searchResults, setSearchResults] = useState<any>([]);
    const isFetchBySelectType = true;
    const baseApiUrl = `${serviceURL.pgpBaseAPI}/api/assessments/risks`;
    const {osApi: { getAccessToken }} = useOs();
    const headers = {
        accept: '*/*',
        Authorization: 'Bearer ' + getAccessToken()
    };
    const [queryVal, setQueryVal] = useState<string>('');
    const [triggerFetch, setTriggerFetch] = useState(false);

    useEffect(() => {
        if (base64Email && selectedSideOrg && selectedSideOrg.id && selectedType) {
            setInitialGridList([]);
            setSearchResults([]);
            fetchInitialGridList(pageToStart, pageSize);
        }
        setTriggerFetch(false);
    }, [base64Email, selectedSideOrg, selectedType, triggerFetch]);

    const fetchInitialGridList = (startRow: number = 0, endRow: number = pageSize,
                                  queryString: string = `&page=${startRow}&size=${endRow}`) => {
        if (selectedSideOrg) {
            const apiUrl = baseApiUrl + '/?type=' + selectedType.name + queryString +
                '&orgId=' + selectedSideOrg.id;

            const handleErrors = (response: any) => {
                if (!response.ok) {
                    setIsLoading(false);
                    setIsToShowBanner(true);
                    throw Error(response.statusText);
                }
                return response;
            }

            setIsLoading(true);
            fetch(apiUrl, {headers: headers})
                .then(handleErrors)
                .then(httpResponse => httpResponse.json())
                .then(response => {
                    if (response) {
                        let sanitizedData = prepareRow(response.content);
                        if (sanitizedData) {
                            setInitialGridList(sanitizedData);
                            setTotalElements(response.totalElements);
                            setIsToShowBanner(false);
                        }
                    }
                    setIsLoading(false);
                })
                .catch(error => {
                    console.error(error);
                    setIsLoading(false);
                    setIsToShowBanner(true);
                });
        }
    };

    const getClickedRow = useCallback((data: object): void => {
        if (!isObjectEmpty(data)) {
            setClickedRowData(data);
            setOpenClickedRowModal(true);
        }
    }, [clickedRowData]);

    const prepareRowData = (rowData: []) => {
        let rows: any = [];
        if (rowData) {
            rowData.forEach((item: any) => {
                rows.push({
                    id: item.id,
                    inventoryId: item.inventoryId,
                    riskScore: item.riskScore,
                    riskLevel: convertToTitleCase(item.riskLevel),
                    status: item.status,
                    mitigationSteps: item.mitigationSteps,
                    dateIdentified: item.dateIdentified,
                    lastUpdated: item.lastUpdated
                });
            })
        }
        return rows;
    }

    const prepareRow = useCallback((rowData: []) => {
        return prepareRowData(rowData);
    }, []);

    const handleSelectType = (e: any) => {
        setSelectedType(e.target.value);
    }

    const getFilterQuery = async (val: string, list: object | searchData[]) => {
        setQueryVal(val);
        setSearchResults(list);
    };

    return (
        <div className={styles.container}>
            <Container maxWidth="xl">
                <div className={styles.loadingArea}>
                    <WppBanner id="banner" type="information" show={isToShowBanner}>
                        Unable to fetch information. Please refresh or try after some time.
                    </WppBanner>
                </div>
                <Grid
                    container
                    spacing={{xs: 2, md: 3, sm: 3}}
                    columns={{xs: 4, sm: 8, md: 12}}
                    rowSpacing={10}>
                    <Grid item xs={12} sm={3} md={3}>
                        <WppSelect
                            onWppChange={handleSelectType}
                            placeholder="Select Type"
                            size="s"
                            value={selectedType}>
                            <WppListItem value={SELECT_TYPES[2]}>
                                <p slot="label">{SELECT_TYPES[2].name}</p>
                            </WppListItem>
                        </WppSelect>
                    </Grid>
                    {/*TODO- Once inventory name is added then uncomment this*/}
                    {/*{INVENTORY_SEARCHS.length > 0 && selectedSideOrg &&*/}
                    {/*    <SearchComp searchList={INVENTORY_SEARCHS}*/}
                    {/*                filterQuery={getFilterQuery}*/}
                    {/*                initialGridList={initialGridList}/>*/}
                    {/*}*/}

                    {/*{selectedSideOrg &&*/}
                    {/*    <SearchResultComp searchResultList={searchResults}*/}
                    {/*                      selectedSideOrg={selectedSideOrg.id}/>}*/}
                    <Grid item xs={12} sm={12} md={12} style={{paddingTop: "5rem"}}>
                        {
                            isLoading ?
                                <DataGridOfflineComp/>
                                :
                                <>
                                    {selectedSideOrg &&
                                        <DataGridComp
                                            columnHeaderDefinition={RISK_REGISTER_GRID_HEADER_DETAILS}
                                            initialGridResult={initialGridList}
                                            totalElements={totalElements}
                                            clickedRow={getClickedRow}
                                            baseApiUrl={baseApiUrl}
                                            prepareRow={prepareRow}
                                            headers={headers}
                                            searchList={INVENTORY_SEARCHS}
                                            queryVal={queryVal}
                                            isFetchBySelectType={isFetchBySelectType}
                                            selectedType={selectedType.name}
                                            noCustomRowBtn={false}
                                            fetchListUrl={baseApiUrl + '/?type=' + selectedType.name + '&orgId=' + selectedSideOrg.id} //add list of attributes
                                        />
                                    }
                                </>
                        }
                    </Grid>
                </Grid>
                {openClickedRowModal &&
                    <RowDetails
                        open={openClickedRowModal}
                        clickedRowData={clickedRowData}
                        onClose={() => setOpenClickedRowModal(false)}
                        typeOfParentInventory={selectedType}
                    />}
            </Container>
        </div>
    );
}

export default connect(mapStateToProps, null)(RiskRegister);