import {
    SELECTED_TYPE, SELECTED_REGION, SELECTED_MARKET, SELECTED_AGENCY,
    GET_REG_LIST, GET_MARKET_LIST, GET_AGENCY_LIST, GET_INV_LIST, SELECTED_INVENTORY
} from './types';

const initialState = {
    data: ''
};

export function caSelectedTypeReducer(state = initialState, action: any) {
    if (action.type === SELECTED_TYPE) {
        return {
            ...state,
            data: action.payload,
        };
    } else {
        return state;
    }
}

export function caSelectedInvReducer(state = initialState, action: any) {
    if (action.type === SELECTED_INVENTORY) {
        return {
            ...state,
            data: action.payload,
        };
    } else {
        return state;
    }
}

export function caSelectedRegReducer(state = initialState, action: any) {
    if (action.type === SELECTED_REGION) {
        return {
            ...state,
            data: action.payload,
        };
    } else {
        return state;
    }
}

export function caSelectedMarketReducer(state = initialState, action: any) {
    if (action.type === SELECTED_MARKET) {
        return {
            ...state,
            data: action.payload,
        };
    } else {
        return state;
    }
}

export function caSelectedAgencyReducer(state = initialState, action: any) {
    if (action.type === SELECTED_AGENCY) {
        return {
            ...state,
            data: action.payload,
        };
    } else {
        return state;
    }
}

export function caInvListReducer(state = initialState, action: any) {
    if (action.type === GET_INV_LIST) {
        return {
            ...state,
            data: action.payload,
        };
    } else {
        return state;
    }
}

export function caRegListReducer(state = initialState, action: any) {
    if (action.type === GET_REG_LIST) {
        return {
            ...state,
            data: action.payload,
        };
    } else {
        return state;
    }
}

export function caMarketListReducer(state = initialState, action: any) {
    if (action.type === GET_MARKET_LIST) {
        return {
            ...state,
            data: action.payload,
        };
    } else {
        return state;
    }
}

export function caAgencyListReducer(state = initialState, action: any) {
    if (action.type === GET_AGENCY_LIST) {
        return {
            ...state,
            data: action.payload,
        };
    } else {
        return state;
    }
}