// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PiuJO{--wpp-button-secondary-text-color: black;--wpp-button-secondary-border-color: black;--wpp-button-secondary-bg-color-hover: lightgray;--wpp-button-secondary-bg-color-active: lightgray;--wpp-button-secondary-border-color-active: black;--wpp-button-secondary-text-color-active: black;--wpp-button-secondary-border-color-disabled: darkgray;--wpp-button-secondary-text-color-disabled: darkgrey;--button-secondary-padding-m: var( --wpp-button-secondary-padding-m, calc(5px - var(--button-border-width)) calc(12px - var(--button-border-width)) )}`, "",{"version":3,"sources":["webpack://./src/components/pdf/pdf.module.scss"],"names":[],"mappings":"AAAA,OACI,wCAAA,CACA,0CAAA,CACA,gDAAA,CACA,iDAAA,CACA,iDAAA,CACA,+CAAA,CACA,sDAAA,CACA,oDAAA,CACA,qJAAA","sourcesContent":[".pdfBtn {\n    --wpp-button-secondary-text-color: black;\n    --wpp-button-secondary-border-color: black;\n    --wpp-button-secondary-bg-color-hover: lightgray;\n    --wpp-button-secondary-bg-color-active: lightgray;\n    --wpp-button-secondary-border-color-active: black;\n    --wpp-button-secondary-text-color-active: black;\n    --wpp-button-secondary-border-color-disabled: darkgray;\n    --wpp-button-secondary-text-color-disabled: darkgrey;\n    --button-secondary-padding-m: var(\n      --wpp-button-secondary-padding-m,\n      calc(5px - var(--button-border-width)) calc(12px - var(--button-border-width))\n    );\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pdfBtn": `PiuJO`
};
export default ___CSS_LOADER_EXPORT___;
