// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QdFPL{align-items:center;display:flex;flex-direction:column;height:100%;gap:2rem;background:#fff;justify-content:center}.IlUxB{color:#6f6f6f}.sig56{text-align:right}.fEN94{margin-bottom:.5rem}`, "",{"version":3,"sources":["webpack://./src/components/unauthorized/UnAuthorized.module.scss"],"names":[],"mappings":"AAAA,OACE,kBAAA,CACA,YAAA,CACA,qBAAA,CACA,WAAA,CACA,QAAA,CACA,eAAA,CACA,sBAAA,CAGF,OACE,aAAA,CAGF,OACE,gBAAA,CAGF,OACE,mBAAA","sourcesContent":[".unAuthContainer {\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  gap: 2rem;\n  background: white;\n  justify-content: center;\n}\n\n.text {\n  color: #6F6F6F;\n}\n\n.btnClose {\n  text-align: right;\n}\n\n.label {\n  margin-bottom: 0.5rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"unAuthContainer": `QdFPL`,
	"text": `IlUxB`,
	"btnClose": `sig56`,
	"label": `fEN94`
};
export default ___CSS_LOADER_EXPORT___;
