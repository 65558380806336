import React from 'react';
import {WppButton, WppIconAdd, WppTypography} from "@wppopen/components-library-react";
import './DataGridCompModule.scss';
import styles from "../../containers/dmModule/inventoryList/InventoryList.module.scss";

const CustomNoRows = (props: any) => {
    const {createModule, createActionModule, noCustomRowBtn} = props;
    const createActionHandler = () => {
        createModule && createModule();
    }

    return (

        <div
            className="ag-overlay-loading-center"
            style={{backgroundColor: 'transparent', height: '9%', boxShadow: 'unset', border: 0}}
        >
            <WppTypography>
                <div className="noDataFound"> No Data Found!</div>
                {noCustomRowBtn ? <WppButton className="customBtnSecondary" variant="secondary" onClick={createActionHandler} size='s'>
                    <WppIconAdd slot="icon-start" className="customIcon"/>
                    {createActionModule}
                </WppButton> : null}
            </WppTypography>
        </div>


    );
};

export default CustomNoRows;