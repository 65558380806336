import {TEXT, USER_ROLE} from "../../helper/constants";
import {Components} from "@wppopen/components-library";
import appConfig from "../../app.config";
import {PROD} from "../../helper/constants";

const isUserAuthorized = (arr1: any, arr2: any) => {
    if (arr1) {
        const isUserAuth = arr1?.groups?.some((item: any) => arr2.includes(item.name));
        return !isUserAuth;
    }
    return true;
}
export const cardList = (roles: any) => {
    if(appConfig.NPROD === PROD) {
        return [
            {
                name: 'Consumer Preference Portal',
                description: TEXT.CPP_CARD,
                navigation: '/cpp/cpp-dashboard',
                disable: isUserAuthorized(roles, [USER_ROLE.CPPA]),
                apps: 2
            },
            {
                name: 'Privacy Review',
                description: TEXT.PR_CARD,
                navigation: '/pia/pia-dashboard',
                disable: isUserAuthorized(roles, [USER_ROLE.SAD, USER_ROLE.VM, USER_ROLE.DPM, USER_ROLE.IM]),
                apps: 3
            },
            {
                name: 'Third Party Risk Management',
                description: TEXT.TPRM_CARD,
                navigation: '/tprm/tprm-dashboard',
                disable: isUserAuthorized(roles, [USER_ROLE.SAD, USER_ROLE.IM, USER_ROLE.AM, USER_ROLE.VM, USER_ROLE.DPM, USER_ROLE.SU]),
                apps: 2
            }
        ]
    } else {
        return [
            {
                name: 'Consumer Preference Portal',
                description: TEXT.CPP_CARD,
                navigation: '/cpp/cpp-dashboard',
                disable: isUserAuthorized(roles, [USER_ROLE.CPPA]),
                apps: 2
            },
            {
               name: 'Data Mapping',
               description: TEXT.DM_CARD,
               navigation: '/dm/dm-dashboard',
               disable: isUserAuthorized(roles, [USER_ROLE.DPM]),
               apps: 3
           },
            {
                name: 'Privacy Review',
                description: TEXT.PR_CARD,
                navigation: '/pia/pia-dashboard',
                disable: isUserAuthorized(roles, [USER_ROLE.SAD, USER_ROLE.VM, USER_ROLE.DPM, USER_ROLE.IM]),
                apps: 3
            },
            {
                name: 'Third Party Risk Management',
                description: TEXT.TPRM_CARD,
                navigation: '/tprm/tprm-dashboard',
                disable: isUserAuthorized(roles, [USER_ROLE.SAD, USER_ROLE.IM, USER_ROLE.AM, USER_ROLE.VM, USER_ROLE.DPM, USER_ROLE.SU]),
                apps: 2
            }
        ]
    }
 
}