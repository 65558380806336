import * as echarts from "echarts";
export const buildAssessmentChart = (
    canvasRefAssessment: any,
    statusCountPA: any,
    statusCountDA: any,
    statusCountVE: any
) => {
    const option = {
        legend: {},
        tooltip: {},
        dataset: {
            source: [
                ['Status', 'Approved', 'Expired', 'Waiting', 'Rejected', 'Review', 'Risky'],
                ['Data Asset', ...statusCountDA],
                ['Processing Activities', ...statusCountPA],
                ['Vendors', ...statusCountVE]
            ]
        },
        xAxis: { type: 'category' },
        yAxis: {},
        series: [{ type: 'bar' }, { type: 'bar' }, { type: 'bar' },{ type: 'bar' }, { type: 'bar' }, { type: 'bar' }],
        color: ['#E0FAEA','#C2F5D4','#84EBAA','#47E17F', '#1FBC59', '#15803D']
    };
    const existingChart = echarts.getInstanceByDom(canvasRefAssessment);
    if (existingChart) {
        existingChart.dispose();
    }
    const myChart = echarts.init(canvasRefAssessment.current);
    option && myChart.setOption(option);
};
