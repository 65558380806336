import React from 'react';
import styles from './Reports.module.scss';
import {
    WppButton,
    WppCard,
    WppCardGroup,
    WppIconDownload,
    WppTypography
} from "@wppopen/components-library-react";
import {CardGroupChangeEventDetail} from "@wppopen/components-library";

export const Reports = () => {

    const handleMultipleCardGroupChange = (event: CustomEvent<CardGroupChangeEventDetail>) => {
        console.log('event.detail =>', event.detail)
    }

    return (
        <div className={styles.reports}>
            <WppCardGroup withRadioOrCheckbox={false} onWppChange={handleMultipleCardGroupChange}>
                <div className={styles.card}>
                    <WppCard size="2xl" disabled={true} className={styles.customCard}>
                        <WppTypography slot="header">GDPR Article 30</WppTypography>
                        <WppButton disabled={true} variant="secondary" size='s' className={styles.customBtnSecondary}>
                            <WppIconDownload slot="icon-start" className={styles.customIcon}/>
                            Download Report
                        </WppButton>
                    </WppCard>
                </div>
                <div className={styles.card}>
                    <WppCard size="2xl"  disabled={true} className={styles.customCard}>
                        <WppTypography slot="header">Personal Data Report</WppTypography>
                        <WppButton disabled={true} variant="secondary" size='s' className={styles.customBtnSecondary}>
                           <WppIconDownload slot="icon-start" className={styles.customIcon}/>
                           Download Report
                       </WppButton>
                   </WppCard>
               </div>
            </WppCardGroup>
        </div>
    );
}

export default Reports;