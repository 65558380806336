import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Container, Grid} from '@mui/material';
import {useOs} from '@wppopen/react';
import {connect} from 'react-redux';
import {WppBanner, WppButton, WppIconAdd, WppToast} from '@wppopen/components-library-react';
import serviceURL from '../../../helper/serviceURL';
import CreateVendors from './createVendors/CreateVendors';
import {
    SELECT_TYPES, VENDORS_GRID_HEADER_DETAILS, ERROR_MSG, VENDORS_SEARCHS
} from '../../../helper/constants';
import {VendorsListProps} from './interface';
import styles from './VendorsList.module.scss';
import DataGridComp from '../../../components/dataGrid/DataGridComp';
import isObjectEmpty, {prepareRowData} from "../../../helper/Helper";
import DataGridOfflineComp from '../../../components/dataGrid/DataGridOfflineComp';
import SearchComp from '../../../components/search/SearchComp';
import {searchData} from "../../../helper/interface";
import SearchResultComp from '../../../components/search/SearchResultComp';
import RowDetails from './rowDetails/RowDetails';
import {rowDataProps} from './interface';

const mapStateToProps = (state: any) => {
    return {
        base64Email: state.storeBase64EmailReducer.data,
        selectedSideOrg: state.selectedSideNavOrgRed.data,
    };
};

const VendorsList = React.memo(({
                                    base64Email, selectedSideOrg
                                }: VendorsListProps) => {
    const pageToStart = 0;
    const pageSize = 25;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const initialRowData = {
        id: '',
        name: '',
        description: '',
        legalEntityInventory: '',
        vendorInventory: '',
        daInventory: '',
        paInventory: ''
    };
    const [clickedRowData, setClickedRowData] = useState<any>(initialRowData);
    const [openClickedRowModal, setOpenClickedRowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isToShowBanner, setIsToShowBanner] = useState(false);
    const [totalElements, setTotalElements] = useState(0); // Initialize rowCount to 0
    const [initialGridList, setInitialGridList] = useState([]);
    const [searchResults, setSearchResults] = useState<any>([]);
    const [showToast, setShowToast] = useState(false);
    const baseApiUrl = `${serviceURL.pgpBaseAPI}/api/inventory`;
    const {osApi: { getAccessToken }} = useOs();
    const headers = {
        accept: '*/*',
        Authorization: 'Bearer ' + getAccessToken()
    };
    const [queryVal, setQueryVal] = useState<string>('');
    const isFetchBySelectType = true;   // indicate data grid if fetch list based on select dropdown values API is different for list and search

    const isFirstRun = useRef(true);

    useEffect(() => {
        if (isFirstRun.current) {
            if (base64Email && selectedSideOrg && selectedSideOrg.id) {
                setInitialGridList([]);
                setSearchResults([]);
                fetchInitialGridList(pageToStart, pageSize);
            }
        } else {
            isFirstRun.current = false;
        }
    }, [base64Email]);

    const fetchInitialGridList = (startRow: number = 0, endRow: number = pageSize,
                                  queryString: string = `&page=${startRow}&size=${endRow}`) => {
        if (selectedSideOrg) {
            const apiUrl = baseApiUrl + '/inventories?type=VENDOR' + queryString;

            const handleErrors = (response: any) => {
                if (!response.ok) {
                    setIsLoading(false);
                    setIsToShowBanner(true);
                    throw Error(response.statusText);
                }
                return response;
            }

            setIsLoading(true);
            fetch(apiUrl, {headers: headers})
                .then(handleErrors)
                .then(httpResponse => httpResponse.json())
                .then(response => {
                    if (response) {
                        let sanitizedData = prepareRow(response.content);
                        if (sanitizedData) {
                            setInitialGridList(sanitizedData);
                            setTotalElements(response.totalElements);
                            setIsToShowBanner(false);
                        }
                    }
                    setIsLoading(false);
                })
                .catch(error => {
                    console.error(error);
                    setIsLoading(false);
                    setIsToShowBanner(true);
                });
        }
    };

    const prepareRow = useCallback((rowData: []) => {
        return prepareRowData(rowData);
    }, []);

    const getClickedRow = useCallback((data: rowDataProps): void => {
        if (!isObjectEmpty(data)) {
            setClickedRowData(data);
            setOpenClickedRowModal(true);
        }
    }, [clickedRowData]);
    const handleCreateVendor = useCallback((): void => {
        setIsModalOpen(true);
        setShowToast(false);
    }, []);
    const getFilterQuery = async (val: string, list: object | searchData[]) => {
        setQueryVal(val);
        setSearchResults(list);
    };

    const getShowToast = (val: any) => {
        setShowToast(val);
        fetchInitialGridList(pageToStart, pageSize);
    }

    return (
        <div className={styles.vContainer}>
            <Container maxWidth="xl">
                <div className={styles.vLoadingArea}>
                    <WppBanner id="banner" type="information" show={isToShowBanner}>
                        {ERROR_MSG.UNABLE_FETCH}
                    </WppBanner>
                    {showToast && <WppToast
                        className={styles.vToast}
                        message={'Vendor created successfully'}
                        type={'success'}/>}
                </div>
                <Grid
                    container
                    spacing={{xs: 2, md: 3, sm: 3}}
                    columns={{xs: 4, sm: 8, md: 12}}
                    className={styles.vendorContainer}
                    >
                    {VENDORS_SEARCHS.length > 0 && selectedSideOrg &&
                        <SearchComp searchList={VENDORS_SEARCHS} filterQuery={getFilterQuery}
                                    initialGridList={initialGridList} type="Vendor" />
                    }
                    {selectedSideOrg &&
                        <SearchResultComp searchResultList={searchResults} selectedSideOrg={selectedSideOrg.id}/>}
                    {
                        initialGridList.length ?
                            <Grid item xs={12} sm={12} md={12} display="flex" justifyContent="flex-end"
                                  alignSelf="flex-end" className={styles.createVendorButton}>
                                <WppButton
                                    className={styles.customBtnSecondary}
                                    variant="secondary" onClick={handleCreateVendor} size='s'>
                                    <WppIconAdd slot="icon-start" className={styles.customIcon}/>
                                    Create Vendor
                                </WppButton>
                            </Grid>
                            : null
                    }

                    <Grid item xs={12} sm={12} md={12} style={{paddingTop: "3rem", marginBottom: "5rem", height: "100vh" }}>
                        {
                            isLoading ?
                                <DataGridOfflineComp/>
                                :
                                <>
                                    {selectedSideOrg &&
                                        <DataGridComp
                                            columnHeaderDefinition={VENDORS_GRID_HEADER_DETAILS}
                                            initialGridResult={initialGridList} totalElements={totalElements}
                                            clickedRow={getClickedRow}
                                            baseApiUrl={baseApiUrl}
                                            prepareRow={prepareRow} headers={headers}
                                            searchList={VENDORS_SEARCHS}
                                            queryVal={queryVal}
                                            isFetchBySelectType={isFetchBySelectType}
                                            selectedType={SELECT_TYPES[2].name}
                                            createParentModule={handleCreateVendor}
                                            noCustomRowBtn={true}
                                            type="Vendor"
                                            fetchListUrl={baseApiUrl + '/inventories?type=' + 'VENDOR' + '&orgId=' + selectedSideOrg.id} //add list of attributes
                                        />
                                    }
                                </>
                        }
                    </Grid>
                </Grid>
                {isModalOpen &&
                    <CreateVendors open={isModalOpen} onClose={() => setIsModalOpen(false)}
                                   showToast={getShowToast}/>}
                {openClickedRowModal &&
                    <RowDetails
                        open={openClickedRowModal}
                        clickedRowData={clickedRowData}
                        onClose={() => setOpenClickedRowModal(false)}
                        typeOfParentInventory={SELECT_TYPES[2]}
                        fetchGridData={fetchInitialGridList}
                    />}
            </Container>
        </div>
    );
});

export default connect(mapStateToProps, null)(VendorsList);
