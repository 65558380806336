import axios from 'axios';
import {
    SELECTED_DAYS,GET_ASSESSER,
    SELECTED_RESPONDENT, ASSESSMENT_NAME,
    SELECTED_ASSESSER, RESPONDENT_NOTE, GET_RESPONDENT,
} from './types';
import {serviceURL} from '../../../../../helper/serviceURL';

export const assessmentName = (name: string) => ({
    type: ASSESSMENT_NAME,
    payload: name
});

export const assessNameDispatcher = (name: string) => (dispatch: any) => {
    dispatch(assessmentName(name));
};

export const selectedRespondent = (respondent: object) => ({
    type: SELECTED_RESPONDENT,
    payload: respondent
});

export const selectedRespondentDispatcher = (respondent: object) => (dispatch: any) => {
    dispatch(selectedRespondent(respondent));
};

export const getRespondent = (respondentList: any) => ({
    type: GET_RESPONDENT,
    payload: respondentList
});

export const getRespondentDispatcher = (head: object) => (dispatch: any) => {
    const apiUrl = serviceURL.pgpBaseAPI + '/api/assessments/respondents';
    return new Promise((resolve, reject) => {
        axios.get(apiUrl, {
            headers: head
        }).then((res: any) => {
            dispatch(getRespondent(res.data));
            resolve(res);
        }).catch((error: any) => reject(error));
    });
};

export const selectedDays = (days: number) => ({
    type: SELECTED_DAYS,
    payload: days
});

export const selectedDaysDispatcher = (days: number) => (dispatch: any) => {
    dispatch(selectedDays(days));
};

export const respondentNote = (note: string) => ({
    type: RESPONDENT_NOTE,
    payload: note
});

export const respondentNoteDispatcher = (note: string) => (dispatch: any) => {
    dispatch(respondentNote(note));
};

export const selectedAssesser = (assesser: object) => ({
    type: SELECTED_ASSESSER,
    payload: assesser
});

export const selectedAssesserDispatcher = (assesser: object) => (dispatch: any) => {
    dispatch(selectedAssesser(assesser));
};

export const getAssesser = (assesserList: any) => ({
    type: GET_ASSESSER,
    payload: assesserList
});

export const getAssesserDispatcher = (head: object, orgId: number) => (dispatch: any) => {
    const apiUrl = serviceURL.pgpBaseAPI + '/api/assessments/assessors?orgId=' + orgId;
    return new Promise((resolve, reject) => {
        axios.get(apiUrl, {
            headers: head
        }).then((res: any) => {
            dispatch(getAssesser(res.data));
            resolve(res);
        }).catch((error: any) => reject(error));
    });
};