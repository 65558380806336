import React, {forwardRef, memo, useEffect, useState} from "react"
import {parseQuestionBankIds} from "../../helper/Helper"
import _ from "lodash"
import {WppTextareaInput} from "@wppopen/components-library-react"
import {QUE_TYPE} from "../../helper/constants"
import styles from "./QueAnsAssessment.module.scss"

const Text = memo(forwardRef((props: any, ref) => {
      const parseKey = parseQuestionBankIds(props.textKey)
      const editQnKey = parseQuestionBankIds(props.dpmQnIdx)
      const [textInvVal, setTextInvVal] = useState("")
      const [updatedVal, setUpdatedVal] = useState({id: "", response: "", questionType: ""})
      const apiSavedQnBank = (savedResArray: any) => {
         if (savedResArray.length) {
            const qnBank = savedResArray.find((catItem: any) => catItem.categoryId === parseKey.categoryId)?.questionAnswers?.length ?
               savedResArray.find((item: any) => item.categoryId === parseKey.categoryId)?.questionAnswers
               : []
            const selectedQn = qnBank.length && qnBank.find((qnItem: any) => qnItem.question.id === parseKey.questionId)
            return {qnBank, selectedQn}
         }
         return {}
      }
      const initialiseTextInv = (savedResponse: any, activeResponse: any) => {
         if (savedResponse.length) {
            const receivedSavedQn = apiSavedQnBank(savedResponse).selectedQn
            if (receivedSavedQn) {
               setTextInvVal(receivedSavedQn.response ? receivedSavedQn.response.responseValue : null)
            }
         } else {
            if(props.quen.questionType === QUE_TYPE.PRESELECTED){
               setTextInvVal(props.assessment?.assessment?.inventoryName);
               setUpdatedVal({...updatedVal, response: props.assessment?.assessment?.inventoryName})
            }else{
               setTextInvVal(activeResponse);
               setUpdatedVal({...updatedVal, response: activeResponse})
            }
         }
      }
      const textInvChangeHandler = (char: string) => {
         setTextInvVal(char)
         setUpdatedVal({...updatedVal, response: char})
      }
      const dynamicTextInv = () => {
         let copyQnBank = props.savedResponse.length ? apiSavedQnBank(props.savedResponse) && _.cloneDeep(apiSavedQnBank(props.savedResponse).qnBank) : _.cloneDeep(props.pmQuestionBank)
         for (let i = 0; i < copyQnBank.length; i++) {
            if (copyQnBank[i]["question"]["id"] === props.quen.id) {
               copyQnBank[i] = {...copyQnBank[i], response: {responseValue: textInvVal}}
               break
            }
         }
         props.updateParentTextInventory && props.updateParentTextInventory(copyQnBank)
      }
      useEffect(() => {
         initialiseTextInv(props.savedResponse, props.response)
      }, [])
      useEffect(() => {
         if (!_.isEqual(updatedVal, {id: "", response: "", questionType: ""})) {
            dynamicTextInv()
         }
      }, [textInvVal, props.savedResponse, updatedVal])

      return <>
         {
            props.readAccess ?
               <>
                  {
                     props.response === null  && props.quen.questionType !== QUE_TYPE.PRESELECTED ?
                        <p>No Response</p>
                        :
                        <WppTextareaInput
                           key={`text-inventory-editAccess-${parseKey.questionId}`}
                           aria-readonly = {true}
                           disabled={props.quen.questionType === QUE_TYPE.PRESELECTED || props.quen.id !== editQnKey.questionId || props.quen.id !== null || props.dpmQnRetrievingSpinner || props.dpmQnSaveSpinner}
                           className={props.readAccess ? styles.disableTextArea : styles.enableTextArea}
                           name="text-response"
                           placeholder="Reponse here..."
                           value={props.quen.questionType === QUE_TYPE.PRESELECTED ? props.assessment?.assessment?.inventoryName : textInvVal}
                           onWppChange={(e) => textInvChangeHandler(e.target.value)}
                        />
                  }
               </>
               :
               <div className={(props.dpmQnRetrievingSpinner || props.dpmQnSaveSpinner) ? styles.loading : ''}>
                  <WppTextareaInput
                     key={`text-inventory-editAccess-${parseKey.questionId}`}
                     aria-readonly = {true}
                     disabled={props.quen.questionType === QUE_TYPE.PRESELECTED || props.quen.id !== editQnKey.questionId || props.dpmQnRetrievingSpinner || props.dpmQnSaveSpinner}
                     className={props.readAccess ? styles.disableTextArea : styles.enableTextArea}
                     name="text-response"
                     placeholder="Reponse here..."
                     value={props.quen.questionType === QUE_TYPE.PRESELECTED ? props.assessment?.assessment?.inventoryName : textInvVal}
                     onWppChange={(e) => textInvChangeHandler(e.target.value)}
                  />
               </div>

         }
         </>

   }
))

export default Text;
