import React from 'react';
import {WppSpinner} from "@wppopen/components-library-react";

export default (props: any) => {
    return (
      <React.Fragment>
          <WppSpinner size='l' color={'black'}/>
          <div
            className="ag-overlay-loading-center"
            style={{ backgroundColor: 'transparent', height: '9%', boxShadow: 'unset', border: 0 }}
          >
              <i className="fas fa-hourglass-half"> {props.loadingMessage} </i>
          </div>
      </React.Fragment>


    );
};
