import React, {useState} from 'react';
import styles from './UnAuthorized.module.scss';
import {
    WppButton,
    WppTypography,
    WppModal,
    WppLabel,
    WppInput,
    WppActionButton
} from "@wppopen/components-library-react";

export const UnAuthorized = () => {
    const [open, setOpen] = useState(false);
    const [email, setEmail] = useState('');

    const handleReqAcc = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleSubmit = () => {
        alert('Request submitted');
        setOpen(false);
    }

    return (
        <div className={styles.unAuthContainer}>
            <WppTypography type={'5xl-display'}>
                401
            </WppTypography>
            <WppTypography type={'m-body'} className={styles.text}>
                Sorry, you're not authorized to access this page
            </WppTypography>
            <WppButton variant={'secondary'} onClick={handleReqAcc}>Request Access</WppButton>
            <WppModal open={open}>
                <h3 slot="header">Submit request</h3>
                <p slot="body">
                    <WppLabel
                        className={styles.label}
                        config={{text: 'Email Id'}}
                        htmlFor="req-acc-email"
                        typography="s-strong"
                    />
                    <WppInput
                        id="req-acc-email"
                        placeholder="Enter Email Id"
                        size={'s'}
                        value={email}
                        onWppChange={(e) => setEmail(e.target.value)}
                    />
                </p>
                <div slot="actions" className={styles.btnClose}>
                    <WppActionButton variant="secondary" onClick={handleSubmit}>Submit</WppActionButton>
                    <WppActionButton variant="secondary" onClick={handleClose}>Close</WppActionButton>
                </div>
            </WppModal>
        </div>
    );
}

export default UnAuthorized;