import React from 'react';
import styles from './ServiceDown.module.scss';
import {WppButton, WppTypography} from "@wppopen/components-library-react";

export const ServiceDown = () => {

    const goto = () => {
        window.location.href = 'https://wpp.wpp-stage.os-dev.io/home';
    }

    return (
        <div className={styles.serviceContainer}>
            <img
                width={'200px'}
                height={'150px'}
                src={'https://storage.cloud.google.com/gcs-gcp-cgh-prvcy-npd-amer-pgp-ui-dev/error1.png'}
                alt={'error'}/>
            <WppTypography type={'4xl-display'}>
                Something went wrong
            </WppTypography>
            <WppTypography type={'m-body'} className={styles.text}>
                We have encountered a problem, please refresh and try again. <br/>
                If the problem persists please contact privacy@groupm.com
            </WppTypography>
            <WppButton
                variant="secondary"
                className={styles.customBtnSecondary}
                onClick={goto}>Go to Homepage</WppButton>
        </div>
    );
}

export default ServiceDown;