import React, { forwardRef, memo, useImperativeHandle, useLayoutEffect, useState } from "react"
import styles from "./AssessmentSummary.module.scss"
import { Grid } from "@mui/material"
import { WppAccordion, WppActionButton, WppIconHistory, WppTypography } from "@wppopen/components-library-react"
import serviceURL from "../../helper/serviceURL"
import { connect } from "react-redux"
import useAxiosInterceptors from "../../hooks/useAxiosInterceptors"
import { STATUS } from "../../helper/constants"

const mapStateToProps = (state: any) => {
  return {
    base64Email: state.storeBase64EmailReducer.data,
    isLaunchAssess: state.launchAssessRed.data,
    selectedSideOrg: state.selectedSideNavOrgRed.data
  }
}

const CommentHistory = memo(
  forwardRef((props: any, ref) => {
    const [showHistory, setShowHistory] = useState(false)
    const [comments, setComments] = useState(props.cmts)
    useImperativeHandle(
      ref,
      () => {
        return {}
      },
      []
    )

    const historyContent = () => {
      return (
        <div className={styles.historyContainer}>
          {showHistory && <ul>{comments.length > 0 ? <p>{comments.replace(/\"/g, "")}</p> : null}</ul>}
        </div>
      )
    }
    return (
      <div>
        <WppActionButton variant="secondary" slot="actions" onClick={e => setShowHistory(!showHistory)}>
          <div className={styles.historyBtn}>
            {showHistory ? "Hide Reviewer Comments" : "Show Reviewer Comments"} &nbsp;
            <p>
              <WppIconHistory color="black" size="s"></WppIconHistory>
            </p>
          </div>
        </WppActionButton>
        {historyContent()}
      </div>
    )
  })
)
const AssessmentSummary = ({
  assessRowDetails,
  base64Email,
  callBackDataLoaded,
  reviewerCommentLoaded,
  assessmentRiskCountDetails,
  selectedSideOrg
}: any) => {
  const { axiosInstance } = useAxiosInterceptors()
  const [getReviewerComments, setReviewerComments] = useState("")
  const riskCount =
    (assessmentRiskCountDetails?.details?.length > 0 &&
      assessmentRiskCountDetails.details[assessmentRiskCountDetails.details.length - 1]?.riskQnsCount) ||
    0
  const [riskControl, setRiskControl] = useState([
    { risk: "Risk", control: "Risk Control" },
    {
      risk: "High Risk",
      control: "High Risk Control"
    },
    {
      risk: "No Risk",
      control: "No Risk Control"
    },
    {
      risk: "Medium Risk",
      control: "Medium Risk Control"
    },
    {
      risk: "Low Risk",
      control: "Low Risk Control"
    }
  ])
  useLayoutEffect(() => {
    if (
      assessRowDetails?.status &&
      (assessRowDetails.status.toLowerCase() === "approved" || assessRowDetails.status.toLowerCase() === "rejected") &&
      !reviewerCommentLoaded
    ) {
      fetchReviewerComments(assessRowDetails)
    } else if (reviewerCommentLoaded) {
      setReviewerComments(reviewerCommentLoaded)
    }
  }, [assessRowDetails])
  const fetchReviewerComments = (assessRowDetails: any) => {
    const apiUrl = serviceURL.pgpBaseAPI + "/api/pm/assessment/" + assessRowDetails.id + "/review-comment"
    axiosInstance
      .get(apiUrl)
      .then((res: any) => {
        if (res && res.status === 200 && res.data?.comment?.length > 0) {
          setReviewerComments(res.data.comment)
          callBackDataLoaded && callBackDataLoaded(res.data.comment)
        }
      })
      .catch((error: any) => {
        console.log(error)
      })
  }

  const stageIdentifier = (status: string) => {
    switch (status) {
      case "created":
        return (
          <div className={styles.centreComp}>
            <div className={`${styles.rectangle} ${styles.selectedRectangle} ${styles.arrowRight}`}>
              <div className={styles.flowContent}>{STATUS.CREATED}</div>
            </div>
            <div className={`${styles.rectangle} ${styles.arrowRight}`}>
              <div className={styles.flowContent}>{STATUS.RESPONSE_PENDING}</div>
            </div>
            <div className={`${styles.rectangle} ${styles.arrowRight}`}>
              <div className={styles.flowContent}>{STATUS.REVIEW_PENDING}</div>
            </div>
            <div className={`${styles.rectangle}`}>
              <div className={styles.flowContent}>{STATUS.COMPLETED}</div>
            </div>
          </div>
        )
      case "waiting for response":
      case "response pending":
        return (
          <div className={styles.centreComp}>
            <div className={`${styles.rectangle} ${styles.selectedRectangle} ${styles.arrowRight}`}>
              <div className={styles.flowContent}>{STATUS.CREATED}</div>
            </div>
            <div className={`${styles.rectangle} ${styles.selectedRectangle} ${styles.arrowRight}`}>
              <div className={styles.flowContent}>{STATUS.RESPONSE_PENDING}</div>
            </div>
            <div className={` ${styles.rectangle} ${styles.arrowRight}`}>
              <div className={styles.flowContent}>{STATUS.REVIEW_PENDING}</div>
            </div>
            <div className={` ${styles.rectangle} ${styles.arrowRight}`}>
              <div className={styles.flowContent}>{STATUS.COMPLETED}</div>
            </div>
          </div>
        )
      case "review pending":
      case "in review":
      case "risk-calculation pending":
        return (
          <div className={styles.centreComp}>
            <div className={`${styles.rectangle} ${styles.selectedRectangle} ${styles.arrowRight}`}>
              <div className={styles.flowContent}>{STATUS.CREATED}</div>
            </div>
            <div className={`${styles.rectangle} ${styles.selectedRectangle} ${styles.arrowRight}`}>
              <div className={styles.flowContent}>{STATUS.RESPONSE_PENDING}</div>
            </div>
            <div className={`${styles.rectangle} ${styles.selectedRectangle} ${styles.arrowRight}`}>
              {status === "risk-calculation pending" && (
                <div className={styles.flowContent}>{STATUS.RISK_CALCULATION_PENDING}</div>
              )}
              {status === "review pending" && <div className={styles.flowContent}>{STATUS.REVIEW_PENDING}</div>}
            </div>
            <div className={`${styles.rectangle}`}>
              <div className={styles.flowContent}>{STATUS.COMPLETED}</div>
            </div>
          </div>
        )
      case "completed":
      case "approved":
      case "expired":
      case "rejected":
        return (
          <div className={styles.centreComp}>
            <div className={`${styles.rectangle} ${styles.selectedRectangle} ${styles.arrowRight}`}>
              <div className={styles.flowContent}>{STATUS.CREATED}</div>
            </div>
            <div className={`${styles.rectangle} ${styles.selectedRectangle} ${styles.arrowRight}`}>
              <div className={styles.flowContent}>{STATUS.RESPONSE_PENDING}</div>
            </div>
            <div className={`${styles.rectangle} ${styles.selectedRectangle} ${styles.arrowRight}`}>
              <div className={styles.flowContent}>{STATUS.REVIEW_PENDING}</div>
            </div>
            <div className={`${styles.rectangle} ${styles.selectedRectangle}`}>
              {status === "completed" && <div className={styles.flowContent}>{STATUS.COMPLETED}</div>}
              {status === "approved" && <div className={styles.flowContent}>{STATUS.APPROVED}</div>}
              {status === "rejected" && <div className={styles.flowContent}>{STATUS.REJECTED}</div>}
              {status === "expired" && <div className={styles.flowContent}>{STATUS.EXPIRED}</div>}
            </div>
          </div>
        )
      default:
        return (
          <div className={styles.centreComp}>
            <div className={`${styles.rectangle} ${styles.arrowRight}`}>
              <div className={styles.flowContent}>{STATUS.CREATED}</div>
            </div>
            <div className={`${styles.rectangle} ${styles.arrowRight}`}>
              <div className={styles.flowContent}>{STATUS.RESPONSE_PENDING}</div>
            </div>
            <div className={`${styles.rectangle} ${styles.arrowRight}`}>
              <div className={styles.flowContent}>{STATUS.REVIEW_PENDING}</div>
            </div>
            <div className={`${styles.rectangle}`}>
              <div className={styles.flowContent}>{STATUS.COMPLETED}</div>
            </div>
          </div>
        )
    }
  }

  return (
    <div className={styles.assessSumryContr}>
      <div className={styles.innerAssessSumryContr}>
        <Grid container spacing={{ xs: 2, md: 3, sm: 3 }} columns={{ xs: 12, sm: 12, md: 12 }} rowSpacing={3}>
          {/* {riskCount > 0 && (
            <Grid
              item
              xs={12}
              sm={assessRowDetails?.status ? 6 : 12}
              md={assessRowDetails?.status ? 6 : 12}
              display="flex"
              justifyContent="center"
            >
              <div className={styles.riskComp}>
                <p className={styles.header}>Number Of Risk Assessment Questions:</p>
                <div className={styles.centreComp}>
                  <p className={styles.riskNumber}>{riskCount}</p>
                </div>
              </div>
            </Grid>
          )} */}
          {assessRowDetails && assessRowDetails.status && (
            <Grid item xs={12} sm={riskCount <= 0 ? 12 : 6} md={riskCount <= 0 ? 12 : 6} display="flex">
              <div className={styles.flowComp}>
                {/* <p className={styles.header}>Stage:</p> */}
                {stageIdentifier(assessRowDetails.status?.toLowerCase())}
              </div>
            </Grid>
          )}

          {/* {
          getReviewerComments.length > 0 && <Grid item xs={12} sm={12} md={12} display="flex" paddingTop={"2rem"}
          >
            <div className={styles.commentComp}>
              <CommentHistory
                cmts={getReviewerComments} />
            </div>
          </Grid>
        } */}
          {/* {
          riskControl?.length &&
          <Grid item xs={12} sm={12} md={12} display="flex" paddingTop={"2rem"}>
            <div className={styles.riskControlComp}>
              <p className={styles.header}>Risk Control Mapping:</p>
              {
                riskControl.map((item: any) => (
                  <WppAccordion size="m" withDivider={false}>
                    <WppTypography type="m-strong" slot="header">{item.risk}</WppTypography>
                    <WppTypography>
                      {item.control}
                    </WppTypography>
                  </WppAccordion>
                ))
              }
            </div>
          </Grid>
        } */}
        </Grid>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(AssessmentSummary)
