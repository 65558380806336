import React, {forwardRef, memo, useEffect, useImperativeHandle, useState} from "react"
import {parseQuestionBankIds} from "../../helper/Helper"
import {QUE_TYPE} from "../../helper/constants"
import _ from "lodash"
import styles from "./QueAnsAssessment.module.scss"
import {WppRadio, WppTextareaInput} from "@wppopen/components-library-react"

const SingleSelect = memo(forwardRef((props: any, ref) => {
      const parseKey = parseQuestionBankIds(props.singleSelectKey)
      const editQnKey = parseQuestionBankIds(props.dpmQnIdx);
      const [singleSelectVal, setSingleSelectVal] = useState({
         id: props.quen.id,
         response: {responseValue: ""},
         questionType: props.quen.questionType,
         text: ""
      })
      const handleSingleSelect = (event: any, qnId: string) => {
         const option = event.target.value
         setSingleSelectVal((prevState) => ({
            ...prevState,
            id: qnId,
            response: {responseValue: option.name},
            questionType: props.quen.questionType,
            text: ""
         }))
      }
      const apiSavedQnBank = (savedResArray: any) => {
         if (savedResArray.length) {
            const qnBank = savedResArray.find((catItem: any) => catItem.categoryId === parseKey.categoryId)?.questionAnswers?.length ?
               savedResArray.find((item: any) => item.categoryId === parseKey.categoryId)?.questionAnswers
               : []
            const selectedQn = qnBank.length && qnBank.find((qnItem: any) => qnItem.question.id === parseKey.questionId)
            return {qnBank, selectedQn}
         }
         return {}
      }
      const singleSelectTextHolder = (qn: any, resp: any, singleSelectVal: any) => {
         if (resp) {
            const arr = resp?.trim().length && resp.split("$$")
            if (qn.questionType === QUE_TYPE.SINGLE_SELECT_TEXT || qn.questionType === QUE_TYPE.SINGLE_SELECT_TEXT_OPTIONAL || qn.questionType === QUE_TYPE.SINGLE_SELECT_TEXT_CONDITIONAL) {
               setSingleSelectVal({
                  ...singleSelectVal,
                  response: {responseValue: arr[0]},
                  text: arr[1] || ""
               })
            } else {
               setSingleSelectVal({
                  ...singleSelectVal,
                  response: {responseValue: arr[0]},
               })
            }
         } else {
            setSingleSelectVal({
               ...singleSelectVal,
               id: qn.id,
               response: {responseValue: resp},
               questionType: props.quen.questionType,
               text: ""
            })
         }
      }
      const initialiseSingleSelect = (savedResponse: any, activeResponse: any) => {
         if (savedResponse.length) {
            const receivedSavedQn = apiSavedQnBank(savedResponse).selectedQn
            if (receivedSavedQn) {
               setSingleSelectVal({
                  ...singleSelectVal,
                  response: {responseValue: receivedSavedQn.response ? receivedSavedQn.response.responseValue : receivedSavedQn.response}
               })
            }
         } else {
            singleSelectTextHolder(props.quen, activeResponse, singleSelectVal)
         }
      }
      const dynamicSingleSelect = () => {
         let copyQnBank = props.savedResponse.length ? apiSavedQnBank(props.savedResponse) && _.cloneDeep(apiSavedQnBank(props.savedResponse).qnBank) : _.cloneDeep(props.pmQuestionBank)
         for (let i = 0; i < copyQnBank.length; i++) {
            if (copyQnBank[i]["question"]["id"] === props.quen.id) {
               copyQnBank[i] = {...copyQnBank[i], response: {responseValue: singleSelectVal.response.responseValue}}
               break;
            }
         }
         props.updateParentSingleSelect && props.updateParentSingleSelect(copyQnBank)
      }
      useImperativeHandle(ref, () => {
         return {
            singleSelectVal,
            setSingleSelectVal
         }
      }, [singleSelectVal, setSingleSelectVal])
      useEffect(() => {
         initialiseSingleSelect(props.savedResponse, props.response)
      }, [])

      useEffect(() => {
         // dynamicSingleSelect()
         if (!_.isEqual({
            id: props.quen.id,
            response: {responseValue: ""},
            questionType: props.quen.questionType,
            text: ""
         }, singleSelectVal)) {
            dynamicSingleSelect();
         }
      }, [props.savedResponse, singleSelectVal])
      useEffect(() => {
         if (props.tempQnBank.length === 0 && !props.inlineEdit) {
            initialiseSingleSelect(props.savedResponse, props.response)
         }
      }, [props.tempQnBank])

      return <div key={`single-select-qnId-${parseKey.questionId}`}>
         {
            props.readAccess ?
               <>
                  {
                     props.response === null ?
                        <p>No Response</p>
                        :
                        <div key={`single-select-response-optionContainer`} className={styles.options}>
                           <WppRadio
                              key={`single-select-radioId-key`}
                              disabled={!props.inlineEdit || props.quen.id !== editQnKey.questionId || props.quen.id !== null  || props.dpmQnRetrievingSpinner || props.dpmQnSaveSpinner}
                              className={styles.customRadio}
                              checked={true}
                              required={true} value={props?.response?.trim().split("$$")[0]}
                              labelConfig={{text: props?.response?.trim().split("$$")[0]}}
                              name={props?.response?.trim().split("$$")[0]}
                           />
                           {  ((props?.quen?.questionType === QUE_TYPE.SINGLE_SELECT_TEXT || props?.quen?.questionType === QUE_TYPE.SINGLE_SELECT_TEXT_OPTIONAL || props?.quen?.questionType === QUE_TYPE.SINGLE_SELECT_TEXT_CONDITIONAL) && singleSelectVal["text"]?.length > 0) &&
                              <WppTextareaInput
                                 key={`single-text-${parseKey.questionId}`}
                                 disabled={!props.inlineEdit || props.quen.id !== editQnKey.questionId || props.quen.id !== null || props.dpmQnRetrievingSpinner || props.dpmQnSaveSpinner}
                                 className={props.readAccess ? styles.disableTextArea : styles.enableTextArea}
                                 name="text-response"
                                 placeholder="Reponse here..."
                                 value={singleSelectVal["text"]}
                              />
                           }
                        </div>
                  }
               </>
               :
               <>
                  {props.quen.optionList ?
                     <>
                        {
                           props.quen.optionList.map((option: any, idx: number) => (
                              <div className={(props.dpmQnRetrievingSpinner || props.dpmQnSaveSpinner) ? styles.loading: ''}>
                                 <div key={`single-select-optionContainer-${idx}`}
                                      className={`${props.quen.id === editQnKey.questionId && (!props.dpmQnRetrievingSpinner || !props.dpmQnSaveSpinner) ? styles.options : [styles.options, styles.disabledClass].join(' ')}`}>
                                    <WppRadio
                                       key={`single-select-radioId-${idx}`}
                                       className={styles.customRadio}
                                       disabled={(!props.inlineEdit || props.quen.id !== editQnKey.questionId) && !props.dpmQnRetrievingSpinner && !props.dpmQnSaveSpinner}
                                       checked={
                                          singleSelectVal.response?.responseValue?.length > 0 && singleSelectVal.response.responseValue === option.name
                                       }
                                       required={true}
                                       value={option}
                                       labelConfig={{text: option.name}}
                                       name={option.name}
                                       onWppChange={(e) => {
                                          props.pmQuestionBank.length ? handleSingleSelect(e, props.quen.id) : null
                                       }}
                                    />
                                 </div>
                              </div>
                           ))
                        }</>
                     :
                     null
                  }
                  {
                     singleSelectVal["text"].length > 0 &&
                     <div className={(props.dpmQnRetrievingSpinner || props.dpmQnSaveSpinner) ? styles.loading : ''}>
                        <WppTextareaInput
                           key={`single-text-editAccess-${parseKey.questionId}`}
                           disabled={!props.inlineEdit || props.quen.id !== editQnKey.questionId || props.quen.id !== null || props.dpmQnRetrievingSpinner || props.dpmQnSaveSpinner}
                           className={props.readAccess ? styles.disableTextArea : styles.enableTextArea}
                           name="text-response"
                           placeholder="Reponse here..."
                           value={singleSelectVal["text"]}
                           onWppChange={(e) => {
                           }}
                        />
                     </div>

                  }
               </>
         }
      </div>
   }
))
export default SingleSelect;