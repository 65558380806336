import React, { useState, useEffect, useMemo, ChangeEvent } from "react"
import { Grid, Container } from "@mui/material"
import { connect } from "react-redux"
import { useOs } from "@wppopen/react"
import { useNavigate } from "react-router-dom"
import { AxiosProgressEvent } from "axios"
import {
  WppButton,
  WppSideModal,
  WppAccordion,
  WppTypography,
  WppSelect,
  WppSpinner,
  WppToast,
  WppProgressIndicator,
  WppBanner,
  WppTabs,
  WppTab,
  WppListItem,
  WppActionButton,
  WppLabel,
  WppDivider,
  WppTag,
  WppIconEdit,
  WppIconDone,
  WppInput,
  WppModal,
  WppIconClose,
  WppTextareaInput
} from "@wppopen/components-library-react"
import { TabsChangeEventDetail } from "@wppopen/components-library"
import { ValidationError, AccordItem, AssessAccordItem } from "../interface"
import {
  getInventoryByIdDispatcher,
  launchAssessDispatcher,
  clearInventoryByIdDispatcher
} from "../../../dmModule/inventoryList/rowDetails/action"
import { selectedTypeDispatcher } from "../../../piaModule/assessmentList/createAssessment/step1/action"
import serviceURL from "../../../../helper/serviceURL"
import { validate } from "../../../../helper/validate"
import { SELECT_TYPES, INVENTORY } from "../../../../helper/constants"
import QueAns from "../../../../components/queAns/QueAns"
import styles from "./RowDetails.module.scss"
import { convertToTitleCase, findQuestions, prepareLinkAccordData } from "../../../../helper/Helper"
import { RowDetailsModalProps } from "../../../../helper/interface"
import LinkedInvCard from "../../../../components/linkedInvCard/LinkedInvCard"
import TagStatusVariantIndicator from "../../../../helper/TagStatusVariantIndicator"
import useAxiosInterceptors from "../../../../hooks/useAxiosInterceptors"
import { STATUS, USER_ROLE } from "../../../../helper/constants"
import { getLocalDate } from "../../../../helper/Helper"
import appConfig from "app.config"
import RelaunchAssessment from "containers/piaModule/assessmentList/createAssessment/RelaunchAssessment"
import VendorPdf from "../../../../components/pdf/vendorPdf"
import DataGridOfflineComp from "components/dataGrid/DataGridOfflineComp"
import TextArea from "components/textArea/TextArea"
import CreateAssessment from "containers/piaModule/assessmentList/createAssessment/CreateAssessment"
import FileUpload from "components/fileUpload/FileUpload"

const mapDispatchToProps = (dispatch: any) => ({
  getInventoryByIdDispatcher: (type: string, id: string, head: object, orgId: number) =>
    dispatch(getInventoryByIdDispatcher(type, id, head, orgId)),
  selectedTypeDispatcher: (type: object) => dispatch(selectedTypeDispatcher(type)),
  launchAssessDispatcher: (obj: object) => dispatch(launchAssessDispatcher(obj)),
  clearInventoryByIdDispatcher: (invRes: object) => dispatch(clearInventoryByIdDispatcher(invRes))
})

const mapStateToProps = (state: any) => {
  return {
    base64Email: state.storeBase64EmailReducer.data,
    rowDetails: state.inventoryRowDetailsRed.data,
    selectedSideOrg: state.selectedSideNavOrgRed.data,
    userRoles: state.getOrgByUserRed.data,
    userContext: state.getOrgByUserRed.context
  }
}

const initialAccordArrState: AccordItem[] = []
const iniAssessArrState: AssessAccordItem[] = []

const RowDetails = ({
  open,
  onClose,
  clickedRowData,
  getInventoryByIdDispatcher,
  typeOfParentInventory,
  base64Email,
  rowDetails,
  launchAssessDispatcher,
  selectedTypeDispatcher,
  selectedSideOrg,
  clearInventoryByIdDispatcher,
  userRoles,
  userContext,
  fetchGridData,
}: RowDetailsModalProps) => {
  const navigate = useNavigate()
  const initialState = { id: 0, name: "" }
  const [loadingPercentage, setLoadingPercentage] = useState(0)
  const [vendorName, setVendorName] = useState("")
  const [isLoadingVendorName, setIsLoadingVendorName] = useState(false)
  const [warningVendorNameExist, setWarningInvNameExist] = useState(false)
  const [isEditEnable, setiIsEditEnable] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isToShowBanner, setIsToShowBanner] = useState(false)
  const [openAccordionId, setOpenAccordionId] = useState<string | null>(null)
  const [showBannerLinking, setShowBannerLinking] = useState(false)
  const [currentTab, setCurrentTab] = useState("AdditionalDetails")
  const [selectedType, setSelectedType] = useState(initialState)
  const [selectedRelation, setSelectedRelation] = useState(initialState)
  const [selectedInventory, setSelectedInventory] = useState(initialState)
  const [relationList, setRelationList] = useState([])
  const [inventoryList, setInventoryList] = useState([])
  const [showSpinner, setShowSpinner] = useState(false)
  const [showLinkParentAccSpin, setShowLinkParentAccSpin] = useState(false)
  const [showLinkChildAccSpin, setShowLinkChildAccSpin] = useState(false)
  const [validationErrors, setValidationErrors] = useState<ValidationError[]>()
  const [showAddRelInventory, setShowAddRelInventory] = useState(false)
  const [showToast, setShowToast] = useState(false)
  const [showVendorNameToast, setShowVendorNameToast] = useState(false)
  const [showDeleteToast, setShowDeleteToast] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [accordLinkArr, setLinkAccordArr] = useState<AccordItem[]>(initialAccordArrState)
  const [accordAssessArr, setAccordAssessArr] = useState<AssessAccordItem[]>(iniAssessArrState)
  const [quickSummary, setQuickSummary] = useState<AssessAccordItem[]>(iniAssessArrState)
  const [relaunchModal, setRelaunchModal] = useState(false)
  const [isModalOpen, setModalStatus] = useState(false)
  const [bannerMsg, setBannerMsg] = useState("Unable to fetch inventory. Please refresh or try after some time.")
  const [deleteMessage, setDeleteMessage] = useState("")
  const [showUploadModal, setShowUploadModal] = useState(false)
  const [showUploadSuccessToast, setShowUploadSuccessToast] = useState(false)
  const pageToStart = 0
  const pageSize = 25
  const assessment = useMemo(() => {
    let assment = rowDetails?.assessments
    return assment && assment.length ? assment[0] : {}
  }, [rowDetails?.assessments])
  const respondentEmail = useMemo(() => {
    return assessment?.respondents || []
  }, [assessment])
  const {
    osContext,
    osApi: { getAccessToken }
  } = useOs()
  const userEmail: string = osContext.userDetails.email
  const { axiosInstance } = useAxiosInterceptors()
  const headers = {
    accept: "*/*",
    Authorization: "Bearer " + getAccessToken()
  }
  const delayTime = 1000

  const hasRequiredPermission = (permission: string[]) => {
    return userRoles?.groups?.some((item: any) => permission.includes(item.name))
  }

  useEffect(() =>{
    if(quickSummary && quickSummary.length && quickSummary[0]?.details?.length){
      setCurrentTab('QuickSummary')
    } 
  }, [quickSummary])

  const isUserAuthorized = () => {
    const roles = userRoles?.groups?.map(role => role?.name) || []
    const userAgency = userContext?.agency?.toLowerCase()
    const orgName = rowDetails.organization?.code?.toLowerCase()
    const canAccess = (roleCheck, condition = true) => {
      return roles.includes(roleCheck) && condition
    }

    let linkClass = styles.disableLink
    let linkAction = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => event.preventDefault()

    if (canAccess(USER_ROLE.SAD)) {
      linkClass = styles.assessmentLink
      linkAction = handleAssessment
    } else if (canAccess(USER_ROLE.DPM, orgName === userAgency)) {
      linkClass = styles.assessmentLink
      linkAction = handleAssessment
    }

    return (
      <a className={linkClass} onClick={linkAction}>
        {assessment?.name}
      </a>
    )
  }

  useEffect(() => {
    setVendorName(rowDetails?.name)
  }, [rowDetails?.name])

  useEffect(() => {
    fetchVendorById()
    // return () => {
    //     clearInventoryByIdDispatcher && clearInventoryByIdDispatcher({}).then(() => {
    //         console.log('clearing row details modal');
    //     }).catch(err => {
    //         console.log(err);
    //     })
    // }
  }, [])

  useEffect(() => {
    if (selectedType.name !== "") {
      fetchInventoryList()
      fetchRelationList()
    }
  }, [selectedType])

  const fetchVendorById = () => {
    setIsLoading(true)
    setShowLinkParentAccSpin(true)
    if (clickedRowData && selectedSideOrg) {
      getInventoryByIdDispatcher &&
        getInventoryByIdDispatcher(
          typeOfParentInventory.name,
          clickedRowData.id,
          headers,
          clickedRowData.organizationId
        )
          .then((res: any) => {
            if (res) {
              const accArr = prepareLinkAccordData(res)
              accArr && setLinkAccordArr(accArr)
              prepareAssessmentData(res.assessments)
              prepareQuickSummaryData(res.assessments)
              setShowLinkParentAccSpin(false)
              setIsLoading(false)
            }
          })
          .catch(err => {
            console.log(err)
            setIsToShowBanner(true)
            setShowLinkParentAccSpin(false)
            setIsLoading(false)
          })
    }
  }
  const prepareAssessmentData = (data: any) => {
    let menu: any[]
    menu = data.map((assessment: any) => {
      return {
        assessmentName: assessment.name,
        details: assessment.categories.map((category: any) => {
          let queList = findQuestions(assessment, category.id)
          return {
            categoryName: category.name,
            questionAnswers:
              Array.isArray(queList) &&
              queList.map((que: { question: object; response: object; attachments: Array<object> | null }) => {
                return {
                  question: que.question,
                  response: { ...que.response, attachments: que?.attachments || [] }
                }
              })
          }
        })
      }
    })
    setAccordAssessArr(menu)
  }

  const prepareQuickSummaryData = (data: any) => {
    let menu: any[]
    menu = data.map((assessment: any) => {
      return {
        assessmentName: assessment.name,
        details: assessment.categories
          .map((category: any) => {
            let queList = findQuestions(assessment, category.id)
            let filteredQueList = Array.isArray(queList)
              ? queList.filter((que: { question: { configuration: string } }) => {
                  try {
                    const config = JSON.parse(que.question.configuration)
                    return config.show === "all"
                  } catch (error) {
                    return false
                  }
                })
              : []

            return filteredQueList.length
              ? {
                  categoryName: category.name,
                  questionAnswers:
                    Array.isArray(queList) &&
                    filteredQueList.map(
                      (que: { question: object; response: object; attachments: Array<object> | null }) => {
                        return {
                          question: que.question,
                          response: { ...que.response, attachments: que?.attachments || [] }
                        }
                      }
                    )
                }
              : null
          })
          .filter((detail: any) => detail !== null)
      }
    })
    setQuickSummary(menu)
  }

  const handleLaunchAssessment = () => {
    selectedTypeDispatcher && selectedTypeDispatcher(typeOfParentInventory)
    launchAssessDispatcher && launchAssessDispatcher({ openAssess: true, type: typeOfParentInventory })
    setModalStatus(true)
  }

  const handleTabChange = (event: CustomEvent<TabsChangeEventDetail>) => {
    setCurrentTab(event.detail.value)
  }

  const fetchInventoryList = () => {
    if (selectedSideOrg) {
      const apiUrl = serviceURL.pgpBaseAPI + "/api/inventory/inventories?type=" + selectedType.name + "&page=0&size=100"
      setIsLoading(true)
      axiosInstance
        .get(apiUrl, {
          headers: {
            accept: "*/*"
          },
          onDownloadProgress: (progressEvent: AxiosProgressEvent) => {
            if (progressEvent) {
              const percentage = Math.round((progressEvent.loaded * 100) / (progressEvent.total || 0))
              setLoadingPercentage(percentage)
            }
          }
        })
        .then(res => {
          const filteredCurrentInv = res?.data?.content?.filter((item: any) => item.name !== rowDetails.name)
          setInventoryList(filteredCurrentInv)
          setIsLoading(false)
          setIsToShowBanner(false)
        })
        .catch(error => {
          console.log(error)
          setIsLoading(false)
          setIsToShowBanner(true)
        })
    }
  }

  const fetchRelationList = () => {
    const apiUrl = serviceURL.pgpBaseAPI + "/api/inventory/relation/type"
    setIsLoading(true)
    axiosInstance
      .get(apiUrl, {
        headers: headers
      })
      .then(res => {
        setRelationList(res.data)
        setIsLoading(false)
        setIsToShowBanner(false)
      })
      .catch(error => {
        console.log(error)
        setIsLoading(false)
        setIsToShowBanner(true)
      })
  }

  const stageIdentifier = (status: string) => {
    switch (status) {
      case "created":
        return (
          <div className={styles.centreComp}>
            <div className={`${styles.rectangle} ${styles.selectedRectangle} ${styles.arrowRight}`}>
              <div className={styles.flowContent}>{STATUS.CREATED}</div>
            </div>
            <div className={`${styles.rectangle} ${styles.arrowRight}`}>
              <div className={styles.flowContent}>{STATUS.RESPONSE_PENDING}</div>
            </div>
            <div className={`${styles.rectangle} ${styles.arrowRight}`}>
              <div className={styles.flowContent}>{STATUS.REVIEW_PENDING}</div>
            </div>
            <div className={`${styles.rectangle}`}>
              <div className={styles.flowContent}>{STATUS.COMPLETED}</div>
            </div>
          </div>
        )
      case "waiting for response":
      case "response pending":
        return (
          <div className={styles.centreComp}>
            <div className={`${styles.rectangle} ${styles.selectedRectangle} ${styles.arrowRight}`}>
              <div className={styles.flowContent}>{STATUS.CREATED}</div>
            </div>
            <div className={`${styles.rectangle} ${styles.selectedRectangle} ${styles.arrowRight}`}>
              <div className={styles.flowContent}>{STATUS.RESPONSE_PENDING}</div>
            </div>
            <div className={` ${styles.rectangle} ${styles.arrowRight}`}>
              <div className={styles.flowContent}>{STATUS.REVIEW_PENDING}</div>
            </div>
            <div className={` ${styles.rectangle} ${styles.arrowRight}`}>
              <div className={styles.flowContent}>{STATUS.COMPLETED}</div>
            </div>
          </div>
        )
      case "review pending":
      case "in review":
      case "risk-calculation pending":
        return (
          <div className={styles.centreComp}>
            <div className={`${styles.rectangle} ${styles.selectedRectangle} ${styles.arrowRight}`}>
              <div className={styles.flowContent}>{STATUS.CREATED}</div>
            </div>
            <div className={`${styles.rectangle} ${styles.selectedRectangle} ${styles.arrowRight}`}>
              <div className={styles.flowContent}>{STATUS.RESPONSE_PENDING}</div>
            </div>
            <div className={`${styles.rectangle} ${styles.selectedRectangle} ${styles.arrowRight}`}>
              {status === "risk-calculation pending" && (
                <div className={styles.flowContent}>{STATUS.RISK_CALCULATION_PENDING}</div>
              )}
              {status === "review pending" && <div className={styles.flowContent}>{STATUS.REVIEW_PENDING}</div>}
            </div>
            <div className={`${styles.rectangle}`}>
              <div className={styles.flowContent}>{STATUS.COMPLETED}</div>
            </div>
          </div>
        )
      case "completed":
      case "approved":
      case "expired":
      case "rejected":
        return (
          <div className={styles.centreComp}>
            <div className={`${styles.rectangle} ${styles.selectedRectangle} ${styles.arrowRight}`}>
              <div className={styles.flowContent}>{STATUS.CREATED}</div>
            </div>
            <div className={`${styles.rectangle} ${styles.selectedRectangle} ${styles.arrowRight}`}>
              <div className={styles.flowContent}>{STATUS.RESPONSE_PENDING}</div>
            </div>
            <div className={`${styles.rectangle} ${styles.selectedRectangle} ${styles.arrowRight}`}>
              <div className={styles.flowContent}>{STATUS.REVIEW_PENDING}</div>
            </div>
            <div className={`${styles.rectangle} ${styles.selectedRectangle}`}>
              {status === "completed" && <div className={styles.flowContent}>{STATUS.COMPLETED}</div>}
              {status === "approved" && <div className={styles.flowContent}>{STATUS.APPROVED}</div>}
              {status === "rejected" && <div className={styles.flowContent}>{STATUS.REJECTED}</div>}
              {status === "expired" && <div className={styles.flowContent}>{STATUS.EXPIRED}</div>}
            </div>
          </div>
        )
      default:
        return (
          <div className={styles.centreComp}>
            <div className={`${styles.rectangle} ${styles.arrowRight}`}>
              <div className={styles.flowContent}>{STATUS.CREATED}</div>
            </div>
            <div className={`${styles.rectangle} ${styles.arrowRight}`}>
              <div className={styles.flowContent}>{STATUS.RESPONSE_PENDING}</div>
            </div>
            <div className={`${styles.rectangle} ${styles.arrowRight}`}>
              <div className={styles.flowContent}>{STATUS.REVIEW_PENDING}</div>
            </div>
            <div className={`${styles.rectangle}`}>
              <div className={styles.flowContent}>{STATUS.COMPLETED}</div>
            </div>
          </div>
        )
    }
  }

  const handleAssessment = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault()
    const orgId = rowDetails.organization?.id
    navigate(`/pia/assessment/${assessment?.id}/${orgId}`)
  }

  const fetchInvName = () => {
    const apiUrl =
      serviceURL.pgpBaseAPI + "/api/inventory/" + INVENTORY.TYPE_VE + "?name=" + encodeURIComponent(vendorName)
    setIsLoadingVendorName(true)
    axiosInstance
      .get(apiUrl, {
        headers: headers
      })
      .then(() => {
        setIsLoadingVendorName(false)
        setWarningInvNameExist(true)
      })
      .catch(error => {
        if (error.response.status === 404) {
          setIsLoadingVendorName(false)
          setWarningInvNameExist(false)
        }
        console.log(error)
      })
  }

  useEffect(() => {
    let delayTimer: any = null
    if (vendorName && isEditEnable) {
      clearTimeout(delayTimer)
      delayTimer = setTimeout(() => {
        fetchInvName()
      }, delayTime)
    } else {
      setWarningInvNameExist(false)
    }
    return () => {
      clearTimeout(delayTimer)
    }
  }, [vendorName, isEditEnable])

  const updateVendorName = () => {
    if (warningVendorNameExist) return
    const apiUrl = serviceURL.pgpBaseAPI + "/api/inventory/" + rowDetails?.id
    const payload = {
      name: vendorName?.trim()
    }
    axiosInstance
      .put(apiUrl, payload, {
        headers: headers
      })
      .then(() => {
        setShowVendorNameToast(true)
        fetchGridData(pageToStart, pageSize)
        onClose()
      })
      .catch(error => {
        setShowVendorNameToast(false)
        console.log(error)
      })
  }

  const handleVendorNameUpdate = () => {
    setiIsEditEnable(false)
    updateVendorName()
  }

  const handleDelete = () => {
    const apiUrl = serviceURL.pgpBaseAPI + "/api/inventory/" + rowDetails?.id + "/delete"
    const payload = {
      remarks: deleteMessage || ""
    }
    axiosInstance
      .put(apiUrl, payload, {
        headers: headers
      })
      .then(() => {
        setShowDeleteToast(true)
        setShowDeleteModal(false)
        fetchGridData(pageToStart, pageSize)
        setTimeout(() => {
          onClose()
        }, 2000)
      })
      .catch(error => {
        setShowDeleteToast(false)
        console.log(error)
      })
  }

  const isEligibleForRelaunch = () => {
    if (
      rowDetails?.createdBy?.toLowerCase()?.trim() === userContext?.email?.toLowerCase()?.trim() ||
      assessment?.createdBy?.toLowerCase()?.trim() === userContext?.email?.toLowerCase()?.trim()
    ) {
      if (appConfig.CUTOFF_DATE !== "NA") {
        const launchDate = new Date(assessment?.createdAt)
        const cutOffDate = new Date(appConfig.CUTOFF_DATE)
        const isBefore = launchDate < cutOffDate
        return assessment?.status === STATUS.RESPONSE_PENDING && isBefore
      } else {
        return isEligibleForLaunch([USER_ROLE.DPM, USER_ROLE.SU]) && assessment?.status === STATUS.RESPONSE_PENDING
      }
    }
  }

  const isEligibleForLaunch = (permission: string[]) => {
    const isSiteAdmin = userRoles?.groups.some((group: any) => group?.name === USER_ROLE.SAD)
    if (isSiteAdmin) {
      return true
    } else {
      const hasSameNetwork =
        rowDetails.organization?.name?.toLowerCase()?.trim() === userContext?.agency?.toLowerCase()?.trim()
      const hasRequiredRoles = userRoles?.groups?.some((item: any) => permission.includes(item.name))
      return hasSameNetwork && hasRequiredRoles
    }
  }

  const handleRelaunchAssessment = () => {
    setRelaunchModal(true)
  }

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setDeleteMessage(event.target.value)
}

const closeSideModal = () => {
  launchAssessDispatcher && launchAssessDispatcher({})
  setModalStatus(false)
  onClose()
}

const isEligibleForImportAssessment = () => {
  const isSiteAdmin = userRoles?.groups.some((group: any) => group?.name === USER_ROLE.SAD);
  const hasAssessment = rowDetails?.assessments?.length > 0;
  return isSiteAdmin && !hasAssessment
}

  return (
    <>
      <WppSideModal open={open} size={"2xl"} onWppSideModalClose={onClose} className={styles.modalBox}>
        {isLoading ? (
          <div slot="body">
            <DataGridOfflineComp />
          </div>
        ) : (
          <>
            <div slot="header" className={styles.header}>
              <h3 className={styles.vendName}>{clickedRowData && clickedRowData.name}</h3>
             <div className={styles.buttonAction}>
             {isEligibleForImportAssessment() &&  (<WppButton
                className={styles.customBtnSecondary}
                variant="secondary"
                onClick={() => setShowUploadModal(true)}
                >
                  Import Assessment
                </WppButton>
              )}
                <VendorPdf data={rowDetails} isLoading={isLoading} />
                <WppActionButton onClick={onClose} variant="secondary" slot="actions">
                  <WppIconClose className={styles.close}></WppIconClose>
                </WppActionButton>
              </div>
              {showVendorNameToast && (
                <WppToast
                  className={styles.toast}
                  message={"Vendor name updated successfully"}
                  type={"success"}
                  duration={2000}
                  onWppToastComplete={() => setShowVendorNameToast(false)}
                />
              )}
              {showDeleteToast && (
                <WppToast
                  className={styles.toast}
                  message={"Vendor deleted successfully"}
                  type={"success"}
                  duration={2000}
                  onWppToastComplete={() => setShowDeleteToast(false)}
                />
              )}
              {showUploadSuccessToast && (
                <WppToast
                  className={styles.toast}
                  message={"Assessment imported successfully"}
                  type={"success"}
                  duration={2000}
                  onWppToastComplete={() => setShowUploadSuccessToast(false)}
                />
              )}
            </div>
            <div slot="body" className={styles.sideModalBody}>
              <div className={styles.banner}>
                <WppBanner id="banner" type="information" show={isToShowBanner}>
                  Unable to fetch inventory. Please refresh or try after some time.
                </WppBanner>
              </div>
              {showToast && (
                <WppToast
                  className={styles.toast}
                  message={"Inventory linked successfully"}
                  type={"success"}
                  duration={2000}
                  onWppToastComplete={() => setShowToast(false)}
                />
              )}
              <Container maxWidth="xl">
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} rowSpacing={10}>
                  <Grid item xs={12} sm={12} md={12} className={styles.container}>
                    <div className={styles.secInventoryDetailsTop}>
                      <div className={styles.headerContainer}>
                        <div className={styles.leftContainer}>
                          <div className={styles.headerKey}>
                            <WppTypography tag="h4">Vendor Information</WppTypography>
                            <div className={styles.vWarningSec}>
                              {isLoadingVendorName && <WppSpinner size="s" color={"black"} />}
                              {warningVendorNameExist && isEditEnable && (
                                <p className={styles.warningNameExist}>
                                  Vendor name already exists. Please choose a different name.
                                </p>
                              )}
                            </div>
                          </div>
                          <div className={styles.headerBox}>
                            <span className={styles.key}>Name: </span>
                            <span className={styles.editBox}>
                              {!isEditEnable && (
                                <span className={styles.vendorName}>
                                  <WppTag
                                    label={rowDetails && rowDetails.name}
                                    className={styles.tag}
                                    maxLabelLength={40}
                                  />
                                </span>
                              )}
                              <span className={styles.iconBox}>
                                {isEditEnable && (
                                  <WppInput
                                    autoFocus
                                    size="s"
                                    className={styles.editInput}
                                    value={vendorName}
                                    onWppChange={event => setVendorName(event.target.value)}
                                  />
                                )}
                                {(rowDetails?.createdBy?.toLowerCase()?.trim() ===
                                  userContext?.email?.toLowerCase()?.trim() ||
                                  assessment?.createdBy?.toLowerCase()?.trim() ===
                                    userContext?.email?.toLowerCase()?.trim()) &&
                                  !isEditEnable && (
                                    <span>
                                      <WppIconEdit
                                        className={styles.btn}
                                        size="s"
                                        onClick={() => setiIsEditEnable(true)}
                                      />
                                    </span>
                                  )}
                                {isEditEnable && (
                                  <span>
                                    <WppIconDone className={styles.btn} size="s" onClick={handleVendorNameUpdate} />
                                  </span>
                                )}
                              </span>
                            </span>
                          </div>
                          <div className={styles.headerBox}>
                            <span className={styles.key}>Description: </span>
                            <span className={styles.value}>
                              {rowDetails && rowDetails.description ? (
                                <WppTag
                                  label={rowDetails.description}
                                  className={styles.tag}
                                  maxLabelLength={40}
                                  tooltipConfig={{ placement: "bottom" }}
                                />
                              ) : (
                                ""
                              )}
                            </span>
                          </div>
                          <div className={styles.headerBox}>
                            <span className={styles.key}>Service Type: </span>
                            <span className={styles.value}>
                              {rowDetails && rowDetails?.inventoryServiceType?.serviceType}
                            </span>
                          </div>
                          <div className={styles.headerBox}>
                            <span className={styles.key}>Market: </span>
                            <span className={styles.value}>{rowDetails && rowDetails?.market?.name}</span>
                          </div>
                          <div className={styles.headerBox}>
                            <span className={styles.key}>Agency: </span>
                            <span className={styles.value}>{rowDetails && rowDetails?.agency?.name}</span>
                          </div>
                          <div className={styles.headerBox}>
                            <span className={styles.key}>Created By: </span>
                            <span className={styles.value}>{rowDetails && rowDetails.createdBy}</span>
                          </div>
                          <div className={styles.headerBox}>
                            <span className={styles.key}>Created At: </span>
                            <span className={styles.value}>
                              {rowDetails?.createdAt && getLocalDate(rowDetails.createdAt)}
                            </span>
                          </div>
                          <div className={styles.headerBox}>
                            <span className={styles.key}>Modified At: </span>
                            <span className={styles.value}>
                              {rowDetails?.modifiedAt && getLocalDate(rowDetails.modifiedAt)}
                            </span>
                          </div>
                          <div className={styles.headerBox}>
                            <span className={styles.key}>Vendor Status: </span>
                            <TagStatusVariantIndicator className={styles.customTag} params={{ value: rowDetails?.status }} />
                          </div>
                        </div>
                        <div className={styles.rightContainer}>
                          <div className={styles.headerKey}>
                            <WppTypography tag="h4">Assessment Information</WppTypography>
                          </div>
                          <div className={styles.headerBox}>
                            <span className={styles.key}>Name: </span>
                            <span className={styles.value}>
                              {assessment && assessment?.name ? (
                                <WppTag
                                  label={assessment?.name}
                                  className={styles.tag}
                                  maxLabelLength={40}
                                  tooltipConfig={{ placement: "bottom" }}
                                />
                              ) : (
                                ""
                              )}
                            </span>
                          </div>
                          <div className={styles.headerBox}>
                            <span className={styles.key}>Assessment Status: </span>
                            <TagStatusVariantIndicator
                              params={{ value: assessment?.status || "No Assessment has launched yet" }}
                              className={styles.customTag}
                            />
                          </div>
                          <div className={styles.headerBox}>
                            <span className={styles.key}>Description: </span>
                            <span className={styles.value}>
                              {assessment && assessment.description ? (
                                <WppTag
                                  label={assessment.description}
                                  className={styles.tag}
                                  maxLabelLength={40}
                                  tooltipConfig={{ placement: "bottom" }}
                                />
                              ) : (
                                ""
                              )}
                            </span>
                          </div>
                          <div className={styles.headerBox}>
                            <span className={styles.key}>Respondents: </span>
                            {respondentEmail.length ? (
                              <span className={styles.respondedContainer}>
                                <WppTag
                                  label={respondentEmail
                                    ?.map((m: { email: string }, index: number) => m?.email)
                                    .join(", ")}
                                  className={styles.tag}
                                  maxLabelLength={40}
                                  tooltipConfig={{ placement: "bottom" }}
                                />
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className={styles.headerBox}>
                            <span className={styles.key}>Created By: </span>
                            <span className={styles.value}>{assessment?.createdBy && assessment.createdBy}</span>
                          </div>
                          <div className={styles.headerBox}>
                            <span className={styles.key}>Created At: </span>
                            <span className={styles.value}>
                              {assessment?.createdAt && getLocalDate(assessment.createdAt)}
                            </span>
                          </div>
                          <div className={styles.headerBox}>
                            <span className={styles.key}>Last Activity Date: </span>
                            <span className={styles.value}>
                              {assessment?.modifiedAt && getLocalDate(assessment.modifiedAt)}
                            </span>
                          </div>
                        </div>
                      </div>
                      <Grid item xs={12} display="flex">
                        <div className={styles.flowComp}>{stageIdentifier(assessment?.status?.toLowerCase())}</div>
                      </Grid>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} paddingTop={"2rem !important"}>
                    <div className={styles.tabSection}>
                      <WppTabs className={styles.customTabs} value={currentTab} onWppChange={handleTabChange}>
                      {quickSummary && quickSummary.length && quickSummary[0]?.details?.length ? (
                          <WppTab className={styles.customTab} value="QuickSummary">
                            Quick Summary
                          </WppTab>
                        ) : null}
                        <WppTab className={styles.customTab} value="AdditionalDetails">
                          Summary
                        </WppTab>
                        <WppTab className={styles.customTab} value="Assessments">
                          Assessments
                        </WppTab>
                      </WppTabs>
                    </div>
                    {
                      {
                        AdditionalDetails: (
                          <div className={styles.tabContent}>
                            {isLoading ? (
                              <WppProgressIndicator className={styles.customLoader} variant="bar" />
                            ) : hasRequiredPermission([USER_ROLE.SAD, USER_ROLE.DPM]) ||
                              rowDetails?.createdBy?.toLowerCase()?.trim() ===
                                userContext?.email?.toLowerCase()?.trim() ||
                              assessment?.createdBy?.toLowerCase()?.trim() ===
                                userContext?.email?.toLowerCase()?.trim() ? (
                              <QueAns rowDetails={accordAssessArr} label="Summary"/>
                            ) : (
                              <span className={styles.value}>You do not have permission to view</span>
                            )}
                          </div>
                        ),
                        Assessments: (
                          <div className={styles.tabContent}>
                            {!assessment?.status && (
                              <div className={styles.assessmentNotFound}>No Assessment Found</div>
                            )}
                            {assessment?.status && isUserAuthorized()}
                          </div>
                        ),
                        QuickSummary: (
                          <div className={styles.tabContent}>
                            {isLoading ? (
                              <WppProgressIndicator className={styles.customLoader} variant="bar" />
                            ) :
                           (
                            <QueAns rowDetails={quickSummary} label="quickSummary" />
                          )}
                          </div>
                        )
                      }[currentTab]
                    }
                  </Grid>
                </Grid>
              </Container>
            </div>
            <div slot="actions" className={styles.actionBtn}>
              <WppButton
                disabled={
                  isLoading || accordAssessArr.length > 0 || !isEligibleForLaunch([USER_ROLE.DPM, USER_ROLE.SU])
                }
                className={styles.customBtnSecondary}
                variant="secondary"
                onClick={handleLaunchAssessment}
              >
                Launch Assessment
              </WppButton>
              {(rowDetails?.createdBy?.toLowerCase()?.trim() === userContext?.email?.toLowerCase()?.trim() ||
                hasRequiredPermission([USER_ROLE.SAD])) && (
                <WppButton
                  className={styles.customBtnSecondary}
                  variant="secondary"
                  onClick={() => setShowDeleteModal(true)}
                >
                  Delete Vendor Record
                </WppButton>
              )}
              {isEligibleForRelaunch() && (
                <WppButton
                  className={styles.customBtnSecondary}
                  variant="secondary"
                  size="s"
                  onClick={handleRelaunchAssessment}
                >
                  Relaunch Assessment
                </WppButton>
              )}
            </div>
          </>
        )}
      </WppSideModal>
      <WppModal open={showDeleteModal} size="s" onWppModalClose={onClose}>
        <div slot="header" className={styles.deleteHeader}>
          <h3 className={styles.assessNameProgressContainer}>
            <span>Delete Vendor Record</span>
          </h3>
          <WppIconClose
            color="black"
            onClick={() => setShowDeleteModal(false)}
            className={styles.close}
            size="m"
          ></WppIconClose>
        </div>
        <div slot="body" className={styles.relaunchModalBody}>
          <span>
            All associated records with it will also be delete from system. If the vendor has already provided
            assessment responses, these will be lost.
          </span>
          <TextArea
            name="deleteMessage"
            placeholder="Reason for deletion..."
            className={styles.reviewContainerCommentItem}
            value={deleteMessage}
            onChange={e => handleChange(e)}
          />
          <span className={styles.deleteNotfication}>
            Note: The respondents will be notified of the deletion. The reason will be shared.
          </span>
        </div>
        <div slot="actions" className={styles.btnClose}>
          <Grid item xs={12} sm={12} md={12} display={"flex"} gap={1} justifyContent={"right"}>
            <WppButton
              className={styles.customBtnSecondary}
              variant={"secondary"}
              loading={showSpinner}
              onClick={handleDelete}
              disabled={!deleteMessage.trim().length}
            >
              DELETE
            </WppButton>
            <WppButton
              className={styles.customBtnSecondary}
              variant={"secondary"}
              loading={showSpinner}
              onClick={() => setShowDeleteModal(false)}
            >
              Cancel
            </WppButton>
          </Grid>
        </div>
      </WppModal>
      {showUploadModal && <FileUpload open={showUploadModal} onClose={() => setShowUploadModal(false)} uploadTost={setShowUploadSuccessToast} />}
      {relaunchModal && (
        <RelaunchAssessment relaunchModal={relaunchModal} onClose={onClose} assessRowDetails={assessment} />
      )}
       {isModalOpen && (
          <CreateAssessment open={isModalOpen} closeSideModal={closeSideModal} />
        )}
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(RowDetails)
