import axios from 'axios';
import {CLEAR_SELECTED_ROW_DATA, GET_ASSESS_BY_ID, GET_INV_BY_ASSESS_ID, UPDATE_ROW_DATA} from './types';
import {serviceURL} from '../../../../helper/serviceURL';
import {ASSESSMENT} from "../../../../helper/constants";
import assessjson from './mockAssessdata.json';
import _ from "lodash";

export const getAssessById = (assessDetails: any) => ({
    type: GET_ASSESS_BY_ID,
    payload: assessDetails
});

export const updateRowData = (assessDetails: any) => ({
    type: UPDATE_ROW_DATA,
    payload: assessDetails
});

export const getInventoryByAssessId = (inventoryDetails: any) => ({
    type: GET_INV_BY_ASSESS_ID,
    payload: inventoryDetails
});
export const clearClickedAssessId = (invAssetDetails: any) => ({
    type: CLEAR_SELECTED_ROW_DATA,
    payload: invAssetDetails
});
const makeLastAssessElement = (response: any) => {
    const idx = response.categories.findIndex((obj: any) => obj['name'] === ASSESSMENT.DATA_PRIVACY_MANAGER);
    if (idx !== -1) {
        const removeObj = response.categories.splice(idx, 1)[0];
        response.categories.push(removeObj);
    }
    return response;
}

    const makeLastInvElement = (response: any) => {
        const idx = response.inventoryAttributes.categories.findIndex((obj: any) => obj['name'] === ASSESSMENT.DATA_PRIVACY_MANAGER);
        if (idx !== -1) {
            const removeObj = response.inventoryAttributes.categories.splice(idx, 1)[0];
            response.inventoryAttributes.categories.push(removeObj);
        }
        return response;
    }

    export const getAssessmentByIdDispatcher = (id: string, header: object, orgId: number) => (dispatch: any) => {
        const apiUrl = serviceURL.pgpBaseAPI + '/api/assessments/' + id + '?orgId=' + orgId;
          return new Promise((resolve, reject) => {
            axios.get(apiUrl, {
                headers: header
            }).then((res: any) => {
                let modObj: any;
                if (res.data) {
                    modObj = makeLastAssessElement(res.data);
                    // modObj = makeLastAssessElement(assessjson);
                } else {
                    modObj = res.data;
                }

                dispatch(getAssessById(modObj));
                resolve(modObj);
            }).catch((error) => reject(error));
            // }).catch((error) =>{
            //     let modObj: any;
            //     alert('API Failed!, loading ad hoc mock data.');
            //     modObj = makeLastAssessElement(assessjson);
            //     dispatch(getAssessById(assessjson));
            //     resolve(modObj);
            // });
        });
    };
    export const getInventoryByAssessIdDispatcher = (type: string, id: string, header: object, orgId: number) => (dispatch: any) => {
        const apiUrl = serviceURL.pgpBaseAPI + '/api/inventory/' + type + '/' + id + '?orgId=' + orgId;
        return new Promise((resolve, reject) => {
            axios.get(apiUrl, {
                headers: header
            }).then((res: any) => {
                let modObj: any;
                if (res.data) {
                    modObj = makeLastInvElement(res.data)
                } else {
                    modObj = res.data;
                }
                dispatch(getInventoryByAssessId(modObj));
                resolve(modObj);
            }).catch((error) => reject(error));
            // }).catch((error) =>{
            //     let modObj: any;
            //     alert('API Failed!, loading ad hoc mock data.');
            //     modObj = makeLastInvElement(mockInvByAssessIDJson);
            //     dispatch(getInventoryByAssessId(mockInvByAssessIDJson));
            //     resolve(modObj);
            // });
        });
    };
    export const clearAssessmentByIdDispatcher = (invAssetRes: object) => (dispatch: any) => {
        return new Promise((res, rej) => {
            dispatch(clearClickedAssessId(invAssetRes));
            res(invAssetRes);
        });
    };
