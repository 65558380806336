import React, { forwardRef, memo, useEffect, useLayoutEffect, useState } from "react"
import { parseQuestionBankIds } from "../../helper/Helper"
import _ from "lodash"
import styles from "./QueAnsAssessment.module.scss"
import { WppPill, WppPillGroup, WppTextareaInput } from "@wppopen/components-library-react"
import { QUE_TYPE } from "../../helper/constants"

interface Option {
  name?: string;
  dqList?: []
}

const MultiSelect = memo(forwardRef((props: any, ref) => {
  const parseKey = parseQuestionBankIds(props.multiSelectKey)
  const editQnKey = parseQuestionBankIds(props.dpmQnIdx)
  // const [apiChkBoxList, setApiChkBoxList] = useState([])
  const [selectedMultiSelect, setSelectedMultiSelect] = useState<Option[]>([])
  const [multiSelectText, setMultiSelectText] = useState("")
  const apiSavedQnBank = (savedResArray: any) => {
    if (savedResArray.length) {
      const qnBank = savedResArray.find((catItem: any) => catItem.categoryId === parseKey.categoryId)?.questionAnswers?.length ?
        savedResArray.find((item: any) => item.categoryId === parseKey.categoryId)?.questionAnswers
        : []
      const selectedQn = qnBank.length && qnBank.find((qnItem: any) => qnItem.question.id === parseKey.questionId)
      return { qnBank, selectedQn }
    }
    return {}
  }

  const initialiseMultiSelect = (question: any, response: any, savedResponse: any) => {
    if (question) {
      const setData = (isBuildByResponse: boolean, data: any, optionList: any) => {
        if (isBuildByResponse) {
          if (question?.questionType === QUE_TYPE.MULTI_SELECT || question?.questionType === QUE_TYPE.MULTI_SELECT_API || question?.questionType === QUE_TYPE.DROPDOWN) {
            let newObjArray = data?.split('|').map((item: string) => {
              return { name: item.trim(), dqList: [] }
            }) || []
            setSelectedMultiSelect(newObjArray)
            // if (optionList.length) {
            //   setApiChkBoxList(optionList)
            // }
          } else if (question?.questionType === QUE_TYPE.MULTI_SELECT_TEXT || question?.questionType === QUE_TYPE.MULTI_SELECT_TEXT_OPTIONAL || question?.questionType === QUE_TYPE.MULTI_SELECT_TEXT_CONDITIONAL) {
            let newObjArray = data?.split('|').map((item: string) => {
              return { name: item.trim(), dqList: [] }
            }) || []
            setSelectedMultiSelect(newObjArray)
            // if (optionList.length) {
            //   setApiChkBoxList((prevState: any) => [...optionList])
            // }
            const text = data.split("|")
            const textRes = text[text?.length - 1]
            setMultiSelectText(textRes)
          }

        } else if (!isBuildByResponse) {
          // setApiChkBoxList(optionList.length && optionList)
          setSelectedMultiSelect([])
        }
      }
      if (response || question?.optionList?.length) {
        if (response) {
          setData(true, response, question.optionList)
        } else {
          setData(false, null, question.optionList)
        }
      }
      if (savedResponse?.length && question.id === editQnKey.questionId) {
        const receivedSavedQn = apiSavedQnBank(savedResponse).selectedQn
        if (receivedSavedQn) {
          receivedSavedQn.response && setData(true, receivedSavedQn.response.responseValue, question.optionList)
        }
      }
    }
  }

  useLayoutEffect(() => {
    initialiseMultiSelect(props.quen, props.response, props.savedResponse)
  }, [])
  const dynamicMultiSelect = () => {
    let reqToApi: any
    const reqToApiArray: any = selectedMultiSelect?.length && selectedMultiSelect.map((item: any) => item?.name)
    reqToApi = reqToApiArray?.length ? reqToApiArray.join("|") : ""
    let copyQnBank = props.savedResponse.length ? apiSavedQnBank(props.savedResponse) && _.cloneDeep(apiSavedQnBank(props.savedResponse).qnBank) : _.cloneDeep(props.pmQuestionBank)
    for (let i = 0; i < copyQnBank.length; i++) {
      if (copyQnBank[i]["question"]["id"] === props.quen.id) {
        copyQnBank[i] = { ...copyQnBank[i], response: { responseValue: reqToApi } }
        break
      }
    }
    props.updateParentMultiSelect && props.updateParentMultiSelect(copyQnBank)
  }
  const handleMultiSelect = (option: Option) => {
    if (isChecked(option)) {
      setSelectedMultiSelect(prevSelectedOptions =>
        prevSelectedOptions.filter((item: Option) => item.name !== option.name)
      );
    } else {
      setSelectedMultiSelect(prevSelectedOptions => [...prevSelectedOptions, option]);
    }
  };

  const isChecked = (option: Option) => {
    return selectedMultiSelect.some((item: Option) => item.name === option.name);
  }

  useEffect(() => {
    if (props.quen.id === editQnKey.questionId) {
      dynamicMultiSelect()
    }
  }, [props.response, props.savedResponse, selectedMultiSelect])
  useEffect(() => {
    if (props.quen.id === editQnKey.questionId) {
      if (props.tempQnBank.length === 0 && !props.inlineEdit) {
        initialiseMultiSelect(props.quen, props.response, props.savedResponse)
      }
    }
  }, [props.tempQnBank])


  return <div key={`multi-select-qnId-${parseKey.questionId}`}>
    {
      props.readAccess ?
        <>
          {props.response === null ?
            <p>No Response</p> :
            <>
              <WppPillGroup
                className={styles.wppPillGroupDisabled}
                type="display"
                value={selectedMultiSelect?.name}
                required={false}
                onWppChange={(e) => null}>
                {
                  selectedMultiSelect.map((option: any, idx: number) => (
                    <div className={styles.options} key={idx} title={option.name}>
                      <WppPill className={styles.wppPill} title={option.name} key={`pills-${idx}`}
                               label={option.name} disabled={!props.inlineEdit} value={option}
                               checked={isChecked(option)} />
                    </div>
                  ))
                }
              </WppPillGroup>
            </>
          }
        </> :
        <>
          {
            <div className={(props.dpmQnRetrievingSpinner || props.dpmQnSaveSpinner) ? styles.loading : ''}>
              <WppPillGroup
                 className={`${props.inlineEdit && props.quen.id === editQnKey.questionId ? styles.wppPillGroup : [styles.wppPillGroup, styles.wppPillGroupDisabled].join(' ')}`}
                 type="multiple"
                 required={false}
                 value={selectedMultiSelect.map(option => option.name).join("|")}
                 onWppChange={(e) => null}
              >
                {
                  props?.quen?.optionList?.map((option: any, idx: number) => (
                     <div className={styles.options} key={idx}>
                       <WppPill
                          className={styles.wppPill}
                          title={option.name}
                          onClick={() => handleMultiSelect(option)}
                          key={`pills-${idx}`}
                          label={option.name}
                          disabled={!props.inlineEdit || props.quen.id !== editQnKey.questionId}
                          value={option}
                          checked={isChecked(option)}/>
                     </div>
                  ))
                }
              </WppPillGroup>
            </div>
          }
          {
            multiSelectText?.length > 0 ?
               <div className={(props.dpmQnRetrievingSpinner || props.dpmQnSaveSpinner)? styles.loading : ''}>
                 <WppTextareaInput
                    key={`multi-text-editAccess-${parseKey.questionId}`}
                    disabled={!props.inlineEdit || props.quen.id !== editQnKey.questionId || props.quen.id !== null}
                    className={props.readAccess ? styles.disableTextArea : styles.enableTextArea}
                    name="text-response"
                    placeholder="Reponse here..."
                    value={multiSelectText}
                    onWppChange={(e) => {
                    }}
                 />
               </div>
               : null
          }
        </>
    }
  </div>
}))

export default MultiSelect