import axios from 'axios';
import {GET_INVENTORY_BY_ID} from './types';
import {serviceURL} from '../../../../helper/serviceURL';
import {LAUNCH_ASSESS, CLEAR_SELECTED_ROW_DATA} from './types';
import {INVENTORY} from '../../../../helper/constants';
export const getInventoryById = (invDetails: any) => ({
    type: GET_INVENTORY_BY_ID,
    payload: invDetails
});

export const clearClickedInvId = (invAssetDetails: any) => ({
    type: CLEAR_SELECTED_ROW_DATA,
    payload: invAssetDetails
});

const makeLastElement = (response: any) => {
    const idx = response.inventoryAttributes.categories.findIndex((obj: any) => obj['name'] === INVENTORY.DATA_PRIVACY_MANAGER);
    if (idx !== -1) {
        const removeObj = response.inventoryAttributes.categories.splice(idx, 1)[0];
        response.inventoryAttributes.categories.push(removeObj);
    }
    return response;
}

export const getInventoryByIdDispatcher = (type: string, id: string, header: object, orgId: number) => (dispatch: any) => {
    const apiUrl = serviceURL.pgpBaseAPI + '/api/inventory/' + type + '/' + id + '?orgId=' + orgId;
    return new Promise((resolve, reject) => {
        axios.get(apiUrl, {
            headers: header
        }).then((res: any) => {
            let modObj: any;
            if (res.data) {
                modObj = makeLastElement(res.data)
            } else {
                modObj = res.data;
            }
            dispatch(getInventoryById(modObj));
            resolve(modObj);
        }).catch((error) => reject(error));
    });
};

export const launchAssess = (obj: object) => ({
    type: LAUNCH_ASSESS,
    payload: obj,
});

export const launchAssessDispatcher = (obj: object) => (dispatch: any) => {
    dispatch(launchAssess(obj));
};

export const clearInventoryByIdDispatcher = (invRes: object) => (dispatch: any) => {
    return new Promise((res, rej) => {
        dispatch(clearClickedInvId(invRes));
        res(invRes);
    });
};