import { MarkerType } from 'reactflow';
export default [
    {
        id: 'edge-1',
        source: 'mookie-id',
        target: 'm-insights',
        label: 'Registry API',
        labelStyle: { fill: '#4F4FBD', fontWeight: 600 },
        markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 20,
            height: 20,
            color: '#8512E8',
        },
        animated: true,
        style: {
            stroke: '#8512E8',
        },
        labelBgPadding: [5, 5] as [number, number],
    },
    {
        id: 'edge-2',
        source: 'mookie-id',
        target: 'activate-prodbase',
        label: 'Registry API via PubSub',
        // labelBgPadding: [5, 5] as [number, number],
        // labelBgBorderRadius: 4,
        labelStyle: { fill: '#4F4FBD', fontWeight: 600 },
        markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 20,
            height: 20,
            color: '#934074',
        },
        animated: true,
        style: {
            stroke: '#934074',
        },
    },
    {
        id: 'edge-3',
        source: 'hashed-emails',
        target: 'audience-origin',
        label: 'DPS API via PubSub',
        labelStyle: { fill: '#4F4FBD', fontWeight: 600 },
        markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 20,
            height: 20,
            color: '#D21312',
        },
        animated: true,
        style: {
            stroke: '#EAB309',
        },
        // type: 'start-end',
    },
    {
        id: 'edge-4',
        source: 'pii',
        target: 'activate-prodbase',
        label: 'DPS API via Email',
        labelStyle: { fill: '#4F4FBD', fontWeight: 600 },
        markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 20,
            height: 20,
            color: '#E08E6D',
        },
        animated: true,
        style: {
            stroke: '#20BC59',
        },
    },
    {
        id: 'edge-5',
        source: 'pii',
        target: 'intel-amerilink',
        label: 'DPS API via Email',
        labelStyle: { fill: '#4F4FBD', fontWeight: 600 },
        markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 20,
            height: 20,
            color: '#C880B2',
        },
        animated: true,
        style: {
            stroke: '#C880B2',
        },
    },
    {
        id: 'edge-6',
        source: 'pii',
        target: 'client-sybase',
        label: 'DPS API via Email',
        labelStyle: { fill: '#4F4FBD', fontWeight: 600 },
        markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 20,
            height: 20,
            color: '#15803D',
        },
        animated: true,
        style: {
            stroke: '#15803D',
        },
    },
    {
        id: 'edge-7',
        source: 'pii',
        target: 'audience-origin',
        label: 'DPS API via PubSub',
        labelStyle: { fill: '#4F4FBD', fontWeight: 600 },
        markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 20,
            height: 20,
            color: '#D21312',
        },
        animated: true,
        style: {
            stroke: '#F87171',
        },
    },
    {
        id: 'edge-8',
        source: 'email',
        target: 'audience-origin',
        label: 'DPS API via Email',
        labelStyle: { fill: '#4F4FBD', fontWeight: 600 },
        markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 20,
            height: 20,
            color: '#D21312',
        },
        animated: true,
        style: {
            stroke: '#8F979D',
        },
    },
];
