import React, { useState, useEffect } from "react"
import { Grid, Container } from "@mui/material"
import { connect } from "react-redux"
import { AxiosProgressEvent } from "axios"
import { useOs } from "@wppopen/react"
import { useNavigate } from "react-router-dom"
import {
  WppButton,
  WppSideModal,
  WppAccordion,
  WppTypography,
  WppSelect,
  WppSpinner,
  WppToast,
  WppProgressIndicator,
  WppBanner,
  WppTabs,
  WppTab,
  WppListItem,
  WppActionButton,
  WppLabel,
  WppIconClose
} from "@wppopen/components-library-react"
import { TabsChangeEventDetail } from "@wppopen/components-library"
import { ValidationError, AccordItem, AssessAccordItem } from "../interface"
import { getInventoryByIdDispatcher, launchAssessDispatcher, clearInventoryByIdDispatcher } from "./action"
import { selectedTypeDispatcher } from "../../../piaModule/assessmentList/createAssessment/step1/action"
import serviceURL from "../../../../helper/serviceURL"
import { validate } from "../../../../helper/validate"
import { SELECT_TYPES, INVENTORY } from "../../../../helper/constants"
import QueAns from "../../../../components/queAns/QueAns"
import styles from "./RowDetails.module.scss"
import { convertToTitleCase, findQuestions, prepareLinkAccordData } from "../../../../helper/Helper"
import { RowDetailsModalProps } from "../../../../helper/interface"
import LinkedInvCard from "../../../../components/linkedInvCard/LinkedInvCard"
import TagStatusVariantIndicator from "../../../../helper/TagStatusVariantIndicator"
import useAxiosInterceptors from "../../../../hooks/useAxiosInterceptors"
import DataGridOfflineComp from "components/dataGrid/DataGridOfflineComp"

const mapDispatchToProps = (dispatch: any) => ({
  getInventoryByIdDispatcher: (type: string, id: string, head: object, orgId: number) =>
    dispatch(getInventoryByIdDispatcher(type, id, head, orgId)),
  selectedTypeDispatcher: (type: object) => dispatch(selectedTypeDispatcher(type)),
  launchAssessDispatcher: (obj: object) => dispatch(launchAssessDispatcher(obj)),
  clearInventoryByIdDispatcher: (invRes: object) => dispatch(clearInventoryByIdDispatcher(invRes))
})

const mapStateToProps = (state: any) => {
  return {
    base64Email: state.storeBase64EmailReducer.data,
    rowDetails: state.inventoryRowDetailsRed.data,
    selectedSideOrg: state.selectedSideNavOrgRed.data
  }
}

const initialAccordArrState: AccordItem[] = []
const iniAssessArrState: AssessAccordItem[] = []

const RowDetails = ({
  open,
  onClose,
  clickedRowData,
  getInventoryByIdDispatcher,
  typeOfParentInventory,
  base64Email,
  rowDetails,
  launchAssessDispatcher,
  selectedTypeDispatcher,
  selectedSideOrg,
  clearInventoryByIdDispatcher
}: RowDetailsModalProps) => {
  const navigate = useNavigate()
  const initialState = { id: 0, name: "" }
  const [loadingPercentage, setLoadingPercentage] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [isToShowBanner, setIsToShowBanner] = useState(false)
  const [showBannerLinking, setShowBannerLinking] = useState(false)
  const [openAccordionId, setOpenAccordionId] = useState<string | null>(null)
  const [currentTab, setCurrentTab] = useState("AdditionalDetails")
  const [selectedType, setSelectedType] = useState(initialState)
  const [selectedRelation, setSelectedRelation] = useState(initialState)
  const [selectedInventory, setSelectedInventory] = useState(initialState)
  const [relationList, setRelationList] = useState([])
  const [inventoryList, setInventoryList] = useState([])
  const [showSpinner, setShowSpinner] = useState(false)
  const [showLinkParentAccSpin, setShowLinkParentAccSpin] = useState(false)
  const [showLinkChildAccSpin, setShowLinkChildAccSpin] = useState(false)
  const [validationErrors, setValidationErrors] = useState<ValidationError[]>()
  const [showAddRelInventory, setShowAddRelInventory] = useState(false)
  const [showToast, setShowToast] = useState(false)
  const [accordLinkArr, setLinkAccordArr] = useState<AccordItem[]>(initialAccordArrState)
  const [accordAssessArr, setAccordAssessArr] = useState<AssessAccordItem[]>(iniAssessArrState)
  const [bannerMsg, setBannerMsg] = useState("Unable to fetch inventory. Please refresh or try after some time.")
  const {
    osContext,
    osApi: { getAccessToken }
  } = useOs()
  const userEmail: string = osContext.userDetails.email
  const { axiosInstance } = useAxiosInterceptors()
  const headers = {
    accept: "*/*",
    Authorization: "Bearer " + getAccessToken()
  }

  useEffect(() => {
    fetchInventoryById()
    // return () => {
    //     clearInventoryByIdDispatcher && clearInventoryByIdDispatcher({}).then(() => {
    //         console.log('clearing row details modal');
    //     }).catch(err => {
    //         console.log(err);
    //     })
    // }
  }, [])

  useEffect(() => {
    if (selectedType.name !== "") {
      fetchInventoryList()
      fetchRelationList()
    }
  }, [selectedType])

  const fetchInventoryById = () => {
    setIsLoading(true)
    setShowLinkParentAccSpin(true)
    if (clickedRowData && selectedSideOrg) {
      getInventoryByIdDispatcher &&
        getInventoryByIdDispatcher(
          typeOfParentInventory.name,
          clickedRowData.id,
          headers,
          clickedRowData.organizationId
        )
          .then((res: any) => {
            if (res) {
              const accordArr = prepareLinkAccordData(res)
              accordArr && setLinkAccordArr(accordArr)
              prepareAssessmentData(res.assessments)
              setShowLinkParentAccSpin(false)
              setIsLoading(false)
            }
          })
          .catch(err => {
            console.log(err)
            setIsToShowBanner(true)
            setShowLinkParentAccSpin(false)
            setIsLoading(false)
          })
    }
  }

  const prepareAssessmentData = (data: any) => {
    let menu: any[]
    menu = data.map((assessment: any) => {
      return {
        assessmentName: assessment.name,
        details: assessment.categories.map((category: any) => {
          let queList = findQuestions(assessment, category.id)
          return {
            categoryName: category.name,
            questionAnswers:
              Array.isArray(queList) &&
              queList.map((que: { question: object; response: object }) => {
                return {
                  question: que.question,
                  response: que.response
                }
              })
          }
        })
      }
    })
    setAccordAssessArr(menu)
    // console.log('Menu', menu);
  }

  const handleLaunchAssessment = () => {
    selectedTypeDispatcher && selectedTypeDispatcher(typeOfParentInventory)
    launchAssessDispatcher && launchAssessDispatcher({ openAssess: true, type: typeOfParentInventory })
    navigate("/pia/assessment")
  }

  const handleTabChange = (event: CustomEvent<TabsChangeEventDetail>) => {
    setCurrentTab(event.detail.value)
  }

  const handleAccord = (inventoryID: string, type: string, orgId: number) => {
    setOpenAccordionId(inventoryID)
    if (openAccordionId !== inventoryID && selectedSideOrg) {
      setShowLinkChildAccSpin(true)
      const apiUrl = serviceURL.pgpBaseAPI + "/api/inventory/" + type + "/" + inventoryID + "?orgId=" + orgId
      axiosInstance
        .get(apiUrl, {
          headers: headers
        })
        .then(() => {
          setShowLinkChildAccSpin(false)
          setIsToShowBanner(false)
        })
        .catch(error => {
          console.log(error)
          setShowLinkChildAccSpin(false)
          setIsToShowBanner(true)
        })
    }
  }

  const fetchInventoryList = () => {
    if (selectedSideOrg) {
      const apiUrl =
        serviceURL.pgpBaseAPI +
        "/api/inventory/inventories?type=" +
        selectedType.name +
        "&page=0&size=100" +
        "&orgId=" +
        selectedSideOrg.id
      setIsLoading(true)
      axiosInstance
        .get(apiUrl, {
          headers: {
            accept: "*/*"
          },
          onDownloadProgress: (progressEvent: AxiosProgressEvent) => {
            if (progressEvent) {
              const percentage = Math.round((progressEvent.loaded * 100) / (progressEvent.total || 0))
              setLoadingPercentage(percentage)
            }
          }
        })
        .then(res => {
          const filteredCurrentInv = res?.data?.content?.filter((item: any) => item.name !== rowDetails.name)
          setInventoryList(filteredCurrentInv)
          setIsLoading(false)
          setIsToShowBanner(false)
        })
        .catch(error => {
          console.log(error)
          setIsLoading(false)
          setIsToShowBanner(true)
        })
    }
  }

  const handleType = (e: any) => {
    setSelectedType(e.target.value)
    setSelectedInventory(initialState)
    setShowBannerLinking(false)
  }

  const fetchRelationList = () => {
    const apiUrl = serviceURL.pgpBaseAPI + "/api/inventory/relation/type"
    setIsLoading(true)
    axiosInstance
      .get(apiUrl, {
        headers: headers
      })
      .then(res => {
        setRelationList(res.data)
        setIsLoading(false)
        setIsToShowBanner(false)
      })
      .catch(error => {
        console.log(error)
        setIsLoading(false)
        setIsToShowBanner(true)
      })
  }

  const linkTwoInventories = () => {
    let validationList: any[]
    let validateFields: object
    validateFields = {
      selectedType: selectedType.name,
      linkedSelectedRelation: selectedRelation.id,
      linkedSelectedInventory: selectedInventory.id
    }
    validationList = validate(validateFields)
    setValidationErrors(validationList)
    const found = validationList.map((item: any) => !!item.error).includes(true)
    if (!found && clickedRowData && selectedSideOrg) {
      const apiUrl =
        serviceURL.pgpBaseAPI +
        "/api/inventory/" +
        typeOfParentInventory.name +
        "/" +
        clickedRowData.id +
        "/" +
        selectedType.name +
        "/" +
        selectedInventory.id +
        "?relationTypeId=" +
        selectedRelation.id
      setShowSpinner(true)
      const postData = {
        createdBy: userEmail,
        modifiedBy: userEmail
      }
      axiosInstance
        .post(apiUrl, postData, {
          headers: {
            accept: "*/*"
          }
        })
        .then(() => {
          setShowSpinner(false)
          setShowToast(true)
          setShowBannerLinking(false)
          fetchInventoryById()
          setSelectedType(initialState)
          setSelectedRelation(initialState)
          setSelectedInventory(initialState)
        })
        .catch(error => {
          console.log(error)
          setShowSpinner(false)
          setShowToast(false)
          setBannerMsg(error.response.data.message)
          setShowBannerLinking(true)
        })
    }
  }

  const renderAdditionalDetails = () => {
    return (
      <div className={`${isLoading ? styles.loading : ""}`}>
        {isLoading && (
          <WppProgressIndicator
            className={styles.customLoader}
            variant="bar"
            value={loadingPercentage}
            isShowPercentage={true}
          />
        )}
        {Array.isArray(rowDetails?.inventoryAttributes?.categories) &&
        rowDetails?.inventoryAttributes?.categories?.length ? (
          <>
            {rowDetails?.inventoryAttributes?.categories?.map((cat: any) => {
              return (
                <WppAccordion
                  key={`accordion-${cat.id}`}
                  withDivider={false}
                  className={
                    cat.name !== INVENTORY.DATA_PRIVACY_MANAGER ? styles.accordStyle : styles.accordStylePrivacy
                  }
                  size="m"
                >
                  <WppTypography
                    key={`accord-header-${cat.id}`}
                    type="m-strong"
                    slot="header"
                    className={styles.accordHeader}
                  >
                    {cat.name !== INVENTORY.DATA_PRIVACY_MANAGER ? cat.name : cat.name + " (only for privacy manager)"}
                  </WppTypography>
                  <div
                    key={`typography-${cat.id}`}
                    // className={cat.name !== INVENTORY.DATA_PRIVACY_MANAGER ? styles.secInventoryDetails : styles.secInventoryDetailsDPM}>
                    className={styles.secInventoryDetails}
                  >
                    {cat.attributes.map(
                      (
                        attr: {
                          businessName: string
                          responseValue: string
                        },
                        idx: number
                      ) => (
                        <>
                          <div key={`row-${idx}`}>
                            <span key={`key-${idx}`} className={styles.key}>
                              {attr.businessName} :
                            </span>
                            <span key={`val-${idx}`} className={styles.childValue}>
                              {attr.responseValue || "N/A"}
                            </span>
                          </div>
                        </>
                      )
                    )}
                  </div>
                </WppAccordion>
              )
            })}
          </>
        ) : (
          <div className={styles.linkNotFound}>No Additional Details Found</div>
        )}
      </div>
    )
  }
  const renderLinkedInventories = () => {
    return (
      <>
        {Array.isArray(accordLinkArr) && accordLinkArr.length ? (
          <>
            {showLinkParentAccSpin ? (
              <WppSpinner size="s" color={"black"} />
            ) : (
              accordLinkArr.map((item: AccordItem) => {
                return (
                  <>
                    <WppAccordion
                      size="m"
                      className={styles.accordStyle}
                      withDivider={false}
                      key={`accordion-${item.id}`}
                    >
                      <WppTypography
                        type="m-strong"
                        slot="header"
                        className={styles.accordHeader}
                        key={`header-${item.id}`}
                      >
                        {item.accordName + " (" + item.childAccord.length + ")"}
                      </WppTypography>
                      <div key={`typography-${item.id}`} className={styles.outerAccord}>
                        {item.childAccord.map((child: any) => {
                          return (
                            <WppAccordion
                              size="m"
                              withDivider={true}
                              onClick={() =>
                                handleAccord(child.id, item.type || typeOfParentInventory.name, child.organization.id)
                              }
                              key={`child-accordion-${child.id}`}
                              expanded={openAccordionId === child.id}
                            >
                              <WppTypography
                                type="m-strong"
                                slot="header"
                                className={styles.accordHeader}
                                key={`child-header-${child.id}`}
                              >
                                {child.name}
                              </WppTypography>
                              <div key={`child-typography-${child.id}`} className={styles.outerAccord}>
                                {openAccordionId === child.id && (
                                  <>
                                    {showLinkChildAccSpin ? (
                                      <WppSpinner size="s" color={"black"} key={`child-spinner-${child.id}`} />
                                    ) : (
                                      <LinkedInvCard key={`child-card-${child.id}`} inventoryDetails={child} />
                                    )}
                                  </>
                                )}
                              </div>
                            </WppAccordion>
                          )
                        })}
                      </div>
                    </WppAccordion>
                  </>
                )
              })
            )}
          </>
        ) : (
          <div className={styles.linkNotFound}>No Linked Inventory Found</div>
        )}
        <>
          <div className={styles.btnAddLinkInventory}>
            <WppActionButton className={styles.customActionBtn} onClick={() => setShowAddRelInventory(true)}>
              Add Related Inventory
            </WppActionButton>
            <WppActionButton className={styles.customActionBtn} onClick={() => setShowAddRelInventory(false)}>
              Hide
            </WppActionButton>
          </div>
          <div className={styles.banner}>
            <WppBanner id="banner" type="information" show={showBannerLinking}>
              {bannerMsg}
            </WppBanner>
          </div>
          {showAddRelInventory && (
            <div className={styles.secLinkTwoInventory}>
              <div className={styles.linkText}>Link this inventory to an existing inventory</div>
              <div className={styles.linkDropdown}>
                <div className={styles.linkDropdownItems}>
                  <WppLabel
                    className={styles.label}
                    config={{ text: "Type" }}
                    htmlFor="row_link_type"
                    typography="s-strong"
                  />
                  <WppSelect
                    id="row_link_type"
                    placeholder="Select Type"
                    value={selectedType.name}
                    size="s"
                    message={
                      (validationErrors && validationErrors.find((item: any) => item.name === "selectedType")?.error) ||
                      ""
                    }
                    messageType={
                      validationErrors && validationErrors.find((item: any) => item.name === "selectedType")?.error
                        ? "error"
                        : undefined
                    }
                    onWppChange={handleType}
                  >
                    {SELECT_TYPES.map((item, idx) => {
                      return (
                        <WppListItem key={idx} value={item}>
                          <p slot="label">{convertToTitleCase(item.name)}</p>
                        </WppListItem>
                      )
                    })}
                  </WppSelect>
                </div>
                <div className={styles.linkDropdownItems}>
                  <WppLabel
                    className={styles.label}
                    config={{ text: "Relation" }}
                    htmlFor="row_link_relation"
                    typography="s-strong"
                  />
                  <WppSelect
                    id="row_link_relation"
                    onWppChange={e => setSelectedRelation(e.target.value)}
                    placeholder={"Select Relation"}
                    disabled={selectedType.name === ""}
                    value={selectedRelation.name}
                    withSearch={true}
                    size="s"
                    message={
                      (validationErrors &&
                        validationErrors.find((item: any) => item.name === "linkedSelectedRelation")?.error) ||
                      ""
                    }
                    messageType={
                      validationErrors &&
                      validationErrors.find((item: any) => item.name === "linkedSelectedRelation")?.error
                        ? "error"
                        : undefined
                    }
                  >
                    {relationList.map((item: { idx: number; name: string }) => {
                      return (
                        <WppListItem key={item.idx} value={item}>
                          <p slot="label">{item.name}</p>
                        </WppListItem>
                      )
                    })}
                  </WppSelect>
                </div>
                <div className={styles.linkDropdownItems}>
                  <WppLabel
                    className={styles.label}
                    config={{ text: "Inventory" }}
                    htmlFor="row_link_inventory"
                    typography="s-strong"
                  />
                  <WppSelect
                    id="row_link_inventory"
                    onWppChange={e => setSelectedInventory(e.target.value)}
                    placeholder={"Select Inventory"}
                    disabled={selectedType.name === "" && selectedRelation.name === ""}
                    value={selectedInventory.name}
                    withSearch={true}
                    size="s"
                    message={
                      (validationErrors &&
                        validationErrors.find((item: any) => item.name === "linkedSelectedInventory")?.error) ||
                      ""
                    }
                    messageType={
                      validationErrors &&
                      validationErrors.find((item: any) => item.name === "linkedSelectedInventory")?.error
                        ? "error"
                        : undefined
                    }
                  >
                    {inventoryList.map(
                      (
                        item: {
                          name: string
                        },
                        idx: number
                      ) => {
                        return (
                          <WppListItem key={idx} value={item}>
                            <p slot="label">{item.name}</p>
                          </WppListItem>
                        )
                      }
                    )}
                  </WppSelect>
                </div>
                <WppButton
                  className={styles.customBtnSecondary}
                  size={"s"}
                  variant={"secondary"}
                  loading={showSpinner}
                  onClick={linkTwoInventories}
                >
                  Link Inventories
                </WppButton>
              </div>
            </div>
          )}
        </>
      </>
    )
  }

  return (
    <WppSideModal open={open} size={"2xl"} onWppSideModalClose={onClose} className={styles.modalBox}>
      {isLoading ? (
        <div slot="body">
          <DataGridOfflineComp />
        </div>
      ) : (
        <>
          <div slot="header"  className={styles.header}> 
            <h3>{clickedRowData && clickedRowData.name}</h3>
            <div className={styles.buttonAction}>
              <WppActionButton onClick={onClose} variant="secondary" slot="actions">
                <WppIconClose className={styles.close}></WppIconClose>
              </WppActionButton>
            </div>
          </div>
          <div slot="body" className={styles.sideModalBody}>
            <div className={styles.banner}>
              <WppBanner id="banner" type="information" show={isToShowBanner}>
                Unable to fetch inventory. Please refresh or try after some time.
              </WppBanner>
            </div>
            {showToast && (
              <WppToast
                className={styles.toast}
                message={"Inventory linked successfully"}
                type={"success"}
                duration={2000}
                onWppToastComplete={() => setShowToast(false)}
              />
            )}
            <Container maxWidth="xl">
              <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} rowSpacing={10}>
                <Grid item xs={12} sm={12} md={12}>
                  <div className={styles.secInventoryDetailsTop}>
                    <div>
                      <span className={styles.key}>id: </span>
                      <span>{rowDetails?.id}</span>
                    </div>
                    <div>
                      <span className={styles.key}>Name: </span>
                      <span>{rowDetails?.name}</span>
                    </div>
                    <div>
                      <span className={styles.key}>Description: </span>
                      <span>{rowDetails?.description}</span>
                    </div>
                    <div>
                      <span className={styles.key}>Status: </span>
                      <TagStatusVariantIndicator params={{ value: rowDetails?.status }} />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} paddingTop={"2rem !important"}>
                  <div className={styles.tabSection}>
                    <WppTabs className={styles.customTabs} value={currentTab} onWppChange={handleTabChange}>
                      <WppTab
                        className={styles.customTab}
                        value="AdditionalDetails"
                        counter={rowDetails?.inventoryAttributes?.categories?.length}
                      >
                        Summary
                      </WppTab>
                      <WppTab
                        className={styles.customTab}
                        value="LinkedInventories"
                        counter={accordLinkArr?.reduce((count, item) => count + item?.childAccord?.length, 0)}
                      >
                        Linked Inventories
                      </WppTab>
                      <WppTab className={styles.customTab} value="Assessments" counter={accordAssessArr.length}>
                        Assessments
                      </WppTab>
                    </WppTabs>
                  </div>
                  {
                    {
                      AdditionalDetails: <div className={styles.tabContent}>{renderAdditionalDetails()}</div>,
                      LinkedInventories: <div className={styles.tabContent}>{renderLinkedInventories()}</div>,
                      Assessments: (
                        <div className={styles.tabContent}>
                          {isLoading ? (
                            <WppProgressIndicator className={styles.customLoader} variant={"bar"} />
                          ) : (
                            <QueAns rowDetails={accordAssessArr} />
                          )}
                        </div>
                      )
                    }[currentTab]
                  }
                </Grid>
              </Grid>
            </Container>
          </div>
          <div slot="actions">
            <WppButton
              className={styles.customBtnSecondary}
              disabled={typeOfParentInventory.name === INVENTORY.TYPE_LE || isLoading}
              variant="secondary"
              onClick={handleLaunchAssessment}
            >
              Launch Assessment
            </WppButton>
          </div>
        </>
      )}
    </WppSideModal>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(RowDetails)
