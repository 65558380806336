import {serviceUrlProps} from './interface';
import appConfig from "../app.config";
export const serviceURL: serviceUrlProps = {
    DPS_SERVICE_URL_DEV: process.env.DPS_SERVICE_URL_DEV || 'https://dev-dps.choreograph.com',
    DPS_SERVICE_URL_QA: process.env.DPS_SERVICE_URL_QA || 'https://qa-dps.choreograph.com',
    DPS_SERVICE_URL_INT: process.env.DPS_SERVICE_URL_INT || 'https://int-dps.choreograph.com',
    DPS_SERVICE_URL_AMER: process.env.DPS_SERVICE_URL_AMER || 'https://amer-dps.choreograph.com',
    DPS_SERVICE_URL_APAC: process.env.DPS_SERVICE_URL_APAC || 'https://apac-dps.choreograph.com',
    DPS_SERVICE_URL_EMEA: process.env.DPS_SERVICE_URL_EMEA || 'https://emea-dps.choreograph.com',
    dpsBaseAPI: appConfig.DPS_HOST_URL || 'https://dev-dps.choreograph.com',
    pgpBaseAPI: appConfig.RA_HOST_URL || 'https://dev-ra.choreograph.com',
};

export default serviceURL;
